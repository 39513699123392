import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  package as PackagePropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';

import './styles.themed.scss';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';
import SlideshowTeasePicture from 'components/SlideshowTeasePicture';
import PackageTitle from 'components/PackageTitle';
import AIMS_FLAVORS from 'lib/aimsFlavors';

const BRAND_TO_FLAVORS = {
  select: {
    s: AIMS_FLAVORS.FOCAL_758x379,
    m: AIMS_FLAVORS.FOCAL_360x180,
    l: AIMS_FLAVORS.FOCAL_320x160,
    xl: AIMS_FLAVORS.FOCAL_380x380,
  },
  default: {
    s: AIMS_FLAVORS.FOCAL_758x379,
    m: AIMS_FLAVORS.FOCAL_460x460,
    l: AIMS_FLAVORS.FOCAL_260x260,
    xl: AIMS_FLAVORS.FOCAL_380x380,
  },
};

function image(item, vertical) {
  if (item.type === 'slideshow') {
    return (
      <SlideshowTeasePicture
        slides={item.item.slides}
        sizes={{
          s: 360,
          m: 230,
          l: 130,
          xl: 190,
        }}
      />
    );
  }
  return (
    <TeasePicture
      responsiveFlavors={BRAND_TO_FLAVORS[vertical] || BRAND_TO_FLAVORS.default}
      type={item.type}
      computedValues={item.computedValues}
    />
  );
}

function getTease(content, metadata, vertical, isMobileOnlyComponent, pkgType) {
  if (!content) return null;
  return (
    <TeaseCard
      content={content}
      {...metadata}
      {...content.metadata}
      size="large"
      vertical={vertical}
      isMobileOnlyComponent={isMobileOnlyComponent}
      pkgType={pkgType}
    >
      {image(content, vertical)}
    </TeaseCard>
  );
}

/**
 * TwoUp component
 * @param {object} props - Component properties
 * @param {import('lib/CustomPropTypes/package').PackageType} props.content - The content to display
 * @param {string} [props.className=''] - Additional class name for the component
 * @param {string} [props.pkgClassName='pkg twoUp'] - Additional class name for the package
 * @param {string} [props.vertical=''] - Vertical alignment for the component
 * @param {boolean} [props.isFirstLayout=false] - Whether this is the first layout
 * @param {boolean} [props.alternateTitleFormat=false] - Whether to use an alternate title format
 * @param {boolean} [props.isMobileOnlyComponent=false] - Whether the component is mobile only
 */
function TwoUp({
  content,
  className = '',
  pkgClassName = 'pkg twoUp',
  vertical = '',
  isFirstLayout = false,
  alternateTitleFormat = false,
  isMobileOnlyComponent = false,
}) {
  if (!content || !(content.items?.[0])) return null;

  const {
    id, items, metadata = {}, packageIndex,
  } = content;

  const pkgType = content?.type;
  const isFirstLayoutAndPackage = isFirstLayout && !packageIndex;
  const classes = classNames(
    pkgClassName,
    className,
    { 'two-up__accent-line': isFirstLayoutAndPackage },
    'tease-card--two-up',
  );

  const { showBottomBorder = false } = metadata;

  return (
    <section
      className={classNames(classes)}
      data-packageid={id}
      data-test="two-up__section"
      data-testid="two-up__section"
    >
      {metadata?.title && (
        <PackageTitle
          metadata={metadata}
          alternateDisplayFormat={alternateTitleFormat}
        />
      )}
      <div className="two-up__articles">
        {getTease(items[0], metadata, vertical, isMobileOnlyComponent, pkgType)}
        {getTease(items[1], metadata, vertical, isMobileOnlyComponent, pkgType)}
        {/* check for ads */}
        {items[2]
          && getTease(
            items[2],
            metadata,
            vertical,
            isMobileOnlyComponent,
            pkgType,
          )}
      </div>
      {showBottomBorder && (<hr data-testid="pkg-bottom-border" className="pkg-bottom-border" />)}
    </section>
  );
}

TwoUp.propTypes = {
  content: PackagePropType.isRequired,
  className: PropTypes.string,
  pkgClassName: PropTypes.string,
  vertical: PropTypes.string,
  isFirstLayout: PropTypes.bool,
  alternateTitleFormat: PropTypes.bool,
  isMobileOnlyComponent: PropTypes.bool,
};


TwoUp.contextTypes = LayoutContextPropType;

export default TwoUp;
