import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';
import colorLogo from 'assets/icons/color-logo-badge.svg';
import playBtn from 'assets/icons/play-btn-watch-badge.svg';
import sportsBadge from 'assets/icons/sports-badge-pill.svg';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { BADGES } from './constants';
import styles from './styles.module.scss';

/**
 *
 * @param {object} prop
 * @param {string} prop.badgeText
 * @param {string} prop.badgeType
 * @param {string} prop.eyebrowText
 * @param {string} prop.url
 * @param {boolean} prop.playmakerBadge
 */
const BadgeLabel = ({
  badgeText = null,
  badgeType = null,
  eyebrowText = null,
  url = null,
  playmakerBadge = false,
}) => {
  const badgeIsEyebrow = badgeType === BADGES.EYEBROW;
  const {
    'show-nbc-sports-front': sportsFrontFF,
  } = useFeatureFlagContext();

  if (!badgeType || (!eyebrowText && badgeIsEyebrow)) {
    return null;
  }
  const {
    FIRST_ON_NBC,
    BREAKING,
    LIVE,
    DEVELOPING,
    EXCLUSIVE,
    SPECIAL_REPORT,
    WATCH, EYEBROW,
    DATA_GRAPHIC,
    SPORTS,
  } = BADGES;
  const badgeTypeSafe = badgeType?.replace(/ /g, '_');
  const displayBadgeType = badgeType.toUpperCase();
  /**
   * Returns text for badge display expect for SPORTS brand
   */
  const displayBadgeText = () => {
    if (displayBadgeType === EYEBROW) {
      return eyebrowText.toUpperCase();
    }
    if (sportsFrontFF && displayBadgeType === SPORTS) {
      return null;
    }
    return (
      typeof badgeText === 'string'
      && badgeText.trim() !== ''
      && !Object.values(displayBadgeType).includes(badgeText)
    ) ? badgeText.toUpperCase() : displayBadgeType;
  };
  const redBadge = [BREAKING, LIVE, DEVELOPING];
  const blackBadge = [FIRST_ON_NBC];
  const yellowBadge = [EXCLUSIVE, SPECIAL_REPORT, DATA_GRAPHIC];
  const sportBadge = [SPORTS];
  const badgeStyles = classNames(
    'badge-label',
    {
      [styles.iconPosition]: badgeType === LIVE,
      [styles.badgeLabel]: !playmakerBadge,
      'playmaker-badge-label': playmakerBadge,
      [`badge-type-${badgeTypeSafe}`]: badgeType,
      [styles.playmakerBadgeLabel]: playmakerBadge,
      [styles.blackBadge]: blackBadge.includes(displayBadgeType),
      [styles.redBadge]: redBadge.includes(displayBadgeType),
      [styles.yellowBadge]: yellowBadge.includes(displayBadgeType),
      [styles.sportsBadge]: sportBadge.includes(displayBadgeType),
      [styles.watchBadge]: displayBadgeType === WATCH,
    },
  );

  /**
   *
   * @param {string} type
   */
  const iconDisplay = (type) => {
    let displayIcon;
    switch (type) {
      case FIRST_ON_NBC:
        displayIcon = colorLogo;
        break;
      case WATCH:
        displayIcon = playBtn;
        break;
      case SPORTS:
        displayIcon = sportsFrontFF ? sportsBadge : null;
        break;
      default:
        displayIcon = null;
    }
    return (
      displayIcon && (
        <img className={styles.icon} alt={displayBadgeText() ?? (sportsFrontFF && 'SPORTS')} src={displayIcon} />
      )
    );
  };

  /**
   * Returns badge display for non playmaker badges
   */
  const badgeDisplay = () => (
    <span data-testid={`badge-label-${badgeType}`} className={badgeStyles}>
      {iconDisplay(displayBadgeType)}
      {displayBadgeText()}
    </span>
  );

  /**
   * Returns badge display for playmaker
   */
  const playmakerBadgeDisplay = () => {
    if (badgeType === LIVE) {
      return (
        <span data-testid="badge-label-storyline-live" className={badgeStyles}>
          {iconDisplay(displayBadgeType)}
          <span className={styles.inlineBlinkingDot} />
          {displayBadgeText()}
        </span>
      );
    }

    return (
      <span data-testid={`badge-label-${badgeType}`} className={badgeStyles}>
        {iconDisplay(displayBadgeType)}
        {displayBadgeText()}
      </span>
    );
  };

  const badgeContent = playmakerBadge ? playmakerBadgeDisplay() : badgeDisplay();
  const hasUrlAndEyeBrow = url && badgeIsEyebrow;
  return hasUrlAndEyeBrow ? <Link to={url}>{badgeContent}</Link> : <>{badgeContent}</>;
};

BadgeLabel.propTypes = {
  badgeType: PropTypes.string,
  badgeText: PropTypes.string,
  eyebrowText: PropTypes.string,
  url: PropTypes.string,
  playmakerBadge: PropTypes.bool,
};

export default BadgeLabel;
