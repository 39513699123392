import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useConcurrencyMonitoring } from '../hooks/useConcurrencyMonitoring';

export function ConcurrencyMonitor({
  mvpdAccountMetadata,
  mvpdProviderConcurrencyFlow,
  mvpdProviderId,
  shouldMonitor = false,
}) {
  const { error } = useConcurrencyMonitoring({
    mvpdAccountMetadata,
    mvpdProviderConcurrencyFlow,
    mvpdProviderId,
    shouldMonitor,
  });

  useEffect(() => {
    // TODO: handle errors better than just console.warn'ing them
    // eslint-disable-next-line no-console
    if (error && typeof console === 'object' && typeof console.warn === 'function') {
      // eslint-disable-next-line no-console
      console.warn('error');
    }
  }, [error]);

  return null;
}

ConcurrencyMonitor.propTypes = {
  shouldMonitor: PropTypes.bool,
  mvpdProviderId: PropTypes.string,
  mvpdProviderConcurrencyFlow: PropTypes.number,
  mvpdProviderAccountMetadata: PropTypes.shape({
    userID: PropTypes.string,
    hba_status: PropTypes.string,
  }),
};

