/**
   * Gets information about the containing Zone
   *
   * @param {Package} packageData - The package data object.
 */
export function zoneLayoutInfo(packageData) {
  const zoneLayoutType = packageData?.context?.layoutType;
  const zoneRailAdjacent = packageData?.context?.isRailAdjacent;
  const zoneIsRail = packageData?.context?.isRailLayout && !zoneRailAdjacent;
  return {
    zoneLayoutType,
    zoneRailAdjacent,
    zoneIsRail,
  };
}
