import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Count({
  children,
  className = null,
}) {
  return (
    <div data-testid="count" className={styles.msnbcDailyCount}>
      <div className={classNames(styles.inner, className)}>
        {children}
      </div>
    </div>
  );
}

Count.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  className: PropTypes.string,
};


export { Count };
