import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withCookies, Cookies } from 'react-cookie';
import classNames from 'classnames';
import { isValidAbsoluteUrl } from 'lib/urlUtils';

import {
  hideProviderSelectAction,
  loadScheduleAction,
  showProviderSelectAction,
} from 'redux/modules/tve';

import {
  playmakerMetadata as PlaymakerMetadataPropType,
  CvsdkTeaseStyle as CvsdkTeaseStylePropType,
} from 'lib/CustomPropTypes';
import {
  configTempPass,
  TVE_CANONICAL_ROUTE,
  ACCESS_ENABLER_OBJ,
} from 'lib/tve';

import { ERROR_TYPES } from 'components/TVE/Slate';
import IntObserver from 'components/IntObserver';
import Player from 'components/TVE/Player';
import ProviderSelect from 'components/TVE/ProviderSelect';
import TempPass from 'components/TVE/TempPass';
import TVECountdown from 'components/TVE/Countdown';
import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import VideoSlate from 'components/PlaymakerTease/Slate';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import BadgeLabel from 'components/BadgeLabel';
import { appendSearchParam } from 'lib/link';

const block = 'cvsdk-tease-msnbc';

/**
 * @param {Cvsdk_Tease_MSNBC} props
 */
function TeaseComponent({
  cookies,
  playmakerMetadata: {
    playmakerArt,
    playmakerFlag,
    playmakerPlayBehavior,
    playmakerTitle,
    playmakerBadge,
    playmakerBadgeText,
  },
  styleOverride,
  vertical,
  authenticated = null,
  currentProgram = {},
  hasTempPass: hasTempPassProp = false,
  hideProviderSelect = () => {},
  icid = null,
  loadSchedule = () => {},
  providerSelectVisible = false,
  showProviderSelect = () => {},
  videoError = false,
  showPlayerControls = false,
  showPlayerActionButton = true,
  slateOverride = {},
  stickyEnabled = false,
  onStuckChange = () => {},
  isStoryline = false,
  noStuckChangeInFullscreen = false,
}) {
  const { t } = useTranslation();
  const iframeRef = React.useRef(null);
  const [inView, setInView] = React.useState(false);

  const [checkTempPass, setCheckTempPass] = React.useState(false);

  const {
    publicRuntimeConfig: {
      DISABLE_AUTH_TVE_MSNBC: bypassAuth,
    },
  } = getConfig();

  const hasTempPass = hasTempPassProp && !bypassAuth;

  const hasAuth = authenticated || hasTempPass;

  const canonicalURL = appendSearchParam(`/${TVE_CANONICAL_ROUTE}`);

  // Actions on mount
  React.useEffect(() => {
    // Load vertical schedule
    if (!currentProgram || !currentProgram.title) {
      loadSchedule(vertical);
    }
  }, []);

  // User not authenticated with mvpd
  React.useEffect(() => {
    if (authenticated === false && hasTempPass === false) {
      // render tempPass component
      setCheckTempPass(true);
    } else if (authenticated === true && providerSelectVisible === true) {
      // Close MVPD overlay
      hideProviderSelect();
    }
  }, [authenticated]);

  /**
 * Retrieves the expiration value from cookies.
 *
 * @returns {number} The expiration value parsed as an integer.
 */
  const getExpiration = () => {
    const { expirationName } = configTempPass;
    return parseInt(cookies.get(expirationName), 10);
  };


  /**
 * Handles the action based on authentication status.
 * If the user is authenticated, redirects to the canonical URL.
 * Otherwise, shows the provider selection.
 */
  const handleAction = () => {
    if (hasAuth) {
      window.location = canonicalURL;
    } else {
      showProviderSelect();
    }
  };

  const isLiveFlag = playmakerFlag === 'Live';

  /**
 * Determines whether to display a badge or flag based on the storyline status.
 */
  const flagOrBadge = () => {
    if (isStoryline) {
      return (
        <BadgeLabel
          badgeType={playmakerBadge}
          badgeText={playmakerBadgeText}
          playmakerBadge
        />
      );
    }

    if (playmakerFlag || isLiveFlag) {
      return (
        <span
          data-testid="playmaker-flag"
          className={classNames(
            `${block}__flag`,
          )}
        >
          <InlineBlinkingLiveDot className={`${block}__dot`} />
          {playmakerFlag || t('Live')}
          &nbsp;
          {'/ '}
        </span>
      );
    }

    return <></>;
  };

  // If user defined the playmaker art as a static image, use that instead of preview
  const isArtImageUrl = isValidAbsoluteUrl(playmakerArt);

  return (
    <>
      {providerSelectVisible && (
        <ProviderSelect
          iframeRef={iframeRef}
          redirectUrl={`${get(window, ['location', 'origin'], '')}${canonicalURL}`}
        />
      )}
      <IntObserver
        callback={([entry]) => {
        // Component in view
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setInView(true);
          }
        }}
        threshold={0}
      >
        <section className={block} data-testid="cvsdk-tease-msnbc">
          {!bypassAuth && inView && (
            <>
              <WithTVEAuthProvider
                iframeRef={iframeRef}
              />
              {videoError !== ERROR_TYPES.BROWSER && (
                <TempPass
                  checkTempPass={checkTempPass}
                />
              )}
            </>
          )}
          <div className={classNames(`${block}__grid-outer`, styleOverride.gridOuter)}>
            <div className={classNames(`${block}__grid-col1`, styleOverride.gridCol1)}>
              <div className={`${block}__player`}>
                <div className={`${block}__player-animated`}>
                  <a href={canonicalURL} className={`${block}__player-link`} data-icid={icid}>
                    {isArtImageUrl ? (
                      <VideoSlate
                        pageView="front"
                        showPlayIcon={false}
                        video={{
                          primaryImage: {
                            url: {
                              primary: playmakerArt,
                            },
                          },
                        }}
                      />
                    ) : (
                      (currentProgram?.thumb) && (
                        <>
                          {slateOverride.hasOverlay && (
                            <div className={`${block}__slate__overlay absolute absolute-fill`} />
                          )}
                          <video autoPlay loop muted playsInline className={classNames({ 'v-hidden': hasAuth })}>
                            <source
                              src={slateOverride.thumb ?? currentProgram.thumb}
                              type="video/mp4"
                            />
                          </video>
                        </>
                      )
                    )}
                  </a>
                </div>
                {(hasAuth || bypassAuth) && (
                  <div className={`${block}__player-live`}>
                    <Player
                      autoplayMuted
                      bypassAuth={bypassAuth}
                      clickToPlay={playmakerPlayBehavior === 'clickToPlay'}
                      externalControlbar={showPlayerControls ? 0 : 1}
                      stickyEnabled={stickyEnabled}
                      onStuckChange={onStuckChange}
                      noStuckChangeInFullscreen={noStuckChangeInFullscreen}
                    />
                  </div>
                )}
                {showPlayerActionButton && !bypassAuth && (
                  <button
                    aria-label="player action"
                    className={`${block}__player-action`}
                    onClick={handleAction}
                    type="button"
                  >
                    <span
                      className={classNames(
                        `${block}__player-icon icon`,
                        {
                          'icon-lock': !authenticated && !hasTempPass,
                          dn: hasAuth,
                        },
                      )}
                    />
                  </button>
                )}
              </div>
            </div>
            <div className={classNames(`${block}__grid-col2`, styleOverride.gridCol2)}>
              {hasTempPass && (
                <div className={`${block}__wrap-labels`}>
                  <a href={canonicalURL} className={`${block}__live-tag`} data-icid={icid}>
                    {t('LIVE')}
                  </a>
                  <div className={`${block}__label`}>
                    {t('Daily preview expires in')}
                  &nbsp;
                    <span className={`${block}__countdown`}>
                      <TVECountdown
                        end={getExpiration()}
                        onEndCallback={() => {
                          if (
                            typeof window !== 'undefined'
                          && typeof window[ACCESS_ENABLER_OBJ] !== 'undefined'
                          && typeof window[ACCESS_ENABLER_OBJ].logout === 'function'
                          ) {
                            window[ACCESS_ENABLER_OBJ].logout();
                          }
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              <div className={`${block}__wrap-tags`}>
                {(!hasTempPass) && (
                  <a href={canonicalURL} className={`${block}__live-tag`} data-icid={icid}>
                    {t('LIVE')}
                  </a>
                )}
                <a href={canonicalURL} className={`${block}__logo-tag`} data-icid={icid}>
                  <span className="icon icon-msnbc-tv" />
                </a>
              </div>
              {(playmakerTitle || (currentProgram?.title)) && (
                <h3 className={`${block}__title`}>
                  { isStoryline ? (
                    <span data-icid={icid}>
                      {/* TODO: BENTO-15783 extract this flag to sub component */}
                      {flagOrBadge()}
                      <span className="cvsdkFlag-video-title" data-testid="cvsdkFlag-video-title">
                        {playmakerTitle || currentProgram.title}
                      </span>
                    </span>
                  )
                    : (
                      <a href={canonicalURL} data-icid={icid}>
                        {/* TODO: BENTO-15783 extract this flag to sub component */}
                        {flagOrBadge()}
                        <span data-testid="cvsdkFlag-video-title">
                          {playmakerTitle || currentProgram.title}
                        </span>
                      </a>
                    )}
                </h3>
              )}
            </div>
          </div>
        </section>
      </IntObserver>
    </>
  );
}

TeaseComponent.propTypes = {
  authenticated: PropTypes.bool,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  currentProgram: PropTypes.shape({
    title: PropTypes.string,
    thumb: PropTypes.string,
  }),
  hasTempPass: PropTypes.bool,
  hideProviderSelect: PropTypes.func,
  icid: PropTypes.string,
  loadSchedule: PropTypes.func,
  providerSelectVisible: PropTypes.bool,
  playmakerMetadata: PlaymakerMetadataPropType.isRequired,
  showProviderSelect: PropTypes.func,
  styleOverride: CvsdkTeaseStylePropType.isRequired,
  vertical: PropTypes.string.isRequired,
  videoError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  showPlayerControls: PropTypes.bool,
  showPlayerActionButton: PropTypes.bool,
  slateOverride: PropTypes.shape({
    hasOverlay: PropTypes.bool,
    thumb: PropTypes.string,
  }),
  stickyEnabled: PropTypes.bool,
  onStuckChange: PropTypes.func,
  isStoryline: PropTypes.bool,
  noStuckChangeInFullscreen: PropTypes.bool,
};

/**
 *
 * @param {object} props
 * @param {tveMp4} props.tve
 * @param {Video} props.video
 */
const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  currentProgram: {
    title: get(tve, ['epgCurrent', 'program', 'title']),
    thumb: get(tve, ['epgCurrent', 'thumbMd']),
  },
  hasTempPass: tve.hasTempPass,
  providerSelectVisible: tve.providerSelectVisible,
  videoError: video.error,
});

/**
 * Maps the dispatch actions to props for the component.
 *
 * @param {Function} dispatch
 * @returns {object} The mapped action props.
 */
const mapActionsToProps = (dispatch) => ({
  /**
   *
   */
  hideProviderSelect: () => dispatch(hideProviderSelectAction()),
  /**
   * Loads the schedule for a given vertical.
   *
   * @param {string} vertical - The vertical to load the schedule for.
   */
  loadSchedule: (vertical) => dispatch(loadScheduleAction(vertical)),
  /**
   *
   */
  showProviderSelect: () => dispatch(showProviderSelectAction()),
});

const CvsdkTeaseMSNBC = connect(mapStateToProps, mapActionsToProps)(
  withCookies(TeaseComponent),
);

export { CvsdkTeaseMSNBC };
