import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';
import { Unibrow } from 'components/Unibrow';
import { isBreakingNewsText } from 'lib/breakingNews';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { BylineList } from 'components/Byline/index';
import { BaguetteVisual } from '../BaguetteVisual/index';

import styles from './styles.module.scss';

const block = 'baguette-item';

export const BaguetteItem = ({
  item,
  showArt = false,
  showByline = true,
  showEyebrow = true,
  showBorder = true,
  showImageOnlyInWide = false,
  lastItem = false,
  hideImageInNarrowZone = false,
}) => {
  if (!item) {
    return null;
  }
  const { computedValues, type, item: innerItem } = item || {};

  const { headline, url, unibrow } = computedValues || {};

  const authors = innerItem?.authors?.slice(0, 1) ?? [];

  const isVideo = type === 'video';

  const shouldShowByline = showByline
    && !isVideo
    && authors.length >= 1
    && !isBreakingNewsText(unibrow.text);
  const shouldShowEyebrow = showEyebrow && unibrow;

  return (
    <li
      className={classNames(styles.item, {
        [styles.noBorder]: !showBorder,
        [styles.lastItem]: lastItem,
      })}
      data-contentid={item?.id}
      data-testid="baguette-item"
    >
      <BaguetteVisual item={item} hideImageInNarrowZone={hideImageInNarrowZone} showArt={showArt} showImageOnlyInWide={showImageOnlyInWide} />
      <div className={styles.contentWrapper}>
        <h2 className={styles.headline} data-testid={`${block}__headline`}>
          <Link to={url}>{headline}</Link>
        </h2>

        <span className={styles.metaWrapper}>
          {shouldShowByline && (
            <span
              className={styles.author}
              data-testid="byline-list"
            >
              <BylineList
                additionalClasses={styles.author}
                authors={authors}
              />
            </span>
          )}
          {shouldShowByline && shouldShowEyebrow && (
            <span className={styles.slash} data-testid={`${block}__slash`}>
              {' '}
              /
              {' '}
            </span>
          )}
          {shouldShowEyebrow && (
            <Unibrow className={styles.eyebrow} unibrow={unibrow} />
          )}
        </span>
      </div>
    </li>
  );
};

BaguetteItem.propTypes = {
  item: ContentPropType.isRequired,
  showArt: PropTypes.bool,
  showByline: PropTypes.bool,
  showEyebrow: PropTypes.bool,
  showBorder: PropTypes.bool,
  showImageOnlyInWide: PropTypes.bool,
  lastItem: PropTypes.bool,
  hideImageInNarrowZone: PropTypes.bool,
};

