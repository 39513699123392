import React from 'react';
import PropTypes from 'prop-types';

import { package as PackagePropType } from 'lib/CustomPropTypes';
import Ad from 'components/Ad';
import './styles.themed.scss';

export function NativeAd(props) {
  const {
    content: {
      id,
    },
    pkgClassName = 'pkg NativeAd',
  } = props;
  return (
    <section
      data-packageid={id}
      className={pkgClassName}
    >
      <Ad
        slot="nativepackagebanner"
        refreshInterval={0}
        activeTab={false}
      />
      <hr className="pkg-bottom-border" />
    </section>
  );
}

NativeAd.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};

