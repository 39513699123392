import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  content as ContentPropType,
} from 'lib/CustomPropTypes';
import { imageAltForItem } from 'lib/imageAlt';
import { getTeaseUrl } from 'lib/teaseUtils';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { formatDuration } from 'lib/DateTime';

import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import { SMORGASBORD_TEASE_VISUAL_VARIANTS } from '../constants';

import './styles.themed.scss';

const block = 'small-tease-visual';
const visualSizes = { s: AIMS_FLAVORS.FOCAL_80x80 };
const {
  VIDEO, IMAGE, DOT, NONE, PLAY,
} = SMORGASBORD_TEASE_VISUAL_VARIANTS;

/**
 * The small visual icons to the left of the metacontent
 * in the small tease and related items
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The item to render in the tease
 * @param {string} props.variant Enum to determine the type of icon that gets rendered (play arrow/dot/image/video/nothing)
 * @returns {JSX.Element} Component
 */
export const SmallTeaseVisual = ({ variant, item = {} }) => {
  if (!item) {
    return null;
  }
  if (variant === PLAY && item?.type === 'video') {
    return (<VideoPlayButton />);
  }
  if (variant === DOT) {
    return (<DotIcon />);
  }
  const imageUrl = getTeaseUrl(item);
  const imageAlt = imageAltForItem(item);
  const {
    computedValues: { url } = {},
    computedValues,
    type,
    item: innerItem,
  } = item;

  const duration = innerItem?.duration;
  const formattedDuration = formatDuration(duration);
  if (variant === VIDEO && item?.type === 'video') {
    return (
      <div
        data-test={`${block}__visual-wrapper--video`}
        className={classNames(`${block}__visual-wrapper`, `${block}__visual-wrapper--video`)}
      >
        <Link to={url} className={`${block}__visual-link`}>
          <TeasePicture
            alt={imageAlt}
            className={`${block}__visual`}
            preview={type === 'video' && { url, redirect: false, radius: false }}
            responsiveFlavors={visualSizes}
            retina
            url={imageUrl}
            computedValues={computedValues}
          />
          <div data-test={`${block}__video-controls`} className={`${block}__video-controls`}>
            <VideoDuration formattedDuration={formattedDuration} />
            <VideoPlayButton />
          </div>
        </Link>
      </div>
    );
  }
  if (variant === IMAGE && imageUrl) {
    return (
      <div
        data-test={`${block}__visual-wrapper`}
        className={classNames(`${block}__visual-wrapper`, `${block}__visual-wrapper--image`)}
      >
        <Link to={url} className={`${block}__visual-link`}>
          <TeasePicture
            alt={imageAlt}
            className={`${block}__visual`}
            retina
            responsiveFlavors={visualSizes}
            url={imageUrl}
            computedValues={computedValues}
          />
        </Link>
      </div>
    );
  }

  return null;
};

SmallTeaseVisual.propTypes = {
  item: ContentPropType.isRequired,
  variant: PropTypes.oneOf([VIDEO, IMAGE, PLAY, DOT, NONE]).isRequired,
};

const VideoPlayButton = () => (
  <div data-test={`${block}__video-play`} className={`${block}__play`}>
    <span className="icon icon-video pointer-events-initial" />
  </div>
);

const DotIcon = () => (
  <div data-test={`${block}__dot`} className={`${block}__dot`}>
    <span className="icon icon-dot" />
  </div>
);

const VideoDuration = ({
  formattedDuration = null,
}) => {
  if (!formattedDuration) {
    return null;
  }
  return (
    <div className={`${block}__video-duration`}>
      <span>{formattedDuration}</span>
    </div>
  );
};

VideoDuration.propTypes = {
  formattedDuration: PropTypes.string,
};

