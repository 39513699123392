import { getPresetPackageMetadata } from '../presetDefaultsHelpers';
/**
   *
   * @param {Package} packageData
   */
export function multiStorylineColumnInfo(packageData) {
  const {
    leadColumn,
    itemsInColumns,
    columns,
  } = getPresetPackageMetadata(packageData);

  const columnInfo = {
    leadColumn,
    itemsInColumns,
    columns,
  };
  return columnInfo;
}
