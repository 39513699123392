import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { addDays, AP_MONTHS } from 'lib/DateTime';

import styles from './styles.module.scss';

const getDateFromMetadata = (dateString) => {
  if (!dateString) return null;
  const date = new Date(`${dateString}T00:00:00`).toUTCString();
  return date;
};

function Badge({
  cardsLength = 0,
  className = '',
  index = 0,
  metadata = {},
}) {
  const { t } = useTranslation();

  let badgeTop = '';
  let badgeBottom = '';
  let showBadge = false;

  const centerBadge = metadata?.badge !== 'dates' ? styles.centerBadge : '';

  switch (metadata?.badge) {
    case 'letters': {
      const alphabet = t('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
      // hide badge if there are over alphabet length items in letters
      if (index > alphabet.length - 1) {
        showBadge = false;
        break;
      }
      badgeBottom = alphabet.charAt(index);
      showBadge = true;
      break;
    }

    case 'numbersAsc':
      badgeBottom = index + 1;
      showBadge = true;
      break;

    case 'numbersDesc':
      badgeBottom = cardsLength - index;
      showBadge = true;
      break;

    case 'dates': {
      if (!metadata.badgeDate && !metadata.badgeStartDate) {
        showBadge = false;
        break;
      }
      const badgeDate = getDateFromMetadata(metadata.badgeDate);
      const startDate = getDateFromMetadata(metadata.badgeStartDate);
      const useDate = badgeDate || startDate;
      const cardDate = addDays(useDate, (badgeDate ? 0 : index));
      badgeTop = t(AP_MONTHS[cardDate.getMonth()]);
      badgeBottom = cardDate.getUTCDate();
      if (badgeBottom.toString().length === 1) badgeBottom = `0${badgeBottom}`; // pad with 0
      showBadge = true;
      break;
    }

    default:
      showBadge = false;
      break;
  }

  if (!showBadge) {
    return null;
  }

  return (
    <div className={`${styles.waffleBadge} ${className} ${centerBadge}`} data-testid="badge">
      <div className={styles.top} data-test="badge__top" data-testid="badge-top">
        {badgeTop}
      </div>
      <div className={styles.bottom} data-test="badge__bottom" data-testid="badge-bottom">
        {badgeBottom}
      </div>
    </div>
  );
}

Badge.propTypes = {
  cardsLength: PropTypes.number,
  className: PropTypes.string,
  index: PropTypes.number,
  metadata: PropTypes.objectOf(PropTypes.string),
};


export { Badge };
