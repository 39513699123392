import React from 'react';
import classNames from 'classnames';
import { package as PackagePropType } from 'lib/CustomPropTypes';

import { MultiStorylineContainer } from './MultiStorylineContainer';
import { PackageSubNav } from '../PackageSubNav';
import { getPackageData, setPackageDefaults } from './utils/packageDataManager';

import './styles.themed.scss';
import sassVariables from './sassVariables.module.scss';

/**
 * @param {object} content
 * @param {object} content.content
 * @returns {React.ReactElement} Component
*/
export function MultiStoryline({ content }) {
  if (!content) return null;

  const {
    id,
    items,
    subnav,
    metadata,
  } = setPackageDefaults(content);
  if (!items || !items.length || !metadata) return null;
  const { packageTitle, pkgHeaderStyle } = metadata;
  const data = { subnav, pkgHeaderStyle, packageTitle };

  const packageClass = sassVariables?.['package-outer-class'];

  const {
    zoneLayoutType,
    zoneRailAdjacent,
    zoneIsRail,
  } = getPackageData(content, 'zoneLayoutInfo');

  return (
    <section
      data-packageid={id}
      className={classNames(
        'pkg',
        'multi-storyline',
        {
          [`zone-layout-${zoneLayoutType}`]: true,
          'zone-layout-not-fullWidth': zoneLayoutType !== 'fullWidth',
          'zone-is-rail-adjacent': zoneRailAdjacent,
          'zone-is-rail': zoneIsRail,
          [packageClass]: packageClass,
        },
      )}
    >
      <PackageSubNav content={{ content, data }} />
      <MultiStorylineContainer content={content} />
    </section>
  );
}

MultiStoryline.propTypes = {
  content: PackagePropType.isRequired,
};
