/**
 * Posts a message to the WebView if available.
 * @param {string | object} message - The message to emit (must be JSON-serializable).
 */
export const postWebViewMessage = (message) => {
  if (!message) {
    return;
  }

  try {
    JSON.stringify({ message }); // Ensure JSON-serializability
  } catch (error) {
    return;
  }

  const webviewHandler = window?.webkit?.messageHandlers?.toggleMessageHandler;

  if (webviewHandler) {
    webviewHandler.postMessage({ message });
  }
};
