import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export function PageIndicators({
  pageControls,
  onPageClick = null,
  className = null,
  indicatorClassName = null,
  activeIndicatorClassName = 'isActive',
}) {
  return (
    <div className={classnames(styles.pageIndicators, className)}>
      {pageControls.map(({ goToThisPage, page, isActivePage }) => (
        <button
          aria-label={`Pagination ${page} of ${pageControls.length}`}
          key={page}
          type="button"
          onClick={(event) => {
            goToThisPage();
            onPageClick?.({ event, page });
          }}
          className={classnames(
            styles.pageIndicator,
            indicatorClassName,
            {
              [styles.activePageIndicator]: isActivePage,
              [activeIndicatorClassName]: isActivePage,
            },
          )}
          data-testid="timeline-page-indicator"
        />
      ))}
    </div>
  );
}

PageIndicators.propTypes = {
  pageControls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onPageClick: PropTypes.func,
  className: PropTypes.string,
  indicatorClassName: PropTypes.string,
  activeIndicatorClassName: PropTypes.string,
};
