import { firstItemOfType } from './firstItemOfType';
import { zoneLayoutInfo } from './zoneLayoutInfo';
import { packageMetadata } from './packageMetadata';
import { multiStorylineColumnInfo } from './multiStorylineColumnInfo';
import { multiStorylinePackageData } from './multiStorylinePackageData';

export const packageDataAccessors = {
  firstItemOfType,
  multiStorylineColumnInfo,
  multiStorylinePackageData,
  packageMetadata,
  zoneLayoutInfo,
};
