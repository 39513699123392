import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link';

import styles from './styles.module.scss';

export function EmbedInfo({
  dek = null,
  headline = null,
  headlineUrl = null,
  isBreaking = false,
  label = 'Live Updates',
  showLabel = true,
}) {
  if (!headline && !dek) {
    return null;
  }

  const headlineClass = classNames(
    styles.headline,
    {
      [styles.dekless]: !dek,
    },
  );

  const labelClass = classNames(
    styles.label,
    {
      [styles.labelBreaking]: isBreaking,
    },
  );

  return (
    <div className={styles.embedInfo}>
      { headline && (
        <LinkIfHref
          className={styles.link}
          data-test="embed-info__link"
          data-testid="embed-info__link"
          href={headlineUrl}
        >
          <h4
            className={styles.header}
            data-test="embed-info__headline"
            data-testid="embed-info__headline"
          >
            {showLabel && (
              <span
                className={labelClass}
                data-test="embed-info__label"
                data-testid="embed-info__label"
              >
                {`${label} / `}
              </span>
            )}
            <span className={headlineClass}>
              {headline}
              {headlineUrl && (
                <span className={classNames(styles.icon, 'icon icon-arrow-link')} />
              )}
            </span>
          </h4>
        </LinkIfHref>
      )}
      {dek && (
        <p
          className={styles.dek}
          data-test="embed-info__dek"
          data-testid="embed-info__dek"
        >
          {dek}
        </p>
      )}
    </div>
  );
}

EmbedInfo.propTypes = {
  dek: PropTypes.string,
  headline: PropTypes.string,
  headlineUrl: PropTypes.string,
  isBreaking: PropTypes.bool,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
};

