import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function ToggleButton({
  activeText = 'On',
  className = '',
  inactiveText = 'Off',
  isActive = false,
  onChangeCallback = () => {},
}) {
  const buttonText = isActive ? activeText : inactiveText;

  return (
    <button
      className={classNames(
        styles.button,
        className,
        { [styles.isActive]: isActive },
      )}
      data-testid="toggle--button"
      onClick={() => {
        const newState = !isActive;
        onChangeCallback(newState);
      }}
      type="button"
    >
      <span className={styles.buttonText} data-testid="toggle--button--text">
        {buttonText}
      </span>
      <span className={classNames(styles.arrow, 'icon icon-angle-down')} />
    </button>
  );
}

ToggleButton.propTypes = {
  activeText: PropTypes.string,
  className: PropTypes.string,
  inactiveText: PropTypes.string,
  isActive: PropTypes.bool,
  onChangeCallback: PropTypes.func,
};


export { ToggleButton };
