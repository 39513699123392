import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { content as ItemPropType } from 'lib/CustomPropTypes';
import { LinkIfHref } from 'components/Link';
import TypeIcon from 'components/TypeIcon';
import TeasePicture from 'components/TeasePicture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import VideoPlayer from 'components/VideoPlayer';
import DataVizLoader from 'components/DataVizLoader';
import LiveVideoMedia from './LiveVideoMedia';
import { getIs2024ScoreboardEmbed } from '../utils/getIs2024ScoreboardEmbed';

import contentMediaStyles from './styles.module.scss';

/**
 *
 * @param {object} item
 * @param {string} size
 * @param {boolean} colLead
 * @param {number} numOfColumns
 * @returns {JSX.Element}
 */
const picture = (item, size, colLead, numOfColumns, sportsFrontFF, shouldOpenLinkInNewTab) => {
  const IMAGE_TRANSFORMATIONS = {
    oneToOne: {
      s: AIMS_FLAVORS.FOCAL_200x200,
      m: AIMS_FLAVORS.FOCAL_200x200,
      l: AIMS_FLAVORS.FOCAL_200x200,
      xl: AIMS_FLAVORS.FOCAL_200x200,
    },
    multiSmall: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_321x214,
      l: AIMS_FLAVORS.FOCAL_300x210,
      xl: AIMS_FLAVORS.FOCAL_350x250,
    },
    multiStandard: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_339x225,
      l: AIMS_FLAVORS.FOCAL_380x250,
      xl: AIMS_FLAVORS.FOCAL_410x290,
    },
    default: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_412x275,
      l: AIMS_FLAVORS.FOCAL_500x333,
      xl: AIMS_FLAVORS.FOCAL_1000x667,
    },
  };
  const hideCredit = size === 'multiSmall' || (size === 'multiStandard' && !colLead && numOfColumns === 3);

  const isSportsVideoWorkflow = item?.item?.taxonomy?.additionalTerms?.filter(({ termName }) => (
    termName?.display === 'Workflow: NBC Sports Video'
  ))?.length > 0;

  const isSportsVideo = sportsFrontFF && isSportsVideoWorkflow ? 'video' : null;

  const displayPlayButton = item.type === 'video';

  return (
    <div className={classNames([contentMediaStyles.media])}>
      <LinkIfHref target={shouldOpenLinkInNewTab ? '_blank' : ''} href={item.computedValues.url}>
        { displayPlayButton && (
          <TypeIcon
            type="video"
            className={classNames([contentMediaStyles.icon])}
          />
        )}
        <TeasePicture
          className="multiStoryPicture"
          responsiveFlavors={IMAGE_TRANSFORMATIONS[size] || IMAGE_TRANSFORMATIONS.default}
          type={item.type}
          computedValues={item.computedValues}
          hideCredit={hideCredit}
          isSportsVideo={isSportsVideo}
        />
      </LinkIfHref>
    </div>
  );
};

/**
 *
 * @param {IndexedContent} item
 * @param {boolean} isMutedAutoplayEnabled
 */
const inlineVideoPlayer = (item, isMutedAutoplayEnabled) => {
  const { item: video, computedValues = {}, metadata } = item;
  const { teaseImage = {} } = computedValues;

  const {
    playmakerAutoplayMuted,
    playmakerPlayBehavior,
    showHeadlineInlineVid = true,
    inlineHeadlineOverride,
  } = metadata || {};
  const autoPlay = isMutedAutoplayEnabled ? playmakerPlayBehavior === 'autoplay' : false;
  return (
    <>
      <VideoPlayer
        autoPlay={autoPlay}
        isEmbedded
        replay
        disableSticky
        onlyAutoplayMuted={playmakerAutoplayMuted ?? true}
        video={{ ...video, teaseImage }}
        isStoryline
        showHeadlineInlineVid={showHeadlineInlineVid}
        inlineHeadlineOverride={inlineHeadlineOverride}
      />
    </>
  );
};

/**
 *
 * @param {object} args
 * @param {IndexedContent} args.contentItem
 * @param {object} args.packageMetadata
 * @param {boolean} args.isLiveBlogLayout
 * @param {Size} args.mediaSize
 * @param {string} args.aspectRatio
 * @param {boolean} args.isLiveVideo
 * @param {number} args.uniqueId
 * @param {boolean} args.colLead
 */
export function ContentMedia({
  contentItem,
  packageMetadata = {},
  isLiveBlogLayout = false,
  mediaSize = 'multiStandard',
  isLiveVideo = false,
  aspectRatio = '3 / 2',
  uniqueId = null,
  colLead = false,
  shouldOpenLinkInNewTab = false,
  storylineDataViz,
}) {
  const {
    'sle-autoplay-muted': isMutedAutoplayEnabled,
    'show-nbc-sports-front': sportsFrontFF,
  } = useFeatureFlagContext();

  if (!contentItem) return null;
  // only respecting known aspect ratios
  const aspectOneToOne = aspectRatio === '1 / 1';

  const isVideo = contentItem.type === 'video' || isLiveVideo;
  const isDataViz = contentItem.type === 'custom';

  const is2024ScoreboardEmbed = getIs2024ScoreboardEmbed(contentItem);

  /**
   *
   */
  const mediaIsImage = () => !(isLiveVideo || isVideo);

  /**
   *
   */
  const getMedia = () => {
    // *****************
    // NEED TO LOOK AT THIS. Moved contentItem.type check to top
    // *****************
    if (isLiveVideo === true) {
      return <LiveVideoMedia packageMetadata={packageMetadata} />;
    }

    const isSportsVideoEmbed = contentItem.computedValues.url.includes('nbcsports.com/video/embed');

    if (isDataViz) {
      return (
        <DataVizLoader
          className={isSportsVideoEmbed ? contentMediaStyles.nbcSportsEmbed : ''}
          data={contentItem}
          uniqueId={uniqueId}
          storylineDataViz={storylineDataViz}
          useResponsiveSizing={!is2024ScoreboardEmbed}
        />
      );
    }

    if (contentItem.type === 'video' && contentItem.metadata.mediaSize !== 'multiSmall') return inlineVideoPlayer(contentItem, isMutedAutoplayEnabled);
    const pictureSize = (aspectOneToOne) ? 'oneToOne' : mediaSize;

    return picture(contentItem, pictureSize, colLead, packageMetadata.columns, sportsFrontFF, shouldOpenLinkInNewTab);
  };

  const media = getMedia();
  return (
    <div
      className={classNames(
        contentMediaStyles['multistory-media'],
        {
          [contentMediaStyles['aspect-one-to-one']]: aspectOneToOne,
          [contentMediaStyles['storyline-media']]: !isLiveVideo,
          [contentMediaStyles['multistory-small']]: mediaSize === 'multiSmall' && !isVideo,
          [contentMediaStyles['multistory-standard']]: mediaSize === 'multiStandard' && !isVideo,
          [contentMediaStyles['multistory-standard-video']]: mediaSize === 'multiStandard' && isVideo,
          [contentMediaStyles['multistory-small-video']]: mediaSize === 'multiSmall' && isVideo,
          [contentMediaStyles['image-ratio']]: mediaIsImage(),
          [contentMediaStyles['liveblog-layoutMedia']]: isLiveBlogLayout,
          [contentMediaStyles['live-video']]: isLiveVideo,
          [contentMediaStyles['is-flex-item']]: is2024ScoreboardEmbed,
        },
      )}
      data-testid={(isLiveVideo ? 'storyline-media-live' : `storyline-media-${contentItem.type}`)}
    >
      {media}
    </div>
  );
}

ContentMedia.propTypes = {
  contentItem: ItemPropType.isRequired,
  packageMetadata: PropTypes.shape({}),
  isLiveBlogLayout: PropTypes.bool,
  mediaSize: PropTypes.string,
  isLiveVideo: PropTypes.bool,
  aspectRatio: PropTypes.string,
  uniqueId: PropTypes.string,
  colLead: PropTypes.bool,
  shouldOpenLinkInNewTab: PropTypes.bool,
};

ContentMedia.defaultProps = {
  packageMetadata: {},
  isLiveBlogLayout: false,
  mediaSize: 'multiStandard',
  isLiveVideo: false,
  aspectRatio: '3 / 2',
  uniqueId: null,
  colLead: false,
};

