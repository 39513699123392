import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { VerticalContext } from 'lib/ContextTypes';
import { getDek, getInfoForSpecificShopButtons, getInfoForShopButtons } from 'lib/teaseUtils';

import { TeaseImage } from 'components/packages/Waffle/TeaseImage';
import Link from 'components/Link';
import { isOnSale } from 'lib/productOfferPriceHelpers';
import { Save } from 'components/SocialShareMenu/Save';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { PKG_SAVE } from 'lib/brandFeatures';
import ShopButton from 'components/ShopButtonInfo/ShopButton';
import 'components/ShopButtonInfo/styles.themed.scss';
import { TextSummary } from 'components/packages/Waffle/TextSummary';

import cardStyles from 'components/packages/Waffle/Card/styles.module.scss';
import { OFFER_OUT_OF_STOCK as OUT_OF_STOCK } from 'lib/constants';
import Breakpoints from 'lib/Breakpoints';
import trimText from 'lib/trimText';
import { PromoCode } from '../PromoCode';
import { Details } from '../Details';
import { Headline } from '../../Headline';

import styles from './styles.module.scss';

function ProductWaffleCard(props) {
  const refWrapper = React.useRef();
  const vertical = React.useContext(VerticalContext);
  const { card } = props;
  const [showMore, setShowMore] = React.useState(false);
  const [isPriceHovered, setIsPriceHovered] = React.useState(false);
  const refShowMoreButton = React.useRef();
  const isMobile = Breakpoints.isS();

  const {
    id,
    description,
    isCustomAd,
    teaseImage,
    offers,
  } = card.item || card;

  // empty card or invalid card
  if (!id || isCustomAd) {
    return null;
  }

  const dek = getDek(props);
  let dekText = dek || description?.tease || '';

  const {
    list,
    productDescription,
    productName,
    productUrl,
    sale,
    seller,
  } = getInfoForShopButtons(props);

  // If there is no dek and no tease description, use the long product description on Drupal products only
  const { html: longProductDescription } = (productDescription.length && productDescription?.[0]);
  if (!id.startsWith('rcpd') && !dekText) dekText = longProductDescription || '';

  // Self invoking metadata transformation - sets default for openInNewTab to true
  const itemMetadata = (() => {
    const { metadata: { openInNewTab = true, ...rest } = {} } = card;
    return { ...rest, openInNewTab };
  })();

  const promoCode = itemMetadata?.promoCode;

  const computedValues = {
    ...card.computedValues,
    teaseImage,
  };

  const showSave = getFeatureConfigForBrand(PKG_SAVE, vertical);

  const handleCloseDetails = (closedWithKeyboard) => {
    setShowMore(false);

    setTimeout(() => {
      if (closedWithKeyboard) refShowMoreButton.current?.focus();
    }, 0);
  };

  const showMoreOption = (itemMetadata?.relatedLinkLabel?.trim().length > 0
    && itemMetadata?.relatedLinkUrl?.trim().length > 0)
    || dekText?.trim().length > 0
    || offers?.length > 1;

  function getShopButtons(cardInfo, offer) {
    const button = getInfoForSpecificShopButtons(cardInfo, offer);
    const isOutOfStock = button.state === OUT_OF_STOCK;

    return (
      <Link
        target={itemMetadata.openInNewTab ? '_blank' : '_self'}
        to={button.productUrl}
        rel="noreferrer"
        className={styles.shopButtonLink}
      >
        <ShopButton
          additionalStyles={{
            shop: styles.shop,
            cost: styles.buttonCost,
            vendor: styles.buttonVendor,
            vendorName: styles.vendorName,
          }}
          isOutOfStock={isOutOfStock}
          block="WaffleProduct"
          isWaffle
          listPrice={button.list}
          name={button.productName}
          salePrice={button.sale}
          seller={button?.seller?.name ?? ''}
          variation="3"
        />
      </Link>
    );
  }

  const shopButtons = offers.map((offer) => getShopButtons(card, offer));

  const textSummary = (
    dekText && dek.trim().length > 0 && (
      <TextSummary
        allowHTMLText
        dekText={dekText}
        showDek
        textClasses={styles.textSummary}
      />
    )
  );

  const plusIcon = (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3482_5656)">
        <path d="M9.5 6.50171H6.5V9.50171H5.5V6.50171H2.5V5.50171H5.5V2.50171H6.5V5.50171H9.5V6.50171Z" className={styles.plusIcon} />
      </g>
      <defs>
        <clipPath id="clip0_3482_5656">
          <rect width="12" height="12" fill="white" transform="translate(0 0.00170898)" />
        </clipPath>
      </defs>
    </svg>
  );

  const detailsText = offers && offers.length !== 1 ? 'More details & retailers' : 'More details';

  const cardFront = (
    <div className={cardStyles.inner}>
      <Link
        rel="noopener noreferrer"
        target={itemMetadata.openInNewTab ? '_blank' : '_self'}
        to={productUrl}
        data-testid="product-link"
        className={styles.link}
      >
        <div className={styles.save}>
          <TeaseImage
            computedValues={computedValues}
            showTease
            vertical={vertical}
          />
          {showSave ? (
            <Save
              contentId={id}
              contentType="product"
              options={{ isThumbnail: true, showCTA: true }}
            />
          ) : null}
        </div>
      </Link>

      <section
        className={classNames(
          styles.section,
          styles.productInfo,
        )}
      >
        <Headline
          headline={trimText(productName, 80)}
          metadata={itemMetadata}
          product
          url={productUrl}
          headlineClass={styles.productName}
        />
        {vertical === 'select' && textSummary}
        <Link
          rel="noreferrer"
          target="_blank"
          to={productUrl}
        >
          <div
            className={classNames(styles.productPrice, {
              [styles.strikeThrough]: isOnSale({
                salePrice: sale,
                listPrice: list,
              }),
            })}
            onMouseEnter={() => setIsPriceHovered(true)}
            onMouseLeave={() => setIsPriceHovered(false)}
          >
            {isOnSale({ salePrice: sale, listPrice: list }) && (
              <div className={styles.salePrice}>
          $
                {sale}
              </div>
            )}
            <div className={styles.listPrice}>
        $
              {list}
            </div>
          </div>
        </Link>
        {seller && (
          <a
            href={productUrl ?? seller?.externalUrl}
            target="_blank"
            className={classNames(styles.vendorName, {
              [styles.showHover]: isPriceHovered,
            })}
            rel="noreferrer"
            onMouseEnter={() => setIsPriceHovered(true)}
            onMouseLeave={() => setIsPriceHovered(false)}
          >
            {seller?.name}
          </a>
        )}
        {vertical !== 'select' && textSummary}
        {promoCode && <PromoCode promoCode={promoCode} />}
        {showMoreOption && (
          <button ref={refShowMoreButton} className={styles.moreInfoBtn} type="button" onClick={() => { setShowMore(!showMore); }}>
            {detailsText}
            {' '}
            {plusIcon}
          </button>
        )}
      </section>
    </div>
  );

  return (
    <li
      className={classNames(
        cardStyles.waffleCard,
        styles.productCard,
      )}
      key={id}
      ref={refWrapper}
      data-testid="product-waffle-card"
    >
      {!showMore || isMobile ? cardFront : (
        <Details
          close={handleCloseDetails}
          description={textSummary}
          name={productName}
          shopButtons={shopButtons}
          metadata={itemMetadata}
        />
      )}

      {showMore && isMobile
        ? (
          <div className={styles.mobileDrawerBackground}>
            <div className={styles.mobileDrawer}>
              <Details
                close={handleCloseDetails}
                description={textSummary}
                name={productName}
                shopButtons={shopButtons}
                metadata={itemMetadata}
                isMobile
              />
            </div>
          </div>
        ) : null}
    </li>

  );
}

ProductWaffleCard.propTypes = {
  card: PropTypes.isRequired,
};


export { ProductWaffleCard };
