import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  content as ContentPropType,
  packageContext as packageContextType,
  layoutContext as layoutContextType,
} from 'lib/CustomPropTypes';
import { getArticleAuthor } from 'lib/featuredAuthor';
import { getUniformTeaseItem } from 'lib/teaseUtils';
import buildUrl from 'lib/buildUrl';

import Link from 'components/Link';
import LeadBorderTop from 'components/LeadBorderTop/index';
import TypeIcon from 'components/TypeIcon/index';
import { Unibrow } from 'components/Unibrow';

import { LeadOneUpPicture } from './LeadOneUpPicture';
import { FeaturedAuthorHeadline } from './FeaturedAuthorHeadline';

import styles from './styles.module.scss';

/**
 * "Lead One Up" package for Today/Telemundo - renders one Tease
 * @param {object} props
 * @param {Tease} props.content Tease to render
 * @param {string} props.packageId ID of the package
 * @param {boolean} [props.railAdjacent] Whether or not there is a rail next to the package
 * @param {string} [props.pkgClassName] Additonal classnames to provide to the package
 * @returns {JSX.Element}
 */
const LeadOneUp = ({
  content = null,
  packageId = null,
  railAdjacent = false,
  pkgClassName = 'pkg leadRectangle',
}, context) => {
  if (!content) {
    return null;
  }

  const { layoutIndex, packageIndex } = context;
  const isLeadPackage = layoutIndex === 0 && packageIndex === 0;

  const { id, type } = content;

  const {
    authors,
    headline,
    unibrow,
  } = getUniformTeaseItem(content);

  const url = buildUrl(content);

  const firstAuthor = getArticleAuthor(authors, type);

  return (
    <section
      className={classNames(
        pkgClassName,
        styles.leadOneUp,
        {
          [`pkg-index-${packageIndex + 1}`]: packageIndex,
          [styles.isLead]: isLeadPackage,
          [styles.railAdjacent]: railAdjacent,
        },
      )}
      data-packageid={packageId}
    >
      <LeadBorderTop types={['collectionLead']} />
      <div
        className={classNames(styles.container, {
          'layout-grid-container': !railAdjacent,
        })}
      >
        <article
          data-contentid={id}
          data-test="lead-one-up__content"
          className={classNames(styles.content, {
            'layout-grid-item': !railAdjacent,
          })}
        >
          <Link
            className={styles.picture}
            href={url}
          >
            <LeadOneUpPicture
              content={content}
              railAdjacent={railAdjacent}
            />
          </Link>
          <div className={styles.info}>
            <div className={styles.infoInner}>
              <TypeIcon
                className={styles.icon}
                iconLinkClass={styles.iconLink}
                type={type}
                url={url}
              />
              <span className={classNames(styles.quote, 'icon icon-quote-mark')} />
              <Unibrow
                className={styles.unibrow}
                unibrow={unibrow}
              />
              <FeaturedAuthorHeadline
                author={firstAuthor}
                headline={headline}
                url={url}
              />
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

LeadOneUp.propTypes = {
  content: ContentPropType,
  packageId: PropTypes.string,
  pkgClassName: PropTypes.string,
  railAdjacent: PropTypes.bool,
};


LeadOneUp.contextTypes = {
  packageIndex: packageContextType.packageIndex,
  layoutIndex: layoutContextType.layoutIndex,
};

export { LeadOneUp };
