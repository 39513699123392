import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { package as PackagePropType } from 'lib/CustomPropTypes';

import LeadBorderTop from 'components/LeadBorderTop';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';

import styles from './styles.module.scss';

function getPicture(item, index) {
  let responsiveFlavors = {
    s: AIMS_FLAVORS.FOCAL_760x380,
    m: AIMS_FLAVORS.FOCAL_460x460,
    l: AIMS_FLAVORS.FOCAL_240x240,
    xl: AIMS_FLAVORS.FOCAL_300x300,
  };
  if (index === 0) {
    responsiveFlavors = {
      ...responsiveFlavors,
      m: AIMS_FLAVORS.FOCAL_920x460,
      l: AIMS_FLAVORS.FOCAL_440x440,
      xl: AIMS_FLAVORS.FOCAL_560x560,
    };
  }
  return (
    <TeasePicture
      responsiveFlavors={responsiveFlavors}
      type={item.type}
      computedValues={item.computedValues}
    />
  );
}

function Mosaic({
  content,
  pkgClassName = 'pkg mosaic',
}) {
  if (!content || !(content.items?.[0]?.item)) {
    return null;
  }

  const {
    id,
    metadata: packageMetadata = {},
    packageIndex,
  } = content;

  const items = content.items.filter((item) => item);

  const containerClasses = classNames(
    pkgClassName,
    styles.mosaic,
    `pkg-index-${packageIndex + 1}`,
  );

  const contentClasses = classNames(
    styles.content,
  );

  return (
    <section
      className={containerClasses}
      data-packageid={id}
      data-test="mosaic__section"
      data-testid="mosaic__section"
    >
      <LeadBorderTop
        customStyles={styles.border}
        types={['collectionLead']}
      />
      <div className={contentClasses}>
        {items.map((item, index) => (
          <TeaseCard
            additionalClasses={{
              content: styles.teaseContent,
              dek: styles.teaseDek,
              featuredAuthor: styles.teaseFeaturedAuthor,
              featuredAuthorImage: styles.featuredAuthorImage,
              title: styles.teaseTitle,
            }}
            content={item}
            key={item.id}
            {...packageMetadata}
            {...item.metadata}
          >
            {getPicture(item, index)}
          </TeaseCard>
        ))}
      </div>
    </section>
  );
}

Mosaic.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};


export { Mosaic };
