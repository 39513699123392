/**
 * Returns a limited number of authors from the provided list.
 *
 * @param {Array} authors - The array of authors.
 * @param {number|string} limit - The maximum number of authors to return.
 * @returns {Array} The sliced array of authors, or the original array if the limit is not valid.
 */
export const getLimitedAuthors = (authors = [], limit) => {
  if (!Array.isArray(authors) || limit === null || limit === undefined) return authors;

  const parsedLimit = Number(limit);

  if (Number.isNaN(parsedLimit) || parsedLimit < 0) {
    return authors;
  }

  return authors.slice(0, Math.floor(parsedLimit));
};
