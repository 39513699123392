import React from 'react';
import PropTypes from 'prop-types';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { PymFrame } from 'components/Pym/Frame';
import './styles.themed.scss';
import classNames from 'classnames';

/**
 * @param {object} props
 * @param {string} props.className
 * @param {object} props.data
 * @param {boolean} props.useResponsiveSizing
 * @returns {React.ReactElement}
 */
const DataVizLoader = ({
  className,
  data,
  storylineDataViz,
  useResponsiveSizing,
}) => {
  const {
    computedValues: { url },
  } = data;

  const cleanedEmbedUrl = url?.replace(/&(childId|parentUrl)=[^&]+/g, '');

  const pymFrameClasses = classNames(
    className,
    {
      'w-100 h-100 b-none': storylineDataViz || !useResponsiveSizing,
    },
  );

  return (
    <div className="data-viz-div">
      <PymFrame
        url={cleanedEmbedUrl}
        title="Data Visualization"
        useResponsiveSizing={useResponsiveSizing}
        className={pymFrameClasses}
        scrolling={!useResponsiveSizing ? 'no' : undefined}
      />
    </div>
  );
};

DataVizLoader.propTypes = {
  className: PropTypes.string,
  data: ContentPropType.isRequired,
  useResponsiveSizing: PropTypes.bool,
};

DataVizLoader.defaultProps = {
  className: '',
  useResponsiveSizing: true,
};

export default DataVizLoader;

