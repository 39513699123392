import React from 'react';
import classNames from 'classnames';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { Promo3Card } from './components/Promo3Card';
import styles from './styles.module.scss';

const Promo3Panel = (props) => {
  const {
    content: {
      id,
      items,
      metadata,
      packageIndex,
    } = {},
  } = props;
  const globalShowTease = metadata?.showTeaseImage ?? true;

  return (
    <section
      className={classNames(
        'pkg',
        `pkg-index-${packageIndex + 1}`,
        styles.promo3Panel,
      )}
      data-packageid={id}
    >
      {items.map((item) => {
        if (!item) { return null; }

        const contentId = item?.id;

        const dek = item?.computedValues?.dek ?? null;
        const headline = item?.computedValues?.headline ?? null;
        const image = item?.computedValues?.teaseImage?.url?.primary ?? null;
        const url = item?.computedValues?.url;

        const buttonTitle = item?.metadata?.ctaText ?? null;
        const buttonUrl = item?.metadata?.ctaUrl ?? url;
        const showTeaseImage = item?.metadata?.showTeaseImage ?? globalShowTease;

        return (
          <Promo3Card
            buttonTitle={buttonTitle}
            buttonUrl={buttonUrl}
            contentId={contentId}
            data-testid="promo-three-panel__card"
            dek={dek}
            headline={headline}
            hideTeaseImage={!showTeaseImage}
            key={headline}
            teaseImage={image}
          />
        );
      })}
    </section>
  );
};

Promo3Panel.propTypes = {
  content: PackagePropType.isRequired,
};

export {
  Promo3Panel,
};
