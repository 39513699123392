import { parseLayoutTemplate } from '../../parseLayoutTemplate';
import { findLeadItemInfo } from '../../containerFunctions';
// Note: We want to use getPackageData to use other accessors, but
// we don't want to import the module index.js to avoid a cyclic import
// since it loads us at import.
import { getPackageData } from '../accessorInterfaces';

/**
 *  @param {Package} packageData
 */
export function multiStorylinePackageData(packageData) {
  const { items } = packageData;
  const packageMetadata = getPackageData(packageData, 'packageMetadata');

  const {
    pkgHeaderStyle,
    playmakerToggle,
    playmakerColumn,
    showVerticalBorderA,
    showVerticalBorderB,
    stackLeadColumnFirstOnMobile,
    column1Title,
    column1Logo,
    column2Title,
    column2Logo,
    column3Title,
    column3Logo,
    contentAsPackageHeadline,
    leadHeadlineSize,
    showBottomBorder,
  } = packageMetadata;
  const {
    leadColumn,
    columns,
  } = getPackageData(packageData, 'multiStorylineColumnInfo');

  const {
    zoneLayoutType,
    zoneRailAdjacent,
    zoneIsRail,
  } = getPackageData(packageData, 'zoneLayoutInfo');

  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';
  const hasColumn3Lead = leadColumn === 'Column3Lead';
  const hasLeadColumn = hasColumn1Lead || hasColumn2Lead || hasColumn3Lead;

  // Get Layout Info
  // note: parseLayoutTemplate is looking for layoutTemplate but can depend on other metadata if needed.
  const layoutDef = parseLayoutTemplate(packageMetadata);

  const { itemsPerColumn = [1, 1] } = layoutDef ?? {};
  // leadHeadlineInfo contains information needed for "contentAsPackageHeadline" mode.
  const leadHeadlineInfo = findLeadItemInfo({
    itemsPerColumn,
    leadHeadlineSize,
    contentAsPackageHeadline,
    leadColumn,
    items,
    metadata: packageMetadata,
    hasColumn1Lead,
    hasColumn2Lead,
    hasColumn3Lead,
  });

  /* Create `itemsByColumn`, an array of a column array of items
      Shape: [[..itemsInCol1..], [..itemsInCol2..], [..itemsInCol3..]]
  */
  let itemsByColumn;
  if (items) {
    const itemsCopy = [...items]; // copied because we mutate the input
    itemsByColumn = itemsPerColumn.reduce((ac, numItemsInColumn, index) => {
      // This produces a list of three lists, each a column of items.
      for (let i = 0; i < numItemsInColumn; i += 1) {
        // get/create column array
        const colItems = ac[index] || [];
        // remove top item in source array.
        const item = itemsCopy.shift();
        // Note: lead items are "removed" (not displayed) based on the
        // `remove` property of leadHeadlineInfo calculated in findLeadItemInfo(..).
        // This is to support contentAsPackageHeadline mode.
        const isLeadItem = item === leadHeadlineInfo.item;
        const removeItem = isLeadItem && leadHeadlineInfo.remove;
        if (item && !removeItem) {
          // if not removed, push source item into column list
          colItems.push(item);
        }
        // save column array in reduce accumulator
        // eslint-disable-next-line no-param-reassign
        ac[index] = colItems;
      }
      return ac;
    }, []);
  }
  // Returned Properties
  return {
    // package metadata
    pkgHeaderStyle,
    playmakerToggle,
    playmakerColumn,
    showVerticalBorderA,
    showVerticalBorderB,
    stackLeadColumnFirstOnMobile,
    column1Title,
    column1Logo,
    column2Title,
    column2Logo,
    column3Title,
    column3Logo,
    contentAsPackageHeadline,
    leadHeadlineSize,
    showBottomBorder,
    // multiStorylineColumnInfo
    leadColumn,
    columns,
    // zoneLayoutInfo
    zoneLayoutType,
    zoneRailAdjacent,
    zoneIsRail,
    // Below is non-granular MultiStorylineContainer
    //  lead flags
    hasColumn1Lead,
    hasColumn2Lead,
    hasColumn3Lead,
    hasLeadColumn,
    //  layout definition (e.g. 1-1-1)
    layoutDef,
    itemsPerColumn,
    //  lead item as package headline information
    leadHeadlineInfo,
    //  items organized into a list of columns filled with items
    itemsByColumn,
    items,
  };
}
