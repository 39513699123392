import React from 'react';
import classNames from 'classnames';

import { LinkIfHref } from 'components/Link/LinkIfHref';

import { VerticalContext, FeatureFlagContext } from 'lib/ContextTypes';
import {
  content as ContentPropType,
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  LIVE_VIDEO_EMBED_LOGO,
} from 'lib/brandFeatures';
import {
  STREAM_KEYS,
  getPlayerKeyForPID,
} from 'lib/liveVideo';
import { getLogoForStreamKey } from '../../LiveVideoEmbed/Logos';


const block = 'smorgasbord-right-rail-video-logo';

/**
 * The logo used in right rail video smorgasbord component
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The right rail video item
 * @param {JSON} props.packageMetadata the metadata from the package
 * @returns {JSX.Element} Component
 */
export const RightRailVideoLogo = ({
  item,
  packageMetadata = null,
}) => {
  const vertical = React.useContext(VerticalContext);
  const {
    'msnbc-feature-flag-logo-update': isMsnbcLogoUpdateEnabled,
    'feature-flag-logo-update': isNewsNowLogoUpdateEnabled,
  } = React.useContext(FeatureFlagContext);

  const isPlaymaker = packageMetadata?.playmakerToggle ?? true;
  const playmakerId = (packageMetadata?.playmakerIdOverride)
  || (packageMetadata?.playmakerId);
  const destinationPageUrl = isPlaymaker
    ? packageMetadata?.destinationPageUrl
    : item?.item?.url?.canonical;
  const playmakerStreamType = getPlayerKeyForPID(playmakerId);
  const isCustomStream = (packageMetadata?.playmakerId) === 'other';
  const playmakerBrand = isCustomStream
    ? packageMetadata?.playmakerBrandOverride
    : playmakerStreamType;
  const branding = isPlaymaker
    ? playmakerBrand || STREAM_KEYS.MSNBC_TVE
    : (item?.metadata?.brand) || STREAM_KEYS.MSNBC_TVE;
  const displayLogo = getFeatureConfigForBrand(
    LIVE_VIDEO_EMBED_LOGO,
    vertical,
  );

  let Logo = null;
  if (displayLogo) {
    Logo = getLogoForStreamKey(branding, isNewsNowLogoUpdateEnabled);
  }

  if (!Logo) return null;

  const containerClasses = classNames(
    `${block}__block ${block}__logo-container`,
    {
      'msnbc-new-logo': isMsnbcLogoUpdateEnabled && branding === STREAM_KEYS.MSNBC_TVE,
    },
  );

  return (
    <LinkIfHref
      href={destinationPageUrl}
      className={`${block}__link ${block}__block`}
    >
      {Logo && (
        <div className={containerClasses}>
          <Logo />
          <hr className={`${block}__logo-line`} />
        </div>
      )}
    </LinkIfHref>
  );
};

RightRailVideoLogo.propTypes = {
  item: ContentPropType.isRequired,
  packageMetadata: PlaymakerMetadataPropType,
};

