import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getDisplayNameForVertical } from 'lib/vertical';

import { CoverSpreadPeacockAvatar } from 'components/svgs/CoverSpreadPeacockAvatar';
import { BylineThumbnail } from 'components/Byline';

/**
 * <ChatAvatar />
 * This component displays the headshot of the author for a blog card.
 * If no authors are provided, it displays a fallback avatar.
 *
 * @component
 * @param {object} props - Component props.
 * @param {string} props.liveBlogStyle - The style of the live blog (can be 'chatStyle', 'reportedStyle', or 'storylineChatStyle').
 * @param {boolean} props.isChatStyle - Biz logic: Not always related to 'storylineChatStyle'. A flag indicating whether the chat style is applied.
 * @param {Array} props.authors - An array of author objects.
 *
 * @example
 * return (
 *   <ChatAvatar
 *     liveBlogStyle="chatStyle"
 *     isChatStyle={true}
 *     authors={[{ name: 'Author 1' }]}
 *   />
 * );
 */
export const ChatAvatar = ({
  liveBlogStyle = 'reportedStyle',
  authors = [],
  isChatStyle = false,
  showCardByline = false,
  vertical = '',
}) => (
  <span
    data-testid="lbp-card__meta-line__bylines"
    className={classNames(
      'lbp-card__meta-line__bylines',
      {
        [`lbp-card__meta-line__bylines--${liveBlogStyle}`]: liveBlogStyle,
      },
    )}
  >
    {authors?.length > 0 && (
      <>
        <BylineThumbnail
          person={authors[0]}
          chatStyle={isChatStyle}
          position={1}
          additionalClasses="lbp-card__avatar--pkg isStorylineChat"
          isLinked={false}
        />
        {showCardByline && (
          <>
            {authors[0].name}
            {(authors.length > 1 && ' & more')}
          </>
        )}
      </>
    )}
    {!authors?.length && (
      <>
        <span className="lbp-card__avatar--pkg isStorylineChat" data-testid="fallback-avatar">
          <CoverSpreadPeacockAvatar className="db lbp-card__peacock-avatar" />
        </span>
        {showCardByline && getDisplayNameForVertical(vertical)}
      </>
    )}
  </span>
);

ChatAvatar.propTypes = {
  liveBlogStyle: PropTypes.oneOf(['chatStyle', 'reportedStyle', 'storylineChatStyle']),
  isChatStyle: PropTypes.bool,
  vertical: PropTypes.string,
  showCardByline: PropTypes.bool,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};


