import React from 'react';
import PropTypes from 'prop-types';
import { Picture } from 'components/Picture';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import styles from './styles.module.scss';

const imageUrlMap = {
  better: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_03/2717286/better.jpg',
  globalcitizen: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_09/2717291/gc.jpg',
  mach: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_03/2717306/mach.jpg',
  msnbc: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_03/2717311/msnbc.jpg',
  news: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_03/2717316/nbcnews.jpg',
  think: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_03/2717321/think.jpg',
  today: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_03/2717326/today.jpg',
};

const getFallbackTease = (vertical) => {
  const fallbackTeaseImage = {
    alternateTeaseImages: [
      {
        aspectRatio: 'ASPECT_1X1',
        image: {
          altText: vertical,
          url: {
            primary: imageUrlMap[vertical.toLowerCase()] || '/waffleBackground.svg',
          },
        },
      },
    ],
    teaseImage: {
      altText: vertical,
    },
  };
  return fallbackTeaseImage;
};

function TeaseImage({
  computedValues = {},
  showTease = true,
  vertical = '',
}) {
  const { url, alternateTeaseImages = [], teaseImage = {} } = computedValues;
  const hasLegacyTease = teaseImage?.url?.primary;
  const hasTease = (alternateTeaseImages?.length || hasLegacyTease) && showTease;

  if (!hasTease) {
    Object.assign(computedValues, getFallbackTease(vertical));
  }

  return (
    <div className={styles.teaseImage} data-testid="tease-img">
      <div className={styles.teaseBackground} data-testid="tease-img__loading-img" />
      <div className={styles.teaseWrapper} data-testid="tease-img__wrapper">
        <Picture
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_460x460,
            m: AIMS_FLAVORS.FOCAL_300x300,
            xl: AIMS_FLAVORS.FOCAL_360x360,
          }}
          url={url}
          computedValues={computedValues}
          originalHeight={460}
          originalWidth={460}
        />
      </div>
    </div>
  );
}

TeaseImage.propTypes = {
  computedValues: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  showTease: PropTypes.bool,
  vertical: PropTypes.string,
};

export { TeaseImage };
