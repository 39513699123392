import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { VerticalContext } from 'lib/ContextTypes';
import { generateElementKey } from 'lib/stringUtils';

import ButtonHoverAnimation from 'components/ButtonHoverAnimation';

import styles from './styles.module.scss';

import config from '../config';

function KeyUpdates({
  metadata = {},
}) {
  const vertical = React.useContext(VerticalContext);

  const { t } = useTranslation();

  const stringCheck = (text, defaultValue) => (
    (typeof text === 'string' && text.trim()) ? text : defaultValue
  );

  const {
    sectionTitle,
    listItems,
    updatesText,
    updatesUrl,
  } = metadata;

  const title = t(config.defaultTitle[vertical]) || t(config.defaultTitle.default);

  return (
    <div className={styles.keyUpdates}>
      <h3 className={styles.title}>
        {stringCheck(sectionTitle, title)}
      </h3>
      <ul className={styles.list}>
        {!!listItems && (listItems.split(/\r?\n/) || [])
          .filter((line) => line)
          .map((item) => (
            <li
              className={styles.item}
              key={generateElementKey(item)}
            >
              {item}
            </li>
          ))}
      </ul>
      {stringCheck(updatesUrl, false) && (
        <div className={styles.button}>
          <ButtonHoverAnimation
            size="normal"
            type="link"
            url={updatesUrl}
            title={stringCheck(updatesText, t('See All'))}
          />
        </div>
      )}
    </div>
  );
}

KeyUpdates.propTypes = {
  metadata: PropTypes.shape({
    sectionTitle: PropTypes.string,
    listItems: PropTypes.string,
    updatesText: PropTypes.string,
    updatesUrl: PropTypes.shape({}),
  }),
};


export { KeyUpdates };
