import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { children as childrenPropType } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

const gridTypes = {
  1: 'Single',
  2: 'Half',
  3: 'Third',
  4: 'Quarter',
};

// Flexbox grid class names.
// Determined by what the main grid type is
// and what the column span is for the package
const classNamesConfig = {
  Quarter: {
    default: styles.columnFullWidth,
    1: `grid-fr-quarter-xl ${styles.borderQuarters}`,
    2: `grid-col-6-xl ${styles.borderQuarters}`,
    3: `grid-col-9-xl ${styles.borderQuarters} ${styles.largeColSpace}`,
  },
  Third: {
    default: styles.columnFullWidth,
    1: `grid-col-4-l ${styles.borderThirds}`,
    2: `grid-col-8-l ${styles.borderThirds}`,
  },
  Half: {
    default: styles.columnFullWidth,
    1: `grid-col-6-m ${styles.borderHalf}`,
  },
  Single: {
    default: styles.columnFullWidth,
  },
};

/**
 * Takes packages that were combined in the same zone and sets them in a grid pattern,
 * according to column layout setting and column span setting per component
 * Defaults to 4 column layout at full width. Leverages Flexbox grid helper classes [_css-grid.scss].
 *
 * Use PackageFlexColGrid for a configuration with custom column span settings (default: 4 columns)
 * Use PackageStaticColGrid for a layout collected in a more "boxed in" design with equal column widths (default: 3 columns)
 *
Example Usage:

- Add a package setting to compatibilityMaps/flex.json
"packages": {
  "packageWithSubTypeRules": {
    "requiredSubType": {
      "packageSubType1": true, // will set as default column span 1
      "packageSubType2": {
        "colSpan": 3 // custom column span per sub type
      },
    }
  },
  "packageNoSubTypeRules": {
    "requiredSubType": null,
    "colSpan": 2 // custom column span for the package as a whole
  }
}

- Add multiple qualifying packages next to each other in the same zone in Curator

 * @param {number} [maxPerRow] the maximum amount of columns the grid supports
 * @param {string} [zoneType] the Curator zone type that the components live in
 * @param {array[number]} [pkgColSpans] an array of column span values per each package
 * (assumes span values are at the same indices as the matching children element)
 * @param {childrenPropType} children components to render in the grid
 * @returns {React.ReactElement}
 */
const PackageFlexColGrid = ({
  children,
  maxPerRow = 4,
  zoneType = 'normal',
  pkgColSpans = [],
}) => {
  const columns = Array.isArray(children) ? children : [children];

  // get the total amount of columns that all the contents would span
  const colSpan = pkgColSpans.length && pkgColSpans.reduce((sum, a) => sum + a, 0);
  // Get the maximum amount of columns each row would span
  // Based on either the amount of columns the content will span, the amount of packages,
  // or the  maximum amount of columns set per row
  const numberOfColumns = Math.min(colSpan || columns.length, maxPerRow);
  // get type of grid layout based on the amount of columns per row
  const gridType = gridTypes[numberOfColumns];

  const getClassNames = (cols, setAsHalfColumn = false) => (
    setAsHalfColumn
      ? classNamesConfig.Half[1]
      : classNamesConfig[gridType][cols] || classNamesConfig[gridType].default
  );

  // set initial column placment values
  let columnPosition = 0;
  let isLastInRow = true;
  let reduceToHalfColumns = false;

  return (
    <div
      className={classNames(
        'package-flex-grid',
        styles.flexgrid,
        styles[`zoneType${zoneType}`],
        styles[`gridType${gridType}`],
      )}
      data-test="package-flex-grid"
      data-test-type={gridType}
    >
      {
        /* eslint-disable react/no-array-index-key */
        // There is no unique identifier for columns, index must be used
        columns.map((col, i) => {
          // mark as the first package in a row if the last one is complete
          let isFirstInRow = isLastInRow;
          const pkgCols = pkgColSpans[i] || 1;
          // get how many columns the current row spans
          columnPosition += pkgCols;

          if (columnPosition > numberOfColumns) {
            // package will overflow, so end the prev row and create a new one
            isFirstInRow = true;
            columnPosition = pkgCols;
            // end the row if the column will span the whole width
            isLastInRow = columnPosition >= numberOfColumns;
          } else {
            // check if reached the end of the row capacity
            isLastInRow = columnPosition % numberOfColumns === 0;
          }

          let setAsHalfColumn = false;
          if (isFirstInRow && pkgCols === 2 && pkgColSpans[i + 1] === 2) {
          // flag if a Quarter grid layout can be reduced to a Half layout
          // IE there are two packages of equal width filling the span on the column
            reduceToHalfColumns = true;
            setAsHalfColumn = true;
          } else if (reduceToHalfColumns) {
            // flag for the second package of the Half layout
            reduceToHalfColumns = false;
            setAsHalfColumn = true;
          }

          // reset position count for next row if last in row
          if (isLastInRow) columnPosition = 0;

          return (
            <div
              className={classNames(
                styles.column,
                'grid-col-12',
                getClassNames(pkgCols, setAsHalfColumn),
                {
                  [`${styles.lastInRow} pkg-flex-grid-last-col`]: isLastInRow,
                  [`${styles.firstInRow} pkg-flex-grid-first-col`]: isFirstInRow,
                },
              )}
              key={`column-${i}`}
              data-test="package-flex-grid-column"
              data-test-colnum={pkgCols}
            >
              {col}
            </div>
          );
        })
        /* eslint-enable react/no-array-index-key */
      }
    </div>
  );
};

PackageFlexColGrid.propTypes = {
  children: childrenPropType.isRequired,
  maxPerRow: PropTypes.number,
  zoneType: PropTypes.oneOf(['normal', 'rail', 'railAdjacent']),
  pkgColSpans: PropTypes.arrayOf(PropTypes.number),
};


export { PackageFlexColGrid };
