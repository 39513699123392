import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getConfig from 'next/config';

import Anonymous from './Anonymous';
import AnonymousSecondary from './AnonymousSecondary';
import AuthedFull from './AuthedFull';
import AuthedTemporary from './AuthedTemporary';

import { ERROR_TYPES } from '../Slate';

import './styles.themed.scss';

const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  hasTempPass: tve.hasTempPass,
  videoError: video.error,
});

/**
 * CallToAction component
 * @param {object} props - Component properties
 * @param {boolean|null} [props.authenticated=null] - User authentication status
 * @param {boolean} [props.hasTempPass=false] - Whether the user has a temporary pass
 * @param {boolean} [props.videoError=false] - Whether there is a video error
 * @param {boolean} [props.showSecondary=true] - Whether to show secondary content
 * @param {boolean} [props.showAuthedFull=true] - Whether to show full content for authenticated users
 */
function CallToAction({
  authenticated = null,
  hasTempPass = false,
  videoError = false,
  showSecondary = true,
  showAuthedFull = true,
}) {
  const { publicRuntimeConfig: { DISABLE_AUTH_TVE_MSNBC } } = getConfig();

  if (DISABLE_AUTH_TVE_MSNBC || videoError === ERROR_TYPES.BROWSER) {
    return null;
  }

  if (authenticated && !hasTempPass && showAuthedFull) {
    return <AuthedFull />;
  }

  return (
    <>
      {authenticated === true && hasTempPass && (
        <AuthedTemporary />
      )}
      {authenticated === false && (
        <Anonymous />
      )}
      {showSecondary && (
        <AnonymousSecondary />
      )}
    </>
  );
}

CallToAction.propTypes = {
  authenticated: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  hasTempPass: PropTypes.bool,
  videoError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  showSecondary: PropTypes.bool,
  showAuthedFull: PropTypes.bool,
};


export default connect(mapStateToProps)(CallToAction);
