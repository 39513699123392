import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { package as packagePropType } from 'lib/CustomPropTypes';

import LeadBorderTop from 'components/LeadBorderTop';
import TeasePicture from 'components/TeasePicture';
import Link from 'components/Link';
import { Dek } from 'components/Dek';
import RelatedContent from 'components/RelatedContent';
import { Unibrow } from 'components/Unibrow';
import TypeIcon from 'components/TypeIcon';

import styles from './styles.module.scss';

function StandardLead(props) {
  const {
    content: { items, id: packageId, packageIndex },
    isRailAdjacent = false,
    pkgClassName = 'pkg standardLead',
  } = props;

  const [main] = items;
  if (!main) { return null; }

  const {
    unibrow, headline, dek, url,
  } = main.computedValues;
  const { related = [] } = items.length && main;

  // NOTE: this is not configurable as of BENTO-9299
  // can be added back to package definition with design signoff
  const { showTeaseImage } = main.metadata || {};
  const noImage = showTeaseImage === false;

  return (
    <section
      data-testid="standardLead"
      data-packageid={packageId}
      className={classNames(
        pkgClassName,
        `pkg-index-${packageIndex + 1}`,
        styles.standardLead,
        { [styles.noImage]: noImage },
      )}
    >
      <div data-contentid={items[0].id}>
        <LeadBorderTop types={['collectionLead']} />
        {!noImage && (
          <Link to={url} className={styles.picture}>
            <TeasePicture
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_760x380,
                m: AIMS_FLAVORS.FOCAL_1000x500,
                l: AIMS_FLAVORS.FOCAL_600x300,
                xl: AIMS_FLAVORS.FOCAL_1000x500,
              }}
              type={main.type}
              computedValues={main.computedValues}
              placeholder
            />
          </Link>
        )}
        <div className={styles.info}>
          <TypeIcon className={styles.icon} type={main.type} />
          <Unibrow
            className={styles.eyebrow}
            unibrow={unibrow}
          />
          <h2 className={styles.title}>
            <Link to={url}>
              {headline}
            </Link>
          </h2>
          <Dek
            dek={dek}
          />
          <RelatedContent
            isRailAdjacent={isRailAdjacent}
            pkgClassName={pkgClassName}
            related={related}
          />
        </div>
      </div>
    </section>
  );
}

StandardLead.propTypes = {
  content: packagePropType.isRequired,
  isRailAdjacent: PropTypes.bool,
  pkgClassName: PropTypes.string,
};


export { StandardLead };
