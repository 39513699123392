import get from 'lodash.get';

import { BADGES } from 'components/BadgeLabel/constants';
import { getItemDefaults } from '../presetDefaultsHelpers';
import { getHeadlineUrl } from '../../getHeadlineUrl';

/* Note, these accessors should be broken out the same way the packageDataAccessors are. */

export const itemDataAccessors = {
  /**
   * Retrieves the names of the authors from the item data.
   *
   * @param {ContentItem} itemData - The item data object.
   */
  liveBlogAuthorNames: (itemData) => {
    const authors = get(itemData, 'item.authors', null);
    if (!authors) return null;
    const authorNames = authors.map((item) => get(item, 'person.name'));
    return authorNames;
  },
  /**
  * Processes multi-storyline item data.
  *
  * @param {JSON} itemData - The data for the item.
  * @param {object} [opts={}] - Optional parameters with fields to be used in processing and content metadata.
  */
  multiStorylineItemData: (itemData, opts = {}) => {
    const { fields } = opts;
    // ideal these fields would not be needed, the item should not have to know their location in the parent layout
    // nor look at the package data. However, the components do currently need this information at the item level.
    const { content, columnIndex, itemIndexInColumn } = fields;
    const packageMetadata = content?.metadata;
    const presetName = packageMetadata?.presetTemplate;
    const {
      computedValues = {},
      item,
      metadata,
      related,
    } = itemData;

    const {
      badge,
      hasBadge,
      badgeText,
      centerContent,
      numberOfAuthors = 1,
      showAuthor: hasAuthor = false,
      showDek,
      showTimestamp,
      columns,
      maxBlogItems = Number(metadata?.maxBlogItems || 0),
    } = metadata;

    let { hasEyebrow = true } = metadata;

    const itemDefaults = getItemDefaults({ presetName, columnIndex, itemIndexInColumn });
    if (itemDefaults.hasEyebrow === false) {
      hasEyebrow = false;
    }
    if (metadata.hasEyebrow) {
      hasEyebrow = metadata.hasEyebrow;
    }

    const {
      dek,
      headline: computedHeadline,
      unibrow,
      url,
    } = computedValues;

    const {
      headlineSize = 'large',
    } = content.metadata;

    const storyTypes = {
      IMPORTANT: 'important',
      STANDARD: 'standard',
    };

    /**
     *
     * @returns {string} Badge Type
     */
    const getBadgeType = () => {
      if (hasBadge && !badge) {
        return 'BREAKING';
      }
      return (hasBadge && badge) || BADGES.EYEBROW;
    };

    const headline = computedHeadline || item?.headline?.primary;
    const hasHeadline = !!headline && !!headline.length;
    const headlineType = headlineSize;
    const showHeadlineOnly = !hasAuthor && !showDek && !showTimestamp;
    const headlineUrl = getHeadlineUrl(itemData.type, metadata, url);

    const presetHeadlineSize = itemDefaults?.multiHeadlineSize;
    const presetMediaSize = itemDefaults?.multiMediaSize;

    const leadColumn = content?.metadata?.leadColumn ? content?.metadata?.leadColumn : 'none';
    const storyType = content?.metadata?.storyType || storyTypes.STANDARD;
    const hasRelatedContent = Array.isArray(related) && related.length > 0;
    const authors = item?.authors || [];
    const delimiter = '/';
    const multiHeadlineSize = metadata?.headlineSize ? metadata?.headlineSize : presetHeadlineSize || 'standard';


    const showAuthor = hasAuthor && item?.authors?.length > 0;
    const multiMediaSize = metadata?.mediaSize ? metadata?.mediaSize : presetMediaSize || 'multiStandard';

    const isSmallMedia = multiMediaSize === 'multiSmall';
    const relatedsDisplay = itemData?.metadata?.relatedsDisplay === 'sideBySide' ? 'sideBySide' : 'stacked';
    const isLiveBlog = itemData?.type === 'liveBlog';
    const itemType = itemData?.type;
    const aspectRatio = multiMediaSize === 'multiSmall' ? itemData?.metadata?.aspectRatio : '3 / 2';
    const oneToOneAspect = aspectRatio === '1 / 1';

    const isVideoContent = itemData?.type === 'video';
    const isDataVizContent = itemData?.type === 'custom';

    const liveBlogStyle = metadata?.liveBlogStyle;

    let colLead = false;
    if ((leadColumn === 'Column1Lead' && columnIndex === 0)
      || (leadColumn === 'Column2Lead' && columnIndex === 1)) {
      colLead = true;
    }
    const itemDataValues = {
      content,
      metadata,
      item,
      related,

      // item metadata
      badge,
      hasBadge,
      hasEyebrow,
      badgeText,
      centerContent,
      hasAuthor,
      showDek,
      showTimestamp,
      maxBlogItems,
      columns, // set by packageDefaults

      // computed values
      dek,
      computedHeadline,
      unibrow,
      url,

      // packageMetadata
      packageMetadata,
      headlineSize,
      headlineType,

      // built
      storyTypes,
      getBadgeType,

      // processed
      headline,
      hasHeadline,
      showHeadlineOnly,
      storyType,
      hasRelatedContent,
      authors,
      delimiter,
      multiHeadlineSize,
      showAuthor,
      numberOfAuthors,
      isSmallMedia,
      multiMediaSize,
      relatedsDisplay,
      isLiveBlog,
      itemType,
      aspectRatio,
      oneToOneAspect,
      isVideoContent,
      isDataVizContent,
      colLead,
      leadColumn,
      headlineUrl,
      liveBlogStyle,
    };

    return itemDataValues;
  },
};

