import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { stub as $t } from '@nbcnews/analytics-framework';

import { UNINITIALIZED, UNKNOWN } from 'lib/myNewsConstants';

import { useGeoLocation } from 'lib/Hooks';
import { useMyNewsStore } from 'store';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';
import { RouteContext } from 'lib/ContextTypes';
import { getAuthenticationState } from 'lib/authenticationUtils';
import { Circles } from './Circles';
import { LoadingAnimation } from './LoadingAnimation';

import styles from './styles.module.scss';
import { GATE_BANNER_TITLES, GATE_TITLES, GATE_SUBTITLES } from './GateTextConstants';

$t('register', 'mbt_gate_click');

export function Gate({
  bannerTitle = GATE_BANNER_TITLES.default,
  title = GATE_TITLES.default,
  subtitle = GATE_SUBTITLES.default,
  className = '',
  variant = null,
}) {
  const authenticationState = getAuthenticationState();
  const authenticate = useMyNewsStore((state) => state.authenticate);
  const isChromeless = useSelector(({ shared }) => shared.isChromeless);
  const isClient = typeof window !== 'undefined';
  const { path } = React.useContext(RouteContext) ?? {};
  const { isUsa } = useGeoLocation() ?? {};

  const track = (action) => {
    $t('track', 'mbt_gate_click', {
      action, // 'signup start' or 'login start' values to be used
      gatetype: 'inline', // this indicates the placement of the CTA.
    });
  };

  const handleLogin = () => {
    if (isChromeless && isClient) {
      const [url] = path.split('?');
      window.open(`${url}?account=login`, '_blank');
      return;
    }
    authenticate({ defaultPage: 'logIn' });
    track('login start');
  };

  const handleSignup = () => {
    if (isChromeless && isClient) {
      const [url] = path.split('?');
      window.open(`${url}?account=signup`, '_blank');
      return;
    }
    authenticate({ defaultPage: 'createProfile' });
    track('signup start');
  };

  let RenderedGate;
  // If usa we never want to show the loading state.
  const isLoading = isUsa
    // In USA we want to show the loading screen if the SDK is not ready yet.
    && [null, undefined, UNINITIALIZED, UNKNOWN].includes(authenticationState) && !isChromeless;


  if (isLoading) {
    return (
      <div className={styles.container} data-testid="loading">
        <div className={classNames(styles.inner, styles.innerLoading)}>
          <LoadingAnimation />
        </div>
      </div>
    );
  }

  const isSticky = variant === 'sticky';
  const isAccountPage = variant === 'account';
  // Sign up or log in Gate
  if (isUsa) {
    RenderedGate = (
      <div
        className={classNames(
          styles.container,
          className,
          {
            [styles.sticky]: isSticky,
            [styles.accountGate]: isAccountPage,
          },
        )}
        data-testid="gate-container-US"
      >
        <div className={classNames(
          styles.inner,
          styles.innerLoaded,
          { [styles.innerPositioned]: !isSticky },
        )}
        >
          <div className={classNames(styles.circleWrapper, styles.circleLeft)}>
            <Circles />
          </div>
          <div className={classNames(styles.circleWrapper, styles.circleRight)}>
            <Circles />
          </div>
          <div className={classNames(styles.banner)}>
            {bannerTitle}
          </div>
          <p className={styles.heading}>
            {title}
          </p>
          {subtitle ? <p className={styles.subtitle} data-testid="subtitle">{subtitle}</p> : null}
          <Button
            onClick={handleSignup}
            title="Sign Up"
            alignment="center"
            tiltClasses={styles.signUpButton}
          />
          <div className={styles.loginButton}>
            or
            {' '}
            <button type="button" onClick={handleLogin} title="Login" data-testid="login-button">
              Log In
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    RenderedGate = (
      <div
        className={classNames(
          styles.container,
          styles.international,
          {
            [styles.sticky]: isSticky,
            [styles.accountGate]: isAccountPage,
          },
        )}
        data-testid="gate-container-international"
      >
        <div className={classNames(styles.inner, styles.innerLoaded)}>
          <div className={classNames(styles.circleWrapper, styles.circleLeft)}>
            <Circles />
          </div>
          <div className={classNames(styles.circleWrapper, styles.circleRight)}>
            <Circles />
          </div>
          <p className={styles.headingInternational}>
            We’re sorry, this content is not available in your location.
          </p>
        </div>
      </div>
    );
  }

  // International Gate

  return RenderedGate;
}

Gate.propTypes = {
  bannerTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['sticky', 'account']),
};

