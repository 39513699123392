import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BTE from 'lib/BTE';
import weatherSVGs from 'components/packages/LocalNews/weather';
import HourlyWeather, { ContentPropType } from './HourlyWeather/index';
import './styles.themed.scss';

const mapStateToProps = ({ localnews }) => ({
  weather: localnews.weather,
});

const DEFAULT_SCROLL_LEFT_MODIFIER = 180;
const DEFAULT_MIN_WIDTH_SCROLLED = 200;

const Weather = ({
  weather: weatherProp,
}) => {
  const weather = {
    temperature: null,
    highTemperature: null,
    lowTemperature: null,
    iconCode: null,
    shortDescription: null,
    description: null,
    feelsLike: null,
    precipitation: null,
    humidity: null,
    wind: null,
    windDirection: null,
    error: null,
    hours: [],
    ...weatherProp,
  };

  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [currentClientWidth, setCurrentClientWidth] = useState(0);
  const [currentScrollableWidth, setCurrentScrollableWidth] = useState(0);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref?.current?.scrollLeft > DEFAULT_MIN_WIDTH_SCROLLED) setShowPreviousButton(true);
    else setShowPreviousButton(false);
  };

  const handleBreakpointChange = () => {
    setCurrentClientWidth(ref.current.clientWidth);
    setCurrentScrollableWidth(ref.current.scrollWidth);
  };

  useEffect((() => {
    const scrollableDiv = document.querySelector('.local-news-weather__scroll');

    scrollableDiv?.addEventListener('scroll', handleScroll);
    BTE.on('resize', handleBreakpointChange);
    setCurrentClientWidth(ref?.current?.clientWidth);
    setCurrentScrollableWidth(ref?.current?.scrollWidth);
    return () => {
      scrollableDiv?.removeEventListener('scroll', handleScroll);
      BTE.remove('resize', handleBreakpointChange);
    };
  }), []);

  const scrollRight = () => {
    ref.current.scrollLeft -= currentClientWidth + 10;
    if (ref.current.scrollLeft <= currentClientWidth) {
      setTimeout(() => {
        ref.current.scrollLeft = 0;
      }, 100);

      if (!ref.current.scrollBy) {
        // create an empty function for unit testing purposes
        ref.current.scrollBy = () => {
        };
      }
      ref.current.scrollBy({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setShowPreviousButton(false);
    }
  };

  const scrollLeft = () => {
    // adding 10 to clientWidth to account for when local-news-weather__prev-btn is displaying
    const scrollByNumber = currentClientWidth + 10;
    ref.current.scrollLeft += scrollByNumber;
    if (ref.current.scrollLeft > DEFAULT_MIN_WIDTH_SCROLLED) {
      setShowPreviousButton(true);
    }
    // handles scrolling content back to the beginning local-news-weather__scroll once we hit the end
    if (
      ref.current.scrollLeft + DEFAULT_SCROLL_LEFT_MODIFIER
      > currentScrollableWidth - currentClientWidth
    ) {
      ref.current.scrollLeft = 0;
      setShowPreviousButton(false);
    }
  };

  const {
    temperature,
    highTemperature,
    lowTemperature,
    iconCode,
    shortDescription,
    description,
    feelsLike,
    precipitation,
    humidity,
    wind,
    windDirection,
    hours,
    error,
  } = weather;

  return error ? (
    <p
      className="local-news-weather__error"
      data-test="local-news-weather__error"
    >
      <img src={weatherSVGs[`icon${40}`]} alt="no weather available icon" />
      We&apos;re sorry we were unable to find your local weather. Please try
      another location.
    </p>
  ) : (
    <div className="local-news-weather">
      <button
        className={cx('local-news-weather__prev-btn', { showPreviousButton })}
        type="button"
        onClick={scrollRight}
      >
        <div>
          <span className="icon icon-angle-down" />
        </div>
      </button>
      <div
        className="local-news-weather__scroll"
        data-test="local-news-weather"
        ref={ref}
      >
        <div className="local-news-weather__current" data-test="local-news-weather__current">
          <div className="local-news-weather__current-weather">
            <h2>
              {temperature}
              °
            </h2>
            <img
              src={weatherSVGs[`icon${iconCode || 32}`]}
              alt={`${shortDescription} weather icon`}
            />
          </div>
          <div className="local-news-weather__low-high">
            <span className="local-news-weather__desc">H:&nbsp;</span>
            <span className="local-news-weather__val">
              {highTemperature}
              ° /&nbsp;
            </span>
            <span className="local-news-weather__desc">L:&nbsp;</span>
            <span className="local-news-weather__val">
              {lowTemperature}
              °F
            </span>
          </div>
          <p className="local-news-weather__desc--short">
            {description || 'Not available'}
          </p>
        </div>

        <div
          className="local-news-weather__current-weather-desc"
          data-test="local-news-weather__current"
        >
          <p className="local-news-weather__details">
            <span className="local-news-weather__desc">Feels Like</span>
            {feelsLike}
            °
          </p>
          <p className="local-news-weather__details">
            <span className="local-news-weather__desc">Precip</span>
            {precipitation}
            ”
          </p>
          <p className="local-news-weather__details">
            <span className="local-news-weather__desc">Humidity</span>
            {humidity}
            %
          </p>
          <p className="local-news-weather__details">
            <span className="local-news-weather__desc">Wind</span>
            {wind}
            &nbsp;
            {windDirection}
          </p>
        </div>

        {hours.length
          ? hours
            .slice(0, 17)
            .map((hour) => (
              <HourlyWeather hourlyData={hour} key={hour.localTime} />
            ))
          : null}

      </div>

      <button
        className="local-news-weather__next-btn"
        type="button"
        onClick={scrollLeft}
      >
        <div>
          <span className="icon icon-angle-down" />
        </div>
      </button>


    </div>
  );
};

Weather.propTypes = {
  weather: PropTypes.shape({
    temperature: PropTypes.number,
    highTemperature: PropTypes.number,
    lowTemperature: PropTypes.number,
    iconCode: PropTypes.number,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    feelsLike: PropTypes.number,
    precipitation: PropTypes.number,
    humidity: PropTypes.number,
    wind: PropTypes.number,
    windDirection: PropTypes.string,
    hours: PropTypes.arrayOf(ContentPropType),
    error: PropTypes.shape({}),
  }),
};

export default connect(mapStateToProps)(Weather);
