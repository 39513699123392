import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { HIDE_TIMESTAMP_FOR_BREAKING_BIG_HEADLINE } from 'lib/brandFeatures';
import { package as packagePropType } from 'lib/CustomPropTypes';
import { timeFrom } from 'lib/DateTime';

import Link from 'components/Link';
import StripedEyebrow from 'components/StripedEyebrow';
import { Unibrow } from 'components/Unibrow';

import { BigHeadlineTease } from './Tease';

import styles from './styles.module.scss';

/**
  BigHeadline fun facts:
  - can't have dek without tease, but tease without dek is okay
  - heading font size varies based on the character count of the heading
  - tease can be video or static image
  - tease vertical position and size (!) depends on whether or not we have a dek
  - on large+ viewports the tease needs to be shifted down
*/

const BigHeadline = (props) => {
  const { t } = useTranslation();

  const {
    content: {
      items,
      metadata: {
        showTimestamp = true,
        showTeaseImage: showTeaseImageMeta,
        showDek,
      },
      id,
    },
    pkgClassName = 'pkg bigHeadline',
    vertical,
  } = props;

  const {
    id: contentId,
    item,
    computedValues: {
      headline, url, unibrow, dek, teaseImage, flag,
    } = {},
  } = get(items, 0, {});

  const { breakingNews, dateCreated, datePublished } = item || {};

  const showTeaseImage = showTeaseImageMeta && !!teaseImage;

  const contentColumnClasses = classNames(
    styles.content,
    'layout-grid-item grid-col-12',
    {
      'grid-col-6-m': showTeaseImage,
      'grid-col-12-m': !showTeaseImage,
      'grid-col-7-l grid-col-6-xl': showTeaseImage && showDek,
    },
  );

  const teaseColumnClasses = classNames(
    styles.tease,
    'layout-grid-item grid-col-12 grid-col-6-m',
    {
      'grid-col-5-l grid-col-6-xl': showDek,
    },
  );

  const hideTimestampWhenBreaking = breakingNews
    && getFeatureConfigForBrand(
      HIDE_TIMESTAMP_FOR_BREAKING_BIG_HEADLINE,
      vertical,
    );

  return (
    <section
      className={classNames(
        pkgClassName,
        styles.container,
        {
          [styles.hasTease]: showTeaseImage,
          [styles.isBreaking]: breakingNews,
          [styles.massive]: headline.length <= 20,
        },
      )}
      data-packageid={id}
      data-test="container"
      data-testid="bigHeadline"
    >
      <div
        className="layout-grid-container"
        data-contentid={contentId}
        data-test="inner-container"
        data-testid="bigHeadline-inner"
      >
        <div className={contentColumnClasses} data-test="inner-column-container" data-testid="bigHeadline-inner-column">
          {breakingNews && (
            <div className={styles.flagWrapper}>
              <StripedEyebrow>
                {flag || t('BREAKING')}
              </StripedEyebrow>
            </div>
          )}
          <div className={styles.textContent}>
            <Unibrow
              className={classNames(styles.unibrow, {
                [styles.breaking]: breakingNews,
              })}
              unibrow={unibrow}
            />
            <h2 className={styles.headline}>
              <Link to={url}>
                {headline}
              </Link>
            </h2>
            {showDek && showTeaseImage && (
              <p className={styles.dek}>
                {dek}
              </p>
            )}
            {showTimestamp && dateCreated && datePublished && !hideTimestampWhenBreaking && (
              <p className={styles.updated} data-test="big-headline-updated" data-testid="big-headline-updated">
                {dateCreated !== datePublished
                  ? `${t('Updated')} ${t('time_ago', { time: timeFrom(datePublished) })}`
                  : `${t('time_ago', { time: timeFrom(dateCreated) })}`}
              </p>
            )}
          </div>
        </div>
        {showTeaseImage && (
          <div className={teaseColumnClasses}>
            <BigHeadlineTease
              teaseItem={items[0]}
              breaking={breakingNews}
            />
          </div>
        )}
      </div>
    </section>
  );
};

BigHeadline.propTypes = {
  content: packagePropType.isRequired,
  pkgClassName: PropTypes.string,
  vertical: PropTypes.string.isRequired,
};


export { BigHeadline };
