import React from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import Player from 'components/TVE/Player';

import {
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';

/**
 * Function to render playmaker player
 * @param {object} props React Props
 * @param {object} props.playmakerMetadata Playmaker Metadata/config returned
 * @param {boolean} props.isMSNBC Boolean noting if this is for an MSNBC stream
 * @param {boolean} props.isRail Boolean indicating whether or not the item is in a rail
 * @param {boolean} props.bypassAuth Boolean indicated whether or not to bypass authentication
 * @returns {React.ReactElement}
 */
function CoreVideoPlayerEmbed({
  playmakerMetadata,
  isMSNBC = false,
  isRail = false,
  bypassAuth = false,
}) {
  const playmakerRef = React.useRef(null);

  const {
    playmakerPlayBehavior,
    playmakerAutoplayMuted,
    streamId,
  } = playmakerMetadata;

  const cvsdkProps = {
    autoplay: playmakerPlayBehavior === 'autoplay',
    autoplayMuted: playmakerAutoplayMuted,
    clickToPlay: playmakerPlayBehavior === 'clickToPlay',
    isRail,
    playmakerMetadata,
    shouldPauseWhenMutedAndOutOfView: true,
    disableStickyOnMute: true,
    stickyEnabled: true,
  };

  return (
    <>
      <ErrorBoundary>
        {isMSNBC ? (
          <Player
            ref={playmakerRef}
            autoplay={cvsdkProps.autoplay}
            autoplayMuted={cvsdkProps.autoplayMuted}
            clickToPlay={cvsdkProps.clickToPlay}
            isRail={cvsdkProps.isRail}
            playmakerMetadata={cvsdkProps.playmakerMetadata}
            shouldPauseWhenMutedAndOutOfView={cvsdkProps.shouldPauseWhenMutedAndOutOfView}
            disableStickyOnMute={cvsdkProps.disableStickyOnMute}
            stickyEnabled={cvsdkProps.stickyEnabled}
            bypassAuth={bypassAuth}
          />
        ) : (
          <OmegaVideoPlayer stream={streamId} autoplay mutedAutoplay stickyEnabled />
        )}
      </ErrorBoundary>
    </>
  );
}

CoreVideoPlayerEmbed.propTypes = {
  playmakerMetadata: PlaymakerMetadataPropType.isRequired,
  isMSNBC: PropTypes.bool,
  isRail: PropTypes.bool,
  bypassAuth: PropTypes.bool,
};


export { CoreVideoPlayerEmbed };
