import React, { useState } from 'react';
import classNames from 'classnames';

import Ad from 'components/Ad';
import './styles.themed.scss';

/**
 * Midresponsive component renders an advertisement in a midresponsive slot.
 * It uses the Ad component and handles the ad rendering state.
 * @typedef {object} mpsAd - The mpsAd object.
 * @property {Function} onRender - Function to handle the ad render event.
 * @returns {JSX.Element} The rendered Midresponsive component.
 */
const Midresponsive = () => {
  const [adRendered, setAdrendered] = useState(false);

  /**
   * adHandler function to handle the ad render event.
   * @param {*} mpsAd - The mpsAd
   */
  const adHandler = (mpsAd) => {
    mpsAd.onRender((event) => {
      if (!event || event.empty) {
        return;
      }
      setAdrendered(true);
    });
  };

  const classes = classNames(
    'ad--midresponsive',
    { 'ad--loaded': adRendered },
  );

  return (
    <Ad
      key="midresponsiveAd"
      slot="midresponsive"
      adClass={classes}
      refreshInterval={0}
      offset={300}
      handler={adHandler}
    />
  );
};

export default Midresponsive;
