import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';

import Link from 'components/Link';

import { VerticalContext, LayoutContext } from 'lib/ContextTypes';
import { querySettings } from 'lib/liveBlogPkgHelpers';
import { getLinkAttribs } from 'lib/getLiveBlogEmbeddedLink';
import { getLimitedAuthors } from 'lib/getLimitedAuthors';

import BadgeLabel from 'components/BadgeLabel';
import { BADGES } from 'components/BadgeLabel/constants';
import { BylineList } from 'components/Byline';
import PackageTitle from 'components/PackageTitle';
import Card from '../Card';

import './styles.themed.scss';

const { queryLimit: maxCards } = querySettings;

/**
 * CardList component
 * @param {object} props - Component properties
 * @param {Array<import('src/lib/graphqlTypeDefs').Card>} props.cards - The list of cards to display
 * @param {import('lib/CustomPropTypes/content').Content} props.content - The content to display
 * @param {string} props.id - The ID of the card list
 * @param {boolean} [props.hideHeadlineText=false] - Whether to hide the headline text
 * @param {boolean} [props.isEmbedded=false] - Whether the card list is embedded
 * @param {boolean} [props.isWebEmbed=false] - Whether the card list is a web embed
 * @param {boolean} [props.isRailAdjacent=false] - Whether the card list is rail adjacent
 * @param {boolean} [props.defaultBreakingStyle=false] - Whether to use the default breaking style
 * @param {string} [props.defaultLabel=''] - The default label for the card list
 * @param {boolean} [props.showLiveBadge=false] - Whether to show the live badge
 * @param {boolean} [props.isStorylinePackage=false] - Whether the card list is a storyline package
 * @param {boolean} [props.isMultiStoryline=false] - Whether the card list is multi-storyline
 * @param {number} [props.maxCardsOverride=maxCards] - Override for the maximum number of cards
 * @param {string} [props.liveBlogStyle='reportedStyle'] - The style of the live blog
 * @param {boolean} [props.renderByline=true] - Whether to render the byline
 */
function CardList({
  cards,
  content,
  id,
  hideHeadlineText = false,
  isEmbedded = false,
  isWebEmbed = false,
  isRailAdjacent = false,
  defaultBreakingStyle = false,
  defaultLabel = '',
  showLiveBadge = false,
  isStorylinePackage = false,
  isMultiStoryline = false,
  maxCardsOverride = maxCards,
  liveBlogStyle = 'reportedStyle',
  renderByline = false,
}) {
  const {
    computedValues,
    item,
    metadata: {
      label,
      showTimestamp: showTimestampSettings,
      showBreakingStyle,
      showDek,
      hideLiveBlogHeadlineText,
      hideLiveBlogBadge,
      showCardDek = true,
      showCardByline = false,
      showAuthor = false,
      numberOfAuthors = 1,
    } = {},
  } = content || {};
  const { headline, dek, url } = computedValues || {};

  const showTimestamp = showTimestampSettings !== false;
  const showBreaking = defaultBreakingStyle
    // if default is to be breaking, value must explicitly be set to false to be turned off
    ? showBreakingStyle !== false
    // if default is to be not breaking, value must explicitly be set to true to be turned on
    : showBreakingStyle === true;

  const labelToDisplay = label || (defaultLabel ? i18next.t(defaultLabel) : null);
  const vertical = React.useContext(VerticalContext);
  const { isFluidWidthPage } = React.useContext(LayoutContext);
  const { href, deviceUrl, target } = getLinkAttribs(url, id, isEmbedded, isWebEmbed, vertical);
  const mspShowCards = !isMultiStoryline || (maxCardsOverride > 0);
  const showCards = cards && !!cards.length && mspShowCards;

  const computedShowLabel = !hideLiveBlogBadge || (isEmbedded && isStorylinePackage); // Biz-logic: For some embedded packages show default label
  const isBylineOnly = !showDek && hideLiveBlogHeadlineText && hideLiveBlogBadge;

  /**
   *
   */
  const displayBadgeOrLiveUpdate = () => (
    <>
      {
        showLiveBadge && defaultLabel ? (
          <div className={classNames({ 'dib live-blog-pkg__liveBadge isInline': liveBlogStyle === 'storylineChatStyle' || liveBlogStyle === 'reportedStyle' })}>
            <BadgeLabel badgeType={BADGES.LIVE} />
          </div>
        )
          : (
            <span
              className={classNames(
                'live-blog-pkg__headline__label',
                {
                  'live-blog-pkg__headline__label--breaking': showBreaking,
                  'live-blog-pkg__headline__label--headline-text-hidden': hideHeadlineText,
                  dn: !labelToDisplay,
                },
              )}
              data-test="live-blog-pkg__label"
              data-testid="live-blog-pkg__label"
            >
              {labelToDisplay}
              {!hideHeadlineText && ' / '}
            </span>
          )
      }
    </>
  );

  return (
    <div
      className={classNames(
        'live-blog-pkg',
        {
          'live-blog-pkg--embedded': isEmbedded,
          'live-blog-pkg--breaking': showBreaking,
          'live-blog-pkg--storyline': isStorylinePackage,
          [`live-blog-pkg--${liveBlogStyle}`]: liveBlogStyle,
        },
      )}
      data-contentid={id}
      data-test="live-blog-pkg"
      data-testid="live-blog-pkg"
    >
      {headline && !isMultiStoryline && (
        // Must send href as null since Link component uses deviceUrl as separate fallback prop
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        isFluidWidthPage ? (
          <PackageTitle
            metadata={{ title: headline, seeAllUrl: href }}
          />
        ) : (
          <Link
            className="live-blog-pkg__column"
            data-test="live-blog-pkg__link"
            data-testid="live-blog-pkg__link"
            href={href} // use deviceUrl if embedded
            deviceUrl={deviceUrl}
            target={target}
          >
            <h2
              className={classNames('live-blog-pkg__headline', {
                'live-blog-pkg__headline--rail': isRailAdjacent,
                [`live-blog-pkg__headline--${liveBlogStyle}`]: liveBlogStyle,
              })}
              data-test="live-blog-pkg__headline"
              data-testid="live-blog-pkg__headline"
            >
              {computedShowLabel && <>{displayBadgeOrLiveUpdate()}</>}
              {!hideHeadlineText && (
                <span
                  data-test="live-blog-pkg__headline__text"
                  data-testid="live-blog-pkg__headline__text"
                  className="live-blog-pkg__headline__text"
                >
                  {headline}
                </span>
              )}
            </h2>
          </Link>
        ))}

      {dek && !isMultiStoryline && (showDek || (cards && cards.length <= 2)) && (
        <p className="live-blog-pkg__dek live-blog-pkg__column" data-test="live-blog-pkg__dek" data-testid="live-blog-pkg__dek">
          {dek}
        </p>
      )}

      {renderByline && showAuthor ? (
        <div className="live-blog-pkg__byline" data-test="live-blog-pkg__byline" data-testid="live-blog-pkg__byline">
          <div
            className={classNames('author-top-spacer', {
              'author-top-spacer--with-dek': showDek,
              'author-top-spacer--byline-only': isBylineOnly,
            })}
            data-test="live-blog_author-top-spacer"
            data-testid="live-blog_author-top-spacer"
          />
          <BylineList authors={getLimitedAuthors(item?.authors, numberOfAuthors)} />
        </div>
      ) : null}

      {showCards && (
        <ul
          data-test="live-blog-pkg__list"
          data-testid="live-blog-pkg__list"
          className={classNames('live-blog-pkg__list live-blog-pkg__column', {
            'live-blog-pkg__list--wrapped': !showDek,
            'live-blog-pkg__list--rail': isRailAdjacent,
            [`live-blog-pkg__list--${liveBlogStyle}`]: liveBlogStyle,
          })}
        >
          {cards.map((card, index) => {
            if (index < Math.min(maxCards, maxCardsOverride)) {
              return (
                <Card
                  isBreaking={showBreaking}
                  isEmbedded={isEmbedded}
                  isWebEmbed={isWebEmbed}
                  showTimestamp={showTimestamp}
                  showCardDek={showCardDek}
                  showCardByline={showCardByline}
                  content={card}
                  key={`lb-${card.id}`}
                  liveBlogStyle={liveBlogStyle}
                />
              );
            }
            return null;
          })}
        </ul>
      )}
    </div>
  );
}

export default CardList;
