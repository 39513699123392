import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TeaseRow } from 'components/packages/TeaseRow';
import { Standard } from 'components/packages/FeatureLead/Standard';
import { TwoLead } from 'components/packages/FeatureLead/TwoLead';

import { package as PackagePropType } from 'lib/CustomPropTypes';
import { teaseCard as paidContent } from 'lib/paidContentUtils';

import styles from './styles.module.scss';

/**
 * Creates FeatureLead package. Full width package renders main story. Intended to be viewable on homepage only.
 * @param {PackagePropType} content package data
 * @param {string} pkgClassName Additional classnames to provide to the package
 */
const FeatureLead = ({
  content,
  pkgClassName = 'pkg featureLead',
}) => {
  if (!content?.items?.length) return null;

  const { items, id, subType } = content;

  // set content items to use in the lead component, depending on lead type
  const leadItems = subType === 'twoLead' ? items.slice(0, 2) : items[0];

  // set content items to pass to the TeaseRow component
  const teaseRowItems = subType === 'twoLead' ? items.slice(2) : items.slice(1);
  const teaseRowContent = {
    subType: 'fullWidth',
    items: [...teaseRowItems, paidContent],
  };

  // to support switching to Cover Lead type in the future
  let Lead = null;
  if (subType === 'twoLead') {
    Lead = TwoLead;
  } else {
    Lead = Standard;
  }

  const classes = classNames(
    pkgClassName,
    styles.section,
    'featureLead',
    styles[subType],
  );

  return (
    <section
      className={classNames(classes)}
      data-test="feature-lead__section"
      data-testid="feature-lead__section"
      data-packageid={id}
      data-activity-map="featureLead-package"
    >
      <Lead content={leadItems} />
      <div className={styles.fluidWidth}>
        <TeaseRow content={teaseRowContent} pkgClassName="tease-row--edge-to-edge" isStandalone={false} />
      </div>
    </section>
  );
};

FeatureLead.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};


export { FeatureLead };
