import React from 'react';
import PropTypes from 'prop-types';

import {
  content as ContentPropType,
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';
import { SmallTease } from '../SmallTease';
import { SmorgasbordTease } from '../Tease/index';
import { MAIN_ART_TEASE_VALID_SIZES, TEASE_SIZES } from '../constants';

/**
 * Component to render the correct size tease component (S->XL).
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The item to render in the tease
 * @param {boolean} props.showByline Will turn on/off the byline in the tease
 * @param {boolean} props.showEyebrow Will turn on/off the eyebrow in the tease
 * @param {boolean} props.showDek Will turn on/off the dek in the tease
 * @param {string} props.size the size of the tease to render (see VALID_SIZES for list)
 * @param {JSON} props.packageMetadata the metadata from the package the tease will be rendered inside. Used to render the playmaker.
 * @returns {JSX.Element} Component
 */
export const MainArtTease = ({
  item,
  componentType,
  layout,
  showByline = true,
  showEyebrow = true,
  maxRelatedItems = 0,
  showDek = false,
  size = TEASE_SIZES.S,
  packageMetadata = null,
}) => {
  if (!item || !MAIN_ART_TEASE_VALID_SIZES.includes(size)) {
    return null;
  }
  if (size === TEASE_SIZES.S) {
    return (
      <SmallTease
        item={item}
        showByline={showByline}
        showEyebrow={showEyebrow}
        showRelated={!!maxRelatedItems}
      />
    );
  }
  return (
    <SmorgasbordTease
      item={item}
      size={size}
      showByline={showByline}
      showEyebrow={showEyebrow}
      maxRelatedItems={maxRelatedItems}
      showDek={showDek}
      packageMetadata={packageMetadata}
      componentType={componentType}
      layout={layout}
    />
  );
};

MainArtTease.propTypes = {
  item: ContentPropType.isRequired,
  showByline: PropTypes.bool,
  showEyebrow: PropTypes.bool,
  showDek: PropTypes.bool,
  size: PropTypes.oneOf(MAIN_ART_TEASE_VALID_SIZES),
  maxRelatedItems: PropTypes.number,
  packageMetadata: PlaymakerMetadataPropType,
  componentType: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

