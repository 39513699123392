import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  Children,
  forwardRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import classnames from 'classnames';

import Breakpoints from 'lib/Breakpoints';

import { getContainerMeasurements } from './getContainerMeasurements';

import styles from './styles.module.scss';

/**
 * @type {React.FunctionComponent}
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {number} props.columnGap
 * @param {((goToNextPage: function) => React.ReactNode)|React.ReactNode} props.nextButtonSlot
 * @param {boolean} props.isNextButtonVisible
 * @param {((goToPrevPage: function) => React.ReactNode)|React.ReactNode} props.prevButtonSlot
 * @param {boolean} props.isPrevButtonVisible
 * @returns {JSX.Element}
 */
// eslint-disable-next-line prefer-arrow-callback
export const BaconSlider = forwardRef(function BaconSlider({
  children = null,
  columnGap = 2,
  prevButtonSlot = null,
  nextButtonSlot = null,
  isPrevButtonVisible = false,
  isNextButtonVisible = true,
  className = null,
  offsetRight = 0,
}, ref) {
  const scrollContainerRef = useRef(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const measurementsChangeHandlerRef = useRef(null);

  const sliderItemsCount = Children.count(children);

  useEffect(() => {
    // create a throttled handler func for taking measurements of the slider container on scroll and
    // breakpoint change
    const scrollResizeHandler = throttle(() => {
      measurementsChangeHandlerRef.current?.(getContainerMeasurements(
        scrollContainerRef.current,
        prevButtonRef.current,
        columnGap,
        sliderItemsCount,
      ));
    }, 200);

    // add the handler to the events
    scrollContainerRef.current.addEventListener('scroll', scrollResizeHandler);
    Breakpoints.listenToAll(scrollResizeHandler);

    // fire the handler once immediately when this hook is fired, which can be, based on deps array,
    // one of the following:
    // - on component load (on the client)
    // - when the scrolling container ref changes
    // - when the previous button element changes
    // - when the column gap changes
    // - when the number of BaconSlider items changes
    measurementsChangeHandlerRef.current?.(getContainerMeasurements(
      scrollContainerRef.current,
      prevButtonRef.current,
      columnGap,
      sliderItemsCount,
    ));

    // return a cleanup function which will remove the scroll and breakpoint event listeners
    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', scrollResizeHandler);
      Breakpoints.removeFromAll(scrollResizeHandler);
    };
  }, [
    scrollContainerRef.current,
    prevButtonRef.current,
    columnGap,
    sliderItemsCount,
  ]);

  // create a callback for going to a particular page
  const goToPage = useCallback((pageNumOrGetPageNum) => {
    const measurements = getContainerMeasurements(
      scrollContainerRef.current,
      prevButtonRef.current,
      columnGap,
      sliderItemsCount,
    );
    // if pageNumOrGetPageNum is a function, pass that function the current measurements so they can
    // be used to determine which page to scroll to
    const pageNum = typeof pageNumOrGetPageNum === 'function'
      ? pageNumOrGetPageNum(measurements)
      : pageNumOrGetPageNum;
    // get the actual scroll amount (in px)
    const scrollAmount = measurements.getScrollAmtFromPageNum(pageNum);
    scrollContainerRef.current?.scrollTo({
      behavior: 'smooth',
      left: scrollAmount,
    });
  }, [
    scrollContainerRef.current,
    prevButtonRef.current,
    columnGap,
    sliderItemsCount,
  ]);

  const goToNextPage = useCallback(() => {
    goToPage(({ currentPage, totalPages }) => {
      const nextPage = currentPage + 1;
      // if the next page is greater than the total number of pages, go to the first page
      return nextPage > totalPages
        ? 1
        : nextPage;
    });
  }, [goToPage]);

  const goToPrevPage = useCallback(() => {
    goToPage(({ currentPage }) => (
      Math.max(currentPage - 1, 1)
    ));
  }, [goToPage]);

  // the imperative handle is used for connecting the `useBaconSlider` hook to
  // this component via ref, which the BaconSlider Consumer is responsible for
  // passing to its instance of BaconSlider
  useImperativeHandle(ref, () => ({
    getContainerMeasurements: () => getContainerMeasurements(
      scrollContainerRef.current,
      prevButtonRef.current,
      columnGap,
      sliderItemsCount,
    ),
    // the `useBaconSlider` hook (or a BaconSlider consumer) can use this method to
    // reigster a handler for when the scrolling container's measurements change
    addMeasurementsChangeHandler: (handler) => {
      handler(getContainerMeasurements(
        scrollContainerRef.current,
        prevButtonRef.current,
        columnGap,
        sliderItemsCount,
      ));
      measurementsChangeHandlerRef.current = handler;
    },
    removeMeasurementsChangeHandler: () => {
      measurementsChangeHandlerRef.current = null;
    },
    goToPage,
    goToNextPage,
    goToPrevPage,
  }));

  return (
    <div className={classnames(styles.baconSlider, className)}>
      <div className={styles.baconWrapper}>
        <div
          className={classnames({ [styles.isVisible]: isPrevButtonVisible }, styles.prevBtn)}
          ref={prevButtonRef}
          aria-hidden={!isPrevButtonVisible}
        >
          {typeof prevButtonSlot === 'function' ? prevButtonSlot(goToPrevPage) : prevButtonSlot}
        </div>
        <div
          className={styles.baconSliderScrollContainer}
          style={{
            '--bacon-slider-column-gap': `${columnGap}px`,
            '--bacon-slider-offset-right': `${offsetRight}px`,
          }}
          ref={scrollContainerRef}
        >
          {children}
        </div>
        <div
          className={classnames({ [styles.isVisible]: isNextButtonVisible }, styles.nextBtn)}
          ref={nextButtonRef}
        >
          {typeof nextButtonSlot === 'function' ? nextButtonSlot(goToNextPage) : nextButtonSlot}
        </div>
      </div>
    </div>
  );
});

BaconSlider.propTypes = {
  children: PropTypes.node,
  columnGap: PropTypes.number,
  prevButtonSlot: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  nextButtonSlot: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isPrevButtonVisible: PropTypes.bool,
  isNextButtonVisible: PropTypes.bool,
  className: PropTypes.string,
  offsetRight: PropTypes.number,
};

export { useBaconSlider } from './hooks/useBaconSlider';
