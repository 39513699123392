import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withCookies, Cookies } from 'react-cookie';
import { ACCESS_ENABLER_OBJ, configTempPass } from 'lib/tve';

import TVECountdown from 'components/TVE/Countdown';
import ProviderLogo from 'components/TVE/Logo';
import ProviderSelect from 'components/TVE/ProviderSelect';


const block = 'live-video-embed';

function MSNBCMetadataComponent({
  cookies,
  authWithProvider = false,
  hasTempPass = false,
  providerSelectVisible = false,
  showProviderSelect = Function.prototype,
}) {
  const { t } = useTranslation();

  const iframeRef = React.useRef(null);

  const getExpiration = () => {
    const { expirationName } = configTempPass;
    return parseInt(cookies.get(expirationName), 10);
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    setTimeout(window[ACCESS_ENABLER_OBJ].logout, 0);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    showProviderSelect();
  };

  const handleClick = (e) => {
    if (authWithProvider) {
      handleSignOut(e);
      return;
    }

    handleSignIn(e);
  };

  const buttonText = authWithProvider ? 'SIGN OUT' : 'SIGN IN';
  const noAuth = !hasTempPass && !authWithProvider;

  return (
    <div className={classNames(`${block}__msnbc_metadata`, {
      'without-provider-auth': !authWithProvider,
    })}
    >
      {hasTempPass && (
        <button
          className={`${block}__countdown`}
          onClick={handleSignIn}
          type="button"
        >
          {t('DAILY PREVIEW EXPIRES')}
          &nbsp;
          <span className={`${block}__tvecountdown`}>
            <TVECountdown
              end={getExpiration()}
              onEndCallback={() => {
                if (
                  typeof window !== 'undefined'
                  && typeof window[ACCESS_ENABLER_OBJ] !== 'undefined'
                  && typeof window[ACCESS_ENABLER_OBJ].logout === 'function'
                ) {
                  window[ACCESS_ENABLER_OBJ].logout();
                }
              }}
            />
          </span>
        </button>
      )}
      {authWithProvider && <ProviderLogo useColorLogo />}
      <div className={`${block}__sign-in`}>
        {providerSelectVisible && <ProviderSelect iframeRef={iframeRef} />}
        {noAuth && (
          <button
            className={`${block}__sign-in--pretext`}
            onClick={handleSignIn}
            type="button"
          >
            {t('TO CONTINUE WATCHING')}
          </button>
        )}
        <button
          type="button"
          className={classNames('lock-icon', {
            'icon icon-lock': !authWithProvider,
          })}
          onClick={(e) => handleClick(e)}
        >
          {t(buttonText)}
        </button>
      </div>
    </div>
  );
}

MSNBCMetadataComponent.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  authWithProvider: PropTypes.bool,
  hasTempPass: PropTypes.bool,
  providerSelectVisible: PropTypes.bool,
  showProviderSelect: PropTypes.func,
};


const MSNBCMetadata = withCookies(MSNBCMetadataComponent);

export { MSNBCMetadata };
