import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { parseDate } from 'lib/DateTime';
import { getTeaseUrl } from 'lib/teaseUtils';

import { BylineList } from 'components/Byline';
import Link from 'components/Link';
import { Picture } from 'components/Picture';
import { Unibrow } from 'components/Unibrow';

import { passTrackingMSNBCDaily } from '../../utility';
import styles from './styles.module.scss';

function Featured({
  item,
  showFeaturePublishedDate = false,
}) {
  const imageURL = getTeaseUrl(item, false);
  const authors = get(item, ['item', 'authors'], []);
  const date = parseDate(get(item, ['item', 'datePublished']), null);

  // Display last two digits of year
  let { year } = date;
  if (year && typeof year === 'number' && year > 2000) {
    year = year.toString().substr(-2);
  }

  // Computed
  const altText = get(item, ['computedValues', 'teaseImage', 'altText']);
  const teaseHeight = get(item, ['computedValues', 'teaseImage', 'height']);
  const teaseWidth = get(item, ['computedValues', 'teaseImage', 'width']);
  const headline = get(item, ['computedValues', 'headline']);
  const unibrow = get(item, ['computedValues', 'unibrow']);
  let url = get(item, ['computedValues', 'url']);

  // Append tracking to content and unibrow links
  const trackingType = 'pkgArticle';
  url = passTrackingMSNBCDaily(url, trackingType);

  return (
    <div className={styles.featuredArticle} data-contentid={item.id} data-testid="featured-article">
      {imageURL && (
        <div className={styles.picture}>
          <Link to={url.url} icid={url.icid}>
            <Picture
              alt={altText}
              lazyloaded
              originalHeight={teaseHeight}
              originalWidth={teaseWidth}
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_758x379,
                m: AIMS_FLAVORS.FOCAL_320x160,
                l: AIMS_FLAVORS.FOCAL_200x100,
              }}
              url={imageURL}
            />
          </Link>
        </div>
      )}
      <div className={styles.info}>
        <Unibrow
          className={classNames(styles.unibrow, { [styles.withDate]: showFeaturePublishedDate })}
          size="h4"
          unibrow={unibrow}
          icid={trackingType}
        />
        <h3 className={styles.headline}>
          <Link to={url.url} icid={url.icid}>
            {showFeaturePublishedDate && (
              <span className={styles.date}>
                {date.month}
                .
                {date.day}
                .
                {year}
                {' / '}
              </span>
            )}
            {headline}
          </Link>
        </h3>
        {Array.isArray(authors) && authors.length > 0 && (
          <div className={styles.author} data-testid="author">
            <BylineList isMsnbcDaily authors={authors} />
          </div>
        )}
      </div>
    </div>
  );
}

Featured.propTypes = {
  item: ContentPropType.isRequired,
  showFeaturePublishedDate: PropTypes.bool,
};


export { Featured };
