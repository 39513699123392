/* eslint-disable jsdoc/require-jsdoc */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import './styles.themed.scss';
import TimeAgo from 'components/packages/LiveBlog/TimeAgo';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { LinkIfHref } from 'components/Link';
import { fetchLatestCardsForPkg } from 'redux/modules/liveBlog';
import { querySettings } from 'lib/liveBlogPkgHelpers';
import { LINK_TYPES, PKG_HEADERS } from './constants';
import { CuratedList } from './CuratedList';

const { LIVE_COVERAGE, EXTERNAL_LINK, ARTICLE } = LINK_TYPES;
const { GO_BIG, STANDARD } = PKG_HEADERS;
const LIVE_BLOG_POLLING_INTERVAL = 30000;

/**
 * @param {object} content
 * @returns {React.ReactElement} Component
*/

function SubNav({
  liveBlogPkgInstances,
  content,
  dispatch,
  shouldOpenLinkInNewTab,
}) {
  const { query: { vertical = 'news' } } = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const {
    content: {
      metadata: {
        pkgHeaderStyle = undefined,
        packageTitle = null,
        electionTheme = false,
        packageTitleUrl = null,
        logoUrl = null,
      } = {},
    },
  } = content;

  const isMsnbc = vertical === 'msnbc';
  const linkTypesArray = [LIVE_COVERAGE, EXTERNAL_LINK, ARTICLE];
  const teaseImg = content && content?.content?.subnav?.teaseImage?.url?.primary;
  const mobileTeaseImg = content && content?.content?.subnav?.mobileTeaseImage?.url?.primary;
  const headline = packageTitle || (content && content?.subnav?.headline?.tease);
  const navLinks = content?.content?.subnav?.content?.items;
  const pkgTitleUrl = packageTitleUrl || content?.content?.subnav?.externalUrl;
  // Package Header Style
  const isGoBig = pkgHeaderStyle === GO_BIG;
  const isStandard = pkgHeaderStyle === STANDARD;
  const isNone = pkgHeaderStyle === undefined;

  const fetchData = async () => {
    const { page, queryLimit, shownOnFronts } = querySettings;
    for (let i = 0; i < navLinks?.length; i += 1) {
      const link = navLinks[i];
      if (link.flag === LIVE_COVERAGE) {
        dispatch(fetchLatestCardsForPkg({
          articleId: String(link.id).replace('cna', ''),
          queryLimit,
          page,
          shownOnFronts,
        }));
      }
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, LIVE_BLOG_POLLING_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  // Go big Subnav horizontal lines
  const subnavHr = (side) => (
    <hr className={classNames('hr-line', { [`${side}-election-theme`]: electionTheme })} />
  );
  const imgStyling = {
    'pkg-logo': isGoBig,
    'standard-logo': isStandard,
    'standard-title-no-nav': isStandard && !navLinks,
  };

  const imgRender = (
    <>
      <img
        alt="Logo for Subnav"
        className={classNames('mobile-title-img', imgStyling)}
        src={mobileTeaseImg || teaseImg || logoUrl}
      />
      <img
        alt="Logo for Subnav"
        className={classNames('desktop-title-img', imgStyling)}
        src={teaseImg || logoUrl}
      />
    </>
  );


  const renderLogo = () => {
    // If Drupal or Curator have logo images
    if (teaseImg || logoUrl) return imgRender;
    // If no logos, then show either headline from Drupal or Curator
    if (headline) return <span className={classNames(imgStyling)}>{headline}</span>;
    return null;
  };

  const pkgTitle = () => {
    if (pkgTitleUrl) return <a rel="noreferrer" target={shouldOpenLinkInNewTab ? '_blank' : ''} href={pkgTitleUrl}>{renderLogo()}</a>;
    return renderLogo();
  };

  const liveCoverageNotice = (flag, modifiedAt) => (
    flag === LIVE_COVERAGE && (
      <span className="live-coverage">
        <InlineBlinkingLiveDot className="live-blink-dot" />
        <TimeAgo displayDate={modifiedAt} isInline />
      </span>
    )
  );

  const standardLayoutLogoDiv = isStandard && (
    <div className={classNames('standard-logo-div', {
      'standard-logo-border': navLinks,
      'standard-no-logo': isStandard && (!teaseImg && !logoUrl && !headline),
    })}
    >
      {pkgTitle()}
    </div>
  );

  /**
   * @param {object} subnavItem
   * @returns {React.ReactElement}
   */
  const subNavItemRender = (subnavItem) => {
    const {
      text,
      type,
      content: curatedListContent,
      url,
      headline: itemHeadline,
      flag,
      cards,
      id,
    } = subnavItem;

    if (linkTypesArray.indexOf(type) !== -1) {
      const pkgId = String(id).replace('cna', '');
      const theDate = liveBlogPkgInstances[pkgId]?.latestDate !== undefined
        ? liveBlogPkgInstances[pkgId]?.latestDate
        : cards?.items?.[0]?.date?.modifiedAt;
      return (
        <>
          <LinkIfHref href={url?.primary}>{itemHeadline?.tease || text}</LinkIfHref>
          {liveCoverageNotice(flag, theDate)}
        </>
      );
    }
    if (type === 'curatedList') {
      return (
        <CuratedList
          content={curatedListContent}
          headline={itemHeadline}
          text={text}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      );
    }
    return null;
  };
  const mappedSubNavLinks = !isNone && (
    <div className={classNames({ 'go-big-links-container': isGoBig })}>
      <div className={classNames({ 'links-container-wrapper': navLinks, 'dropdown-open': isDropdownOpen })}>
        <div className="links-container">
          {standardLayoutLogoDiv}
          {navLinks?.map(
            (subNavItem) => (
              <>
                <div data-icid="package-nav" className="link-item">
                  {subNavItemRender(subNavItem)}
                </div>
              </>
            ),
          )}
        </div>
      </div>
    </div>
  );

  const goBigLayout = ((
    headline !== undefined || (teaseImg && mobileTeaseImg) || logoUrl))
  && (
    <>
      <div className="go-big-title-container">
        {subnavHr('left')}
        <div className="go-big-subnav-title">{pkgTitle()}</div>
        {subnavHr('right')}
      </div>
    </>
  );

  return (
    (!isNone) && (
      <div data-activity-map="package-nav" className="subnav">
        {isMsnbc && isGoBig && <div className={classNames('msnbc-bar', { 'msnbc-election-theme': electionTheme })} />}
        {isGoBig && <>{goBigLayout}</>}
        {mappedSubNavLinks}
      </div>
    )
  );
}

SubNav.propTypes = {
  liveBlogPkgInstances: PropTypes.shape({}).isRequired,
  content: PropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  shouldOpenLinkInNewTab: PropTypes.bool,
};

SubNav.defaultProps = {
  shouldOpenLinkInNewTab: false,
};

const mapStateToProps = ({ liveBlog }) => ({ liveBlogPkgInstances: liveBlog.pkgInstances });
const PackageSubNav = connect(mapStateToProps)(SubNav);

export { PackageSubNav };
