import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import classNames from 'classnames';
import { StoryItem } from './StoryItem';
import { LeadItem } from './LeadItem';
import './styles.themed.scss';
import { ColumnsContainer } from '../ColumnsContainer';
import { ColumnOfItems } from '../ColumnOfItems';
import { getPackageData } from '../utils/packageDataManager';
import { getIs2024ScoreboardEmbed } from '../utils/getIs2024ScoreboardEmbed';

/**
 * @param {object} root0
 * @param {import('lib/CustomPropTypes/package').PackageType} root0.content
 * @returns {React.ReactElement} Component
 */
export function MultiStorylineContainer({ content }) {
  if (!content) return null;

  const {
    // package metadata
    pkgHeaderStyle,
    playmakerToggle,
    playmakerColumn,
    showVerticalBorderA,
    showVerticalBorderB,
    stackLeadColumnFirstOnMobile,
    column1Title,
    column1Logo,
    column2Title,
    column2Logo,
    column3Title,
    column3Logo,
    showBottomBorder,
    // multiStorylineColumnInfo
    leadColumn,
    columns,
    // zoneLayoutInfo
    zoneLayoutType,
    zoneRailAdjacent,
    zoneIsRail,
    // lead flags
    hasLeadColumn,
    // "lead item as package headline" information
    leadHeadlineInfo,
    // items organized into a list of columns filled with items
    itemsByColumn,
    layoutDef,
    items,
  } = getPackageData(content, 'multiStorylinePackageData');

  if (!layoutDef || !items) {
    /* eslint-disable-next-line no-console */
    console.error('MultiStoryline ERROR: Invalid Layout');
    return null;
  }

  let currentItemIndex = 0;
  let itemIndexInColumn = 0;

  /* Create the columnar JSX as a list of ColumnOfItems container StoryItems */
  const columnsJsx = itemsByColumn.map((itemList, columnIndex) => {
    // @@REFACTOR: Some of the variable calculations below should be in an accessor,
    // but some are just private variables for this loop. The difference is "would another
    // column-based feature need these same values?". If so we don't want those components
    // to recalculate them and they should be in an accessor.
    const columnNumber = columnIndex + 1;
    itemIndexInColumn = 0;
    const columnName = `Column${columnNumber}`;
    const columnClass = `items-column-${columnNumber}`;
    const numItems = itemList.length;
    const numItemsTriple = numItems % 3 === 0;
    const numItemsEven = numItems % 2 === 0;
    const itemsCountClass = `items-count-class-${numItems < 3 ? numItems : '3plus'}`;
    // playmakerColumn value will include column number with with Top or Bottom at the end of the value
    const showLive = playmakerToggle && playmakerColumn.includes(columnName);
    const showLiveIndex = showLive && playmakerColumn.includes('Top') ? 0 : numItems - 1;
    const isLeadColumn = leadColumn === `${columnName}Lead`;
    const isNotLeadColumn = (leadColumn !== undefined && leadColumn !== 'none') && !isLeadColumn;
    const innerColumnJoin = columnNumber < columns;
    const showBothVerticalBorders = (showVerticalBorderA && showVerticalBorderB);
    const showNoVerticalBorders = (!showVerticalBorderA && !showVerticalBorderB);
    const showOneVerticalBorder = !showBothVerticalBorders && !showNoVerticalBorders;
    const columnHasFlexItem = itemList.some(getIs2024ScoreboardEmbed);

    // rowModeGroupNumber calc, syncs with classes to make the columns-in-row-mode
    let rowModeGroupNumber = 3;
    if (numItems < 3) {
      // 1 or 2 items, use 2 columns
      rowModeGroupNumber = 2;
    } else if (numItemsTriple) {
      // make sure if evenly divisible by 3, it's 3 columns
      rowModeGroupNumber = 3;
    } else if (numItemsEven) {
      // if evenly divisible by two and not by three, use 2 columns
      // currently with our max of 7 items, only affects 4 items.
      rowModeGroupNumber = 2;
    }

    let currentColumnTitle;
    let currentColumnLogo;
    switch (columnIndex) {
      case 0:
        currentColumnTitle = column1Title;
        currentColumnLogo = column1Logo;
        break;
      case 1:
        currentColumnTitle = column2Title;
        currentColumnLogo = column2Logo;
        break;
      default:
        currentColumnTitle = column3Title;
        currentColumnLogo = column3Logo;
    }
    const columnEl = (
      <ColumnOfItems
        key={`${columnName}`}
        columnName={columnName}
        horizontalItemDividerClassName="horizontal-item-divider"
        rowModeDividerClassName="row-mode-horizontal-divider"
        rowModeGroupNumber={rowModeGroupNumber}
        columnTitle={currentColumnTitle}
        columnLogo={currentColumnLogo}
        className={classNames(
          'multistory-items-column',
          columnClass, // items-column-N
          itemsCountClass, // needed for column-3 rearrange
          {
            [`coi-zone-layout-${zoneLayoutType}`]: true,
            'coi-zone-layout-not-fullWidth': zoneLayoutType !== 'fullWidth',
            'coi-zone-is-rail-adjacent': zoneRailAdjacent,
            'coi-zone-is-rail': zoneIsRail,
            'items-count-is-triple': numItemsTriple,
            'items-count-is-even': numItemsEven,
            'two-column': columns === 2,
            'three-column': columns === 3,
            'show-vertical-border-1': showVerticalBorderA,
            'show-vertical-border-2': showVerticalBorderB,
            'both-vertical-borders': showBothVerticalBorders,
            'one-vertical-border': showOneVerticalBorder,
            'lead-column': isLeadColumn,
            'not-lead-column': isNotLeadColumn,
            'has-lead-column': hasLeadColumn,
            'no-lead-column': !hasLeadColumn,
            'before-inner-vertical-join': innerColumnJoin,
            'has-flex-item': columnHasFlexItem,
          },
        )}
      >
        {
          itemList.reduce((jsxList, itemData, index) => {
            const storyItemJsx = itemData && (
              <StoryItem
                className={classNames(
                  'multistory-item',
                  {
                    'row-mode-860': hasLeadColumn && columnNumber === 3,
                  },
                )}
                key={`${itemData.id}-${columnName}`}
                content={content}
                itemData={itemData}
                showLive={showLive && showLiveIndex === index}
                columnIndex={columnIndex}
                itemIndex={currentItemIndex}
                itemIndexInColumn={itemIndexInColumn}
                showVerticalBorder={showVerticalBorderA}
                hideHeadlineAndDek={leadHeadlineInfo?.item === itemData}
              />
            );

            // The currentItemIndex has to be tracked because
            // it's needed when the content item is a live blog.
            // <LiveBlog> gets the item from package data itself
            // at the given itemIndex.

            currentItemIndex += 1;
            itemIndexInColumn += 1;
            jsxList.push(storyItemJsx);

            return jsxList;
          }, [])
        }
      </ColumnOfItems>
    );
    return columnEl;
  });

  const verticalDividersList = columns === 3
    ? [showVerticalBorderA, showVerticalBorderB] : [showVerticalBorderA];
  return (
    <div
      className={classNames(
        'multistory-container',
        {
          'two-column': columns === 2,
          'three-column': columns === 3,
          'has-lead-column': hasLeadColumn,
          'no-lead-column': !hasLeadColumn,
          'package-go-big': pkgHeaderStyle === 'Go Big',
        },
      )}
      data-testid="multi-storyline-container"
    >
      <LeadItem leadItemContent={leadHeadlineInfo} />
      <ColumnsContainer
        className={classNames(
          {
            [`cc-zone-layout-${zoneLayoutType}`]: true,
            'cc-zone-layout-not-fullWidth': zoneLayoutType !== 'fullWidth',
            'cc-zone-is-rail-adjacent': zoneRailAdjacent,
            'cc-zone-is-rail': zoneIsRail,
          },
        )}
        leadColumn={leadColumn}
        stackLeadColumnFirstOnMobile={stackLeadColumnFirstOnMobile}
        verticalDividers={verticalDividersList}
      >
        {columnsJsx}
      </ColumnsContainer>
      { showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </div>
  );
}

MultiStorylineContainer.propTypes = {
  content: PackagePropType.isRequired,
};
