import get from 'lodash.get';
// Module Level Methods
// This module acts a singleton via this configuration.
let accessorsLibrary = {};

/**
 * @typedef {import('./packageDataManagerTypeDefs').AccessorLibrary} AccessorLibrary
 */

/**
 *
 * @param {AccessorLibrary} newAccessorsLibrary
 */
export function setAccessorsLibrary(newAccessorsLibrary) {
  accessorsLibrary = { ...newAccessorsLibrary };
}

/* General Purpose Layer
    The `getData` function is the bottom level configuration-driven executor of accessors.
    It uses `sourceType` and `dataName` to select an accessor function to call.
    The higher level (package and item specific) functions are provided
    because they are more convenient due to being more specific and less abstract.
*/

/**
 * Executes a named accessor based on sourceType and dataName
 * @param {object} args
 * @param {string} args.sourceType
 * @param {string} args.dataName
 * @param {object} args.source
 * @param {object} args.fields
 * @param {*} args.fallback
 */
export function getData({
  sourceType, dataName, source, fields, fallback,
} = {}) {
  if (!dataName || !source) return fallback;

  const accessorsDict = accessorsLibrary[sourceType] || [];
  const accessor = accessorsDict[dataName];
  let value;
  if (accessor) {
    value = accessor(source, {
      dataName,
      sourceType,
      fields,
    });
  } else {
    value = get(source, dataName);
  }

  if (value === undefined && fallback !== undefined) {
    value = fallback;
  }

  return value;
}

/* Schema Access Layer
    A pair of functions for getting specifically package or specifically item data.
*/

/**
 * Retrieves package data based on the provided parameters.
 * @param {Package} packageData - The data source for the package.
 * @param {string} dataName - The name of the data to retrieve.
 * @param {object} fields - Accessor-specific options passed in `opts.fields`.
 */
export function getPackageData(packageData, dataName, fields) {
  return getData({
    sourceType: 'package',
    source: packageData,
    dataName,
    fields,
  });
}

/**
 * Retrieves item data based on the provided parameters.
 * @param {ContentItem} itemData - The data source for the item.
 * @param {string} dataName - The name of the data to retrieve.
 * @param {object} fields - Accessor-specific options passed in `opts.fields`.
 */
export function getItemData(itemData, dataName, fields) {
  return getData({
    sourceType: 'item',
    source: itemData,
    dataName,
    fields,
  });
}
