/**
 * Gets the URL to use for the headline.
 *
 * @param {string} itemDataType - The item data type.
 * @param {object} metadata - The item metadata object.
 * @param {string} itemUrl - The URL of the item.
 * @returns {string} The URL to use for the headline.
 */
export const getHeadlineUrl = (itemDataType, metadata, itemUrl) => {
  if (itemDataType === 'video' && metadata?.headlineUrlOverride) return metadata.headlineUrlOverride;
  if (itemDataType === 'custom' && metadata?.embedHeadlineUrl) return metadata.embedHeadlineUrl;
  return itemUrl;
};
