import React from 'react';
import { isBedrockApiEnabled, getAuthenticationState } from 'lib/authenticationUtils';
import { useGeoLocation } from 'lib/Hooks';
import { AccountLoginRegistration } from 'components/AccountLoginRegistration';
import { useBedrockRegistration } from 'store';
import { Gate } from 'components/Gate';
import { GATE_TITLES } from 'components/Gate/GateTextConstants';
import classNames from 'classnames';

import {
  AUTHENTICATED,
} from 'lib/myNewsConstants';

import styles from './styles.module.scss';

const letters = ['A', 'E', 'M', 'L', 'D', 'S', 'C', 'S', 'P', 'T', 'D', 'S', 'T', 'A', 'U', 'P'];

/**
 * Creates the signin prompt for the word search game. It should show
 * the gate or the account login flow based on feature flags.
 * @returns {React.ReactElement} - The rendered signin prompt
 */
export function SignInPrompt() {
  const isNewLoginFlow = isBedrockApiEnabled();
  const showBedrockGate = useBedrockRegistration((state) => state.showGate);
  const authenticationState = getAuthenticationState();
  const notAuthenticated = isBedrockApiEnabled()
    ? showBedrockGate : authenticationState !== AUTHENTICATED;
  const { isUsa } = useGeoLocation();
  const showLogin = notAuthenticated && isUsa && isNewLoginFlow;

  const showGateOrLogin = showLogin ? (
    <AccountLoginRegistration />
  ) : (
    <Gate className={styles.gate} title={GATE_TITLES.game} />
  );

  return (
    <div className={styles.container} data-testid="word-search-game--sign-in-prompt">
      <div className={classNames(styles.overlay, showLogin ? styles.accountLoginOverlay : '')}>
        <div className={styles.letters}>
          {letters.map((letter, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>{letter}</span>
          ))}
        </div>
      </div>
      {showGateOrLogin}
    </div>
  );
}
