import { useState, useEffect } from 'react';
import { useFeatureFlagContext } from 'lib/ContextTypes';

/**
* Custom hook that decides whether to show source name of asset
* @param {string} [sourceName=''] - The sourceName of the asset
* @returns {boolean} - indicating whether or not to show the sourceName of the asset
*/
export const useShouldShowSource = (sourceName = '') => {
  const { 'show-nbc-sports-front': shouldShowSportsFront } = useFeatureFlagContext();

  const [shouldShowSourceName, setShouldShowSourceName] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    const isNbcNewsWebsite = window.location.hostname.includes('nbcnews.com');
    const isSportsPath = path === '/sports' || path === '/sports/';
    const isSportsFront = isNbcNewsWebsite && isSportsPath;
    const isNotSportsFront = isNbcNewsWebsite && !isSportsPath;
    const showSourceName = isSportsFront || (isNotSportsFront && sourceName === 'NBC Sports');

    setShouldShowSourceName(showSourceName);
  }, []);

  if (!shouldShowSportsFront) return false;

  return shouldShowSourceName;
};
