import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'components/Button';
import TeasePicture from 'components/TeasePicture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import styles from './styles.module.scss';

const Promo3Card = ({
  dek,
  headline,
  buttonTitle,
  buttonUrl,
  contentId,
  hideTeaseImage = false,
  teaseImage,
}) => {
  const title = buttonTitle || i18next.t('MORE');

  return (
    <article
      data-contentid={contentId}
      className={classNames(
        styles.promo3Card,
        { [styles.promo3CardHasImage]: !hideTeaseImage },
      )}
    >
      <h2 className={styles.promo3CardHeadline}>
        {headline}
      </h2>
      <p className={styles.promo3CardDek}>
        {dek}
      </p>
      <Button
        additionalClasses={styles.promo3PanelButton}
        tiltClasses={styles.promo3PanelButtonMultiLine}
        size="small"
        title={title}
        type="link"
        url={buttonUrl}
      />
      {!hideTeaseImage && teaseImage && (
        <TeasePicture
          className={styles.promo3CardImage}
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_100x100,
            m: AIMS_FLAVORS.FOCAL_120x120,
            xl: AIMS_FLAVORS.FOCAL_160x160,
          }}
          url={teaseImage}
        />
      )}
    </article>
  );
};

Promo3Card.propTypes = {
  buttonTitle: PropTypes.string,
  buttonUrl: PropTypes.string,
  contentId: PropTypes.string,
  dek: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  hideTeaseImage: PropTypes.bool,
  teaseImage: PropTypes.string,
};


export {
  Promo3Card,
};
