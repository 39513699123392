import React from 'react';
import classNames from 'classnames';

import * as CustomPropTypes from 'lib/CustomPropTypes';

import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import PackageHeader from 'components/PackageHeader';
import TitleLogo from 'components/TitleLogo';

import styles from './styles.module.scss';

/**
 * CollectionTitle component
 * Component to add title information to bundles of packaged content. Can be
 * rendered multiple times on a page. Also renders a 'See All' button to link
 * to additional content packages.
 * @param {object} props - Component properties
 * @param {import('lib/CustomPropTypes/content').Content} props.content - The content to display
 * @param {boolean} [props.alignRight=false] - Whether to align the title to the right
 * @param {string} [props.pkgClassName='pkg'] - Additional class name for the package
 */
const CollectionTitle = ({
  content,
  alignRight = false,
  pkgClassName = 'pkg',
}) => {
  const {
    metadata = {},
    packageIndex,
  } = content;

  const {
    description,
    logoUrl,
    seeAllText,
    seeAllUrl,
    title,
  } = metadata || {};

  const collectionTitleCx = classNames(
    styles.collectionTitle,
    pkgClassName,
    {
      [styles.mirror]: alignRight,
    },
  );

  return (
    <section className={collectionTitleCx}>
      {(logoUrl || title) && (
        <PackageHeader className={styles.title} packageIndex={packageIndex}>
          <TitleLogo logoUrl={logoUrl} title={title} />
        </PackageHeader>
      )}
      <p className={styles.dek}>
        {description}
      </p>
      {seeAllUrl && (
        <ButtonHoverAnimation
          additionalClasses={styles.button}
          hasDarkBackground
          size="normal"
          title={seeAllText}
          type="link"
          url={seeAllUrl}
        />
      )}
    </section>
  );
};

CollectionTitle.contextTypes = {
  ...CustomPropTypes.layoutContext,
};

export { CollectionTitle };
