import { getPackageData } from './accessorInterfaces';
import { presetDefaultsConfig, itemBaseDefaults } from './presetDefaultsConfig';

/**
 *
 * @param {Package} packageData
 */
export function getPresetPackageMetadata(packageData) {
  const metadata = getPackageData(packageData, 'packageMetadata');
  const { presetTemplate, leadColumn, columns = 2 } = metadata;
  const presetPackageDefaults = presetDefaultsConfig[presetTemplate]?.packageDefaults ?? {};

  const {
    leadColumn: presetLeadColumn,
    columns: presetColumns,
    itemsInColumns: presetItemsInColumns,
  } = presetPackageDefaults;
  const correctLeadColumn = presetTemplate && !leadColumn ? presetLeadColumn : leadColumn;
  const correctColumns = presetTemplate && !columns ? presetColumns : columns;
  const correctItemsInColumns = presetItemsInColumns
    || Array.from({ length: correctColumns }, () => 1);

  return {
    presetTemplate,
    leadColumn: correctLeadColumn,
    columns: correctColumns,
    itemsInColumns: correctItemsInColumns,
  };
}

/**
 * @param {object} args
 * @param {number} args.colIndex
 * @param {string} args.presetTemplate
 */
export const getDefaultItemsForColumn = ({ colIndex, presetTemplate }) => {
  const presetDefaults = presetDefaultsConfig[presetTemplate];
  const presetItemsInCol = presetDefaults?.packageDefaults?.itemsInColumns[colIndex];
  return presetItemsInCol || 1;
};

/**
 *
 * @param {string} presetName
 * @param {string} presetName.presetName
 * @param {number} presetName.columnIndex
 * @param {number} presetName.itemIndexInColumn
 */
export function getItemDefaults({ presetName, columnIndex, itemIndexInColumn }) {
  // lookup default preset defaults getter/generator and call it
  const fallbackItemDefaults = presetDefaultsConfig[presetName]?.fallbackItemDefaults;
  const baseDefaults = fallbackItemDefaults || { ...itemBaseDefaults() };

  const presetDefaults = presetDefaultsConfig[presetName]?.itemDefaults?.[columnIndex]?.[itemIndexInColumn] || {};
  const itemDefaults = {
    ...baseDefaults,
    ...presetDefaults,
  };

  return itemDefaults;
}
