import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  package as PackagePropType,
} from 'lib/CustomPropTypes';
import './styles.themed.scss';

import LeadBorderTop from 'components/LeadBorderTop';
import PackageTitle from 'components/PackageTitle';
import SlideshowTeasePicture from 'components/SlideshowTeasePicture';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { RENDER_SLIDESHOW_AS_COLLAGE_ON_COVER } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';

/**
 * @param {Slideshow|Tease} item
 * @param {*} context
 * @param {string} vertical - The vertical that the user is currently on
 */
function getPicture(item, context = {}, vertical) {
  const renderSlideshowAsCollageOnCover = getFeatureConfigForBrand(
    RENDER_SLIDESHOW_AS_COLLAGE_ON_COVER,
    vertical,
  );
  const { pageRoute } = context;
  const isCover = pageRoute === '/';

  if (item.type === 'slideshow') {
    if (!renderSlideshowAsCollageOnCover && isCover) {
      return (
        <TeasePicture
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_758x379,
            m: AIMS_FLAVORS.FOCAL_1000x500,
            l: AIMS_FLAVORS.FOCAL_1240x620,
            xl: AIMS_FLAVORS.FOCAL_1240x620,
          }}
          type={item.type}
          computedValues={item.computedValues}
        />
      );
    }
    return (
      <SlideshowTeasePicture
        slides={item.slides || item.item.slides}
        sizes={{
          s: 360, m: 460, l: 260, xl: 380,
        }}
        rectangle
      />
    );
  }
  return (
    <TeasePicture
      responsiveFlavors={{
        s: AIMS_FLAVORS.FOCAL_758x379,
        m: AIMS_FLAVORS.FOCAL_1000x500,
        l: AIMS_FLAVORS.FOCAL_1240x620,
        xl: AIMS_FLAVORS.FOCAL_1240x620,
      }}
      type={item.type}
      computedValues={item.computedValues}
    />
  );
}

/**
 * OneUp component
 * @param {object} props - Component properties
 * @param {import('lib/CustomPropTypes/package').PackageType} props.content - The content to display
 * @param {boolean} [props.isRailAdjacent=false] - Whether the component is rail adjacent
 * @param {boolean} [props.standalone=false] - Whether the component is standalone
 * @param {string} [props.pkgClassName='pkg one-up'] - Additional class name for the package
 * @param {string} [props.propStyles=''] - Additional styles for the component
 * @param {string} [props.vertical=''] - Vertical alignment for the component
 * @param {boolean} [props.isFirstLayout=false] - Whether this is the first layout
 * @param {boolean} [props.alternateTitleFormat=false] - Whether to use an alternate title format
 * @param {boolean} [props.isMobileOnlyComponent=false] - Whether the component is mobile only
 */
function OneUp({
  content,
  isRailAdjacent = false,
  standalone = false,
  pkgClassName = 'pkg one-up',
  propStyles = '',
  vertical = '',
  isFirstLayout = false,
  alternateTitleFormat = false,
  isMobileOnlyComponent = false,
}) {
  if (!content || !(content.items?.[0])) return null;
  const {
    id, items, metadata = {}, packageIndex, context,
  } = content;

  const item = items[0];
  const shouldSendPkgClassName = (
    standalone || pkgClassName === 'pkg leadSectionFront'
      ? pkgClassName
      : null
  );
  const pkgType = content?.type;
  const isFirstLayoutAndPackage = isFirstLayout && !packageIndex;
  const { showBottomBorder = false } = metadata;
  return (
    <section
      className={classNames(
        pkgClassName,
        `pkg-index-${packageIndex + 1 || 'rail'}`,
        {
          'one-up--has-accent-line': isFirstLayoutAndPackage,
        },
        'tease-card--one-up',
        propStyles,
      )}
      data-packageid={id}
      data-test="one-up__section"
      data-testid="one-up__section"
    >
      <LeadBorderTop types={['collectionLead']} />
      {metadata?.title && (
        <PackageTitle
          metadata={metadata}
          alternateDisplayFormat={alternateTitleFormat}
        />
      )}
      <TeaseCard
        isMobileOnlyComponent={isMobileOnlyComponent}
        content={item}
        isRailAdjacent={isRailAdjacent}
        pkgClassName={shouldSendPkgClassName}
        styles={{ ...propStyles }}
        vertical={vertical}
        pkgType={pkgType}
        {...metadata}
        {...item.metadata}
      >
        {getPicture(item, context, vertical)}
      </TeaseCard>
      {showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </section>
  );
}

OneUp.propTypes = {
  content: PackagePropType.isRequired,
  isRailAdjacent: PropTypes.bool,
  pkgClassName: PropTypes.string,
  propStyles: PropTypes.string,
  standalone: PropTypes.bool,
  vertical: PropTypes.string,
  isFirstLayout: PropTypes.bool,
  alternateTitleFormat: PropTypes.bool,
  isMobileOnlyComponent: PropTypes.bool,
};

export default OneUp;
