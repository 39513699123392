/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { trimNullPackageItems } from 'lib/packageHelper';

import PackageContext from './PackageContext';


// Package components
// Start
import { PromoBanner } from './PromoBanner';
import Ad from '../Ad';
import BigStoryTakeover from './BigStoryTakeover';
import DevPackage from './DevPackage';
import LiveBlog from './LiveBlog';
import LiveVideoPromo from './LiveVideoPromo';
import MidresponsiveAd from '../Ad/Midresponsive';
import OneUp from './OneUp';
import ShowLead from './ShowLead';
import Smorgasbord from './Smorgasbord';
import Taboola from '../Taboola';
import ThreeUp from './ThreeUp';
import TwoUp from './TwoUp';
import TVE from './TVE';
import UpController from './UpController';
import { ALaCarte } from './ALaCarte';
import { Bacon } from './Bacon';
import { Baguette } from './Baguette';
import { BigHeadline } from './BigHeadline';
import { Collection } from './Collection';
import { CollectionLead } from './CollectionLead';
import { CollectionTitle } from './CollectionTitle';
import { CoverSpread } from './CoverSpread';
import { CuratedUpdates } from './CuratedUpdates/index';
import { DynamicUp } from './DynamicUp';
import { Embed } from './Embed';
import { EntityHub } from './EntityHub';
import { ErrorBoundary } from '../ErrorBoundary';
import { FeatureLead } from './FeatureLead';
import { Feeds } from './Feeds';
import { LeadOneUp } from './LeadOneUp';
import { LeadSectionFront } from './LeadSectionFront';
import { LiveVideo } from './LiveVideo';
import { LiveVideoEmbed } from './LiveVideoEmbed';
import { LocalNews } from './LocalNews';
import { LocalWeather } from './LocalWeather';
import { MagazineLead } from './MagazineLead';
import { Mosaic } from './Mosaic';
import { MsnbcDaily } from '../MsnbcDaily';
import { MsnbcDailyAuthors } from './MsnbcDailyAuthors';
import { MultiStoryline as MultiStoryline3 } from './MultiStoryline3/index';
import { NativeAd } from './NativeAd';
import { Pancake } from './Pancake';
import { ProductWaffle } from './Waffle/ProductWaffle';
import { Promo3Panel } from './Promo3Panel';
import { Schedule } from './Schedule';
import { SelectCover } from './SelectCover';
import { StandardLead } from './StandardLead';
import { StickyAdTrack } from './StickyAdTrack';
import { Storyline } from './Storyline';
import { StraightUp } from './StraightUp';
import { TeaseList } from './TeaseList';
import { TeaseRow } from './TeaseRow';
import { Timeline } from './Timeline';
import { VideoPackage } from './VideoPackage';
import { Waffle } from './Waffle/Default';
import { AutomatedWaffle } from './Waffle/Automated';
import { WordSearchGame } from './WordSearchGame/index';
// End

/**
 *
 * @param {object} packageData
 * @param {object} props
 * @param {object} context
 */
function getPackage(packageData, props = {}, context = {}) {
  const {
    id,
    items,
    manuallyCuratedAd = false,
    metadata = {},
    isMidFront,
  } = packageData;
  const {
    adsDisabled,
    isRailAdjacent,
    layoutIndex,
    pageRoute,
    railContext,
    shouldMirror,
    vertical,
    zonePackageTypes,
    packageIndex,
    layoutType,
  } = context;
  const { slot } = props;

  switch (packageData.type) {
    /** UP PACKAGES  */
    case 'oneUp':
      return (
        <OneUp
          standalone
          content={packageData}
          isRailAdjacent={isRailAdjacent}
          key={id}
          {...props}
          vertical={vertical}
          isFirstLayout={!layoutIndex}
        />
      );
    case 'twoUp':
    case 'threeUp':
    case 'fourUp':
      return <UpController package={packageData} adsDisabled={adsDisabled} {...props} />;
    case 'sevenUp':
      if (items.filter((x) => x).length === 0) {
        return null;
      }
      /* TODO: this will be gone when oneUp, twoUp, threeUp are used instead */
      /* note that subpackages don't need package spacing */
      return (
        <StickyAdTrack
          dataTest="sevenUp__section"
          id={id}
          key={id}
          metadata={metadata}
          mirror={shouldMirror()}
          pkgClassName="pkg stickyAdTrack stickyAdTrack--7up"
          {...props}
        >
          <ThreeUp
            content={{
              ...packageData,
              items: items.slice(0, 3),
              metadata: { ...packageData.metadata, title: null },
            }}
            showFirstImageSource={false}
            {...props}
            pkgClassName="three-up three-up--7up"
            isFirstLayout={!layoutIndex}
          />
          <OneUp
            content={{
              ...packageData,
              items: [items[3]],
              metadata: { ...packageData.metadata, title: null },
            }}
            key={id}
            {...props}
            vertical={vertical}
            pkgClassName="one-up one-up--7up"
          />
          <TwoUp
            content={{
              ...packageData,
              items: items.slice(4, 6),
              metadata: { ...packageData.metadata, title: null },
            }}
            {...props}
            vertical={vertical}
            pkgClassName="two-up two-up--7up"
          />
          <OneUp
            content={{
              ...packageData,
              items: [items[6]],
              metadata: { ...packageData.metadata, title: null },
            }}
            {...props}
            pkgClassName="one-up one-up--7up"
            vertical={vertical}
          />
        </StickyAdTrack>
      );
    case 'dynamicUp':
      return (
        <DynamicUp
          key={id}
          package={packageData}
          railAdjacent={isRailAdjacent}
          vertical={vertical}
        />
      );

    /** NON-UP PACKAGES */
    case 'devPackage':
      return (
        <DevPackage
          content={packageData}
          pkgClassName="pkg-devPackage"
          {...props}
        />
      );
    case 'featureLead':
      return (
        <FeatureLead
          content={packageData}
          key={id}
          vertical={vertical}
          {...props}
        />
      );
    case 'accountPromo':
      return (
        <PromoBanner
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'ad':
      if (slot === 'midresponsive') return <MidresponsiveAd />;
      if (slot === 'frontMidbanners') {
        return (
          <>
            <div className="dn db-m">
              <Ad
                key={id}
                packageId={id}
                {...props}
                slot="topbanner"
                manuallyCuratedAd={manuallyCuratedAd}
                offsetViewport={100}
                pkgClassName="pkg-ad ad ad--centered"
                refreshInterval={0}
              />
            </div>
            <div className="dn-m">
              <Ad
                key={id}
                packageId={id}
                {...props}
                slot="boxinline"
                manuallyCuratedAd={manuallyCuratedAd}
                offsetViewport={50}
                pkgClassName="pkg-ad ad ad--centered"
                refreshInterval={0}
              />
            </div>
          </>
        );
      }
      return (
        <Ad
          key={id}
          packageId={id}
          {...props}
          manuallyCuratedAd={manuallyCuratedAd}
          offsetViewport={slot === 'boxinline' ? 50 : 100}
          pkgClassName="pkg-ad ad mh0"
        />
      );
    case 'aLaCarte':
      return (
        <ALaCarte key={id} content={packageData} {...props} />
      );
    case 'entityHub':
      return (
        <EntityHub key={id} content={packageData} {...props} />
      );
    case 'bacon':
      return (
        <Bacon
          content={{
            ...packageData,
            context: {
              pageType: 'cover',
            },
          }}
          key={id}
          pageRegion="page-front"
          railAdjacent={isRailAdjacent}
          shouldShowAdditionalSeller={
            packageData?.name?.toLowerCase() === 'product bacon'
            || packageData?.name?.toLowerCase() === 'bestsellers'
            || packageData?.subType === 'oneOneProduct'
          }
          vertical={vertical}
          {...props}
        />
      );
    case 'Baguette':
      return (
        <Baguette
          content={packageData}
          key={id}
          vertical={vertical}
          railAdjacent={isRailAdjacent}
          zonePackageTypes={zonePackageTypes}
          packageIndex={packageIndex}
          layoutType={layoutType}
          {...props}
        />
      );
    case 'bigHeadline':
      return (
        <BigHeadline
          content={packageData}
          key={id}
          vertical={vertical}
          {...props}
        />
      );
    case 'bigStoryTakeover':
      return (
        <BigStoryTakeover
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'collectionBillboard':
    case 'collectionPoster':
      return <Collection content={packageData} key={id} pkgClassName={packageData.type} />;
    case 'collectionsLead':
      return (
        <CollectionLead
          key={id}
          content={packageData}
          {...props}
        />
      );
    case 'collectionTitle':
      return (
        <CollectionTitle
          content={packageData}
          key={id}
          alignRight={railContext === 'right'}
          {...props}
        />
      );
    case 'coverSpread':
      return <CoverSpread content={packageData} key={id} />;
    case 'curatedUpdates':
      return <CuratedUpdates content={packageData} key={id} />;
    case 'embed':
      return <Embed content={packageData} key={id} {...props} />;
    case 'leadRectangle':
      return (
        <LeadOneUp
          packageId={id}
          content={items[0]}
          key={id}
          railAdjacent={isRailAdjacent}
          {...props}
        />
      );
    case 'leadSectionFront':
      return (
        <LeadSectionFront
          content={packageData}
          isRailAdjacent={isRailAdjacent}
          key={id}
          {...props}
        />
      );
    // Post List uses LiveBlog component as only live blog content type is supported currently
    case 'postList':
      return <LiveBlog key={id} content={packageData} {...props} />;
    case 'localNews':
      return <LocalNews content={packageData} placement="package" key={id} {...props} />;
    case 'localWeather':
      return <LocalWeather content={packageData} placement="package" key={id} {...props} />;
    case 'magazineLead':
      return <MagazineLead packageId={id} content={packageData} {...props} />;
    case 'mosaic':
      return <Mosaic content={packageData} key={id} {...props} />;
    case 'msnbcDailyArticles':
      return <MsnbcDaily content={packageData} key={id} {...props} />;
    case 'MultiStoryline3':
      return (
        <MultiStoryline3
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'MultiStoryline3Preset':
      return (
        <MultiStoryline3
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'pancake':
      return (
        <Pancake
          content={packageData}
          key={id}
          vertical={vertical}
          railAdjacent={isRailAdjacent}
          {...props}
        />
      );
    case 'partnerRecirc':
      return <Taboola content={packageData} key={id} {...props} />;
    case 'taboola':
      return (
        <Taboola
          content={packageData}
          key={id}
          isMidFront={isMidFront}
          {...props}
        />
      );
    case 'promo3Panel':
      return <Promo3Panel content={packageData} key={id} {...props} />;
    case 'showLead':
      return <ShowLead packageId={id} content={packageData} {...props} />;
    case 'smorgasbord':
      return <Smorgasbord packageId={id} content={packageData} {...props} />;
    case 'standardLead':
      return (
        <StandardLead
          content={packageData}
          isRailAdjacent={isRailAdjacent}
          key={id}
          {...props}
        />
      );
    case 'liveVideoPromo':
      return (
        <LiveVideoPromo
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'liveVideo':
      return (
        <LiveVideo
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'liveVideoEmbed':
      return (
        <LiveVideoEmbed
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'schedule':
      return (
        <Schedule
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'selectCover':
      return (
        <SelectCover key={id} content={packageData} {...props} />
      );
    case 'straightUp':
      return (
        <StraightUp
          content={packageData}
          isRailAdjacent={isRailAdjacent}
          key={id}
          {...props}
        />
      );
    case 'teaseList':
      return (
        <TeaseList key={id} content={packageData} {...props} />
      );
    case 'teaseRow':
      return (
        <TeaseRow key={id} content={packageData} pkgClassName="pkg teaseRow" {...props} />
      );
    case 'feeds':
      return (
        <Feeds
          isRail={!!railContext}
          isFullWidth={layoutType === 'fullWidth'}
          content={{
            ...packageData,
            items: trimNullPackageItems(items),
          }}
          key={id}
          vertical={vertical}
          route={pageRoute}
          {...props}
        />
      );
    case 'videoPkg':
      return <VideoPackage content={packageData} key={id} packageIndex={packageIndex} {...props} />;
    case 'waffle':
      switch (packageData.subType) {
        case 'autofilledProductWaffle':
        case 'productWaffle':
          return <ProductWaffle content={packageData} key={id} {...props} />;
        case 'automated':
          return <AutomatedWaffle content={packageData} key={id} {...props} />;
        default:
          return (
            <Waffle
              content={packageData}
              key={id}
              {...props}
              adsDisabled={adsDisabled}
            />
          );
      }
    case 'wordSearchGame':
      return <WordSearchGame content={packageData} key={id} />;
    case 'msnbcDailyAuthors':
      return (
        <MsnbcDailyAuthors
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'Storyline':
      return (
        <Storyline
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'timeline':
      return (
        <Timeline
          content={packageData}
          key={id}
          getTrackingPlacement={() => `timeline ${id} on ${pageRoute}`}
          {...props}
        />
      );
    case 'tve':
      return (
        <TVE
          content={packageData}
          key={id}
          {...props}
        />
      );
    case 'NativeAd':
      return (
        <NativeAd
          content={packageData}
          key={id}
          {...props}
        />
      );
    default:
      return null;
  }
}

/**
 *
 * @param {object} packages
 * @param {string} type
 * @param {string} zone
 */
export function checkForPackage(packages, type, zone = 1) {
  return packages.findIndex((pkg) => pkg.type === type && pkg.zone === zone) > -1;
}

/**
 *
 * @param {object} packageData
 * @param {object} props
 * @param {object} context
 */
function getSafePackage(packageData, props, context) {
  const {
    packageIndex,
    zone,
    zonePackageTypes,
    isRailAdjacent,
    railContext,
    isFirstLayoutWithPackages,
    pkgTitleColorIndex,
  } = context;

  // Full width gets its index in a different way due to Full Bleed splicing.
  // This should be consolidated to be consistent.
  const { packageIndex: packageIndexFromFullWidth } = packageData;

  const pkgComponent = getPackage(packageData, props, context);

  // Gets one of the second and the third packages in first layout with upto four items in each of them
  // This is for optimization of laziloading TeasePicture on initial load
  const secondAndThirdPackages = isFirstLayoutWithPackages
    && packageIndex > 0 && packageIndex <= 2 ? packageData : null;
  const topOfPagePackages = (isFirstLayoutWithPackages && packageIndex === 0)
      || secondAndThirdPackages?.items?.length <= 4;

  return (
    pkgComponent && (
      <ErrorBoundary key={`ErrorBoundary(${packageData.id})`}>
        <PackageContext
          packageIndex={packageIndexFromFullWidth || packageIndex}
          zone={zone}
          zonePackageTypes={zonePackageTypes}
          isRailAdjacent={isRailAdjacent}
          railContext={railContext}
          topOfPagePackages={topOfPagePackages}
          pkgTitleColorIndex={pkgTitleColorIndex}
        >
          {pkgComponent}
        </PackageContext>
      </ErrorBoundary>
    )
  );
}
export default getSafePackage;
