import React from 'react';
import PropTypes from 'prop-types';

import TeasePicture from 'components/TeasePicture';
import Link from 'components/Link';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import styles from './styles.module.scss';

/**
 * @typedef {object} FeaturedAuthorImage
 * @property {string} url
 * @property {string} alt
 */

/**
 * @typedef {object} LeadOneUpFeaturedAuthor
 * @property {boolean} isFeatured
 * @property {string} name
 * @property {FeaturedAuthorImage} image
 */

/**
 * Renders the Featured Author's image and name (if featured) and headline.
 * @param {object} props The React props passed to the component
 * @param {string} props.headline The headline to display
 * @param {LeadOneUpFeaturedAuthor} props.author
 * @param {string} props.url
 * @returns {JSX.Element}
 */
const FeaturedAuthorHeadline = (props) => {
  const { headline, author, url: headlineUrl } = props;
  if (!author && !headline) {
    return null;
  }

  const {
    isFeatured,
    name: authorName,
    image: { url: imageUrl, alt: imageAltText } = {},
  } = author ?? {};

  return (
    <>
      <h2 className={styles.title}>
        <Link to={headlineUrl}>
          {!!isFeatured && (
            <span className={styles.featuredAuthor}>
              {authorName}
              {' '}
            </span>
          )}
          <span className={styles.headline}>
            {headline}
          </span>
        </Link>
      </h2>
      {!!isFeatured && (
        <div className={styles.featuredAuthorImage}>
          <TeasePicture
            url={imageUrl}
            responsiveFlavors={{ s: AIMS_FLAVORS.FOCAL_380x380 }}
            alt={imageAltText}
          />
        </div>
      )}
    </>
  );
};

FeaturedAuthorHeadline.propTypes = {
  headline: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  author: PropTypes.shape({
    isFeatured: PropTypes.bool,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
};

export { FeaturedAuthorHeadline };
