import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { CvsdkTease } from 'components/CvsdkTease';
import Breakpoints from 'lib/Breakpoints';
import getNavbarHeight from 'lib/getNavbarHeight';
import sassVariables from '../../sassVariables.module.scss';


import contentMediaStyles from '../styles.module.scss';

const packageClass = sassVariables?.['package-outer-class'];


/**
 *
 * @param {object} props
 * @returns {React.ReactElement} Live video component for MSP
 */
export default function LiveVideoMedia(props) {
  const { packageMetadata } = props;
  const playmakerRef = useRef(null);
  const playmakerHomeRef = useRef(null);
  const playmakerElement = playmakerRef?.current;
  const playmakerHomeElement = playmakerHomeRef?.current;
  const [playmakerShouldBeHome, setPlaymakerShouldBeHome] = useState(true);
  const [fullscreenActive, setFullscreenActive] = useState(false);

  const navbarHeight = getNavbarHeight(Breakpoints);
  const playmakerTitle = packageMetadata?.playmakerTitle;
  const isWhitespacePlaymakerTitle = playmakerTitle && (playmakerTitle?.trim()?.length === 0);

  /*  StickyPIP and InView logic.
    The logic below for handling StickyPIP is due to the fact that StickyPIP uses
    `position: fixed` to place itself. Position fixed is limited to the containing
    block and therefore to the MSP containers used for CSS container queries. As a
    result the StickyPip pops out but is not positioned in the viewport but in the column.
      The solution:
        1. When StickyPIP goes into float mode, we move the player in the DOM
          to the top of the package and hide it with zero height, overflow hidden.
          Thus the player has both a HOME position in the dom (the rendered position
          in the flow) and a FLOATING location in the dom (at the top of the package).
        2. In the FLOATING DOM location at the top of the package, StickyPIP can
           use `position: fixed` to escape the `height:0` `overflow:hidden` parent into
           the viewport context as intended.
        3. Since StickyPiP's intersection observer is on the parent that is `height:0`
           when in FLOATING location, it will not come into view and fire, StickyPip
           cannot re-seat itself. Therefore LiveVideoMedia uses its own
           intersection observe to watch for the HOME location coming back into view.
        4. When back in view (30% or more) LiveVideoMedia moves the player back to the
           HOME position. StickyPip then receives the in view message and reseats the
           the player.

    Going fullscreen presents another issue. This can cause an intersection observer
    event that causes the element to be moved in the dom. In Safari this causes
    the fullscreen API to exit fullscreen. The same thing happens in Safari if the
    element is "moved" into its current position. Both LiveVideoMedia and StickyPip need
    to ignore "onStuckChange" events while in fullscreen.
     The solution:
      1. Ignore all onStuckChange events and our own intersection observer
         when in fullscreen mode.
      2. Adding a property that tells StickyPIP not to track OnStuck events when in fullscreen.
  */

  // This is an intersection observer for re-seating LiveVideo when it's back in
  // view.
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.3,
    rootMargin: `${-navbarHeight}px 0px 0px 0px`,
  });
  /* Watch for fullscreen
    Note: If leaving fullscreen, the player element will need to be reset.
    If it was in sticky pip mode it will have been unstuck by the this component.
  */
  const onFullscreenChange = useCallback(() => {
    const fsActive = !!document.fullscreenElement;
    setFullscreenActive(fsActive);
    if (!fsActive) {
      setPlaymakerShouldBeHome(true);
    }
  }, [setFullscreenActive]);

  useEffect(() => {
    // StickyPIP uses the standard fullscreen API so we attach to a standard event
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, [onFullscreenChange, setPlaymakerShouldBeHome]);

  /* On Stuck Change, received from CvsdkTease Stack.
    Indicates if the player should be home. However, if the
    player is going unstuck b/c we are in fullscreen
    then the player should be left in place until leaving fullscreen.
  */
  const onStuckChange = useCallback((isStuckVal) => {
    if (playmakerElement
      && !fullscreenActive
    ) {
      setPlaymakerShouldBeHome(!isStuckVal);
    }
  }, [playmakerElement, fullscreenActive, setPlaymakerShouldBeHome]);

  /* Watch our intersection observer
    1. When the home div comes in view, we reseat the player
    2. Once reseated, StickyPiP will see it's in view and unstick it.
    3. A CSS rule hides the player while it's home but not yet unstuck to
       avoid a flicker which otherwise sometimes would happen.
  */
  useEffect(() => {
    if (playmakerElement && !fullscreenActive) {
      if (inView && playmakerElement) {
        setPlaymakerShouldBeHome(true);
      } else {
        setPlaymakerShouldBeHome(false);
      }
    }
  }, [inView, playmakerElement, setPlaymakerShouldBeHome, fullscreenActive]);

  /* React to change in playmakerShouldBeHome state
    1. Move element to correct location.
    2. Add/Remove classes to mark player based on home vs float state.
  */
  useEffect(() => {
    if (!playmakerElement || fullscreenActive) return;

    // Move Player
    if (!playmakerShouldBeHome) {
      // MOVE PLAYER To Top of Package
      const containerEl = playmakerElement.closest(`.${packageClass}`);
      const targetEl = containerEl?.firstChild;
      playmakerElement.classList?.add('msp-pip-stuck');
      targetEl?.before(playmakerElement);
      // Note this top/bottom class can be left and trigger the rule by a parent dynamic class.
      if (playmakerHomeElement.getBoundingClientRect().y > 0) {
        playmakerElement.classList?.add('bottom');
        playmakerElement.classList?.remove('top');
      } else {
        playmakerElement.classList?.add('top');
        playmakerElement.classList?.remove('bottom');
      }
      // tag parent with correct class for state
      const playmakerHomeParent = playmakerHomeElement.parentElement;
      playmakerHomeParent.classList?.add('player-is-not-home');
      playmakerHomeParent.classList?.remove('player-is-home');
    } else {
      // MOVE PLAYER home
      playmakerElement.classList?.remove('msp-pip-stuck');
      playmakerElement.classList?.remove('bottom');
      playmakerElement.classList?.remove('top');

      // tag parent with correct class for state
      const playmakerHomeParent = playmakerHomeElement?.parentElement;
      playmakerHomeParent?.classList?.add('player-is-home');
      playmakerHomeParent?.classList?.remove('player-is-not-home');

      playmakerHomeElement?.before(playmakerElement);
    }
  }, [fullscreenActive, playmakerShouldBeHome, playmakerElement]);

  const CvsdkTeaseJsx = (
    <CvsdkTease
      icid="storyline-tease"
      metadata={packageMetadata}
      showDek={false}
      showTitle
      showPlayerControls
      isStoryline
      onStuckChange={onStuckChange}
      stickyEnabled
      noStuckChangeInFullscreen
    />
  );

  return (
    <div
      ref={inViewRef}
      className={classNames(contentMediaStyles.liveVideoHomeContainer, 'live-video-home')}
    >
      <div
        ref={playmakerRef}
        className={classNames(
          contentMediaStyles.liveVideoMovingContainer,
          'live-video-moving', {
            'hide-video-label': isWhitespacePlaymakerTitle,
          },
        )}
      >
        { CvsdkTeaseJsx }
      </div>
      <div className="player-home-reference" ref={playmakerHomeRef} />
    </div>
  );
}

LiveVideoMedia.propTypes = {
  packageMetadata: PropTypes.shape({}).isRequired,
};
