import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18next from 'i18next';

import ButtonHoverAnimation from 'components/ButtonHoverAnimation';

import './styles.themed.scss';

const stringCheck = (text, defaultValue) => (
  typeof text === 'string' && text.trim()
    ? text.toUpperCase()
    : defaultValue
);

const KeyUpdates = ({
  updates,
  breaking = false,
  sectionTitle = null,
  url = null,
  buttonText = null,
}) => (
  <div
    className={classNames(
      'key-updates',
      {
        'key-updates--breaking': breaking,
      },
    )}
    data-testid="key-updates__container"
  >
    <h3 className="key-updates__title">{sectionTitle || 'Key Updates'}</h3>
    <div className="key-updates--dotted-line" />
    <ul className="key-updates__list">
      {(updates || []).filter((line) => line).map(
        (item) => <li className="key-updates__list-item" key={item}>{item}</li>,
      )}
    </ul>

    {stringCheck(url, '') && (
      <ButtonHoverAnimation
        url={url}
        type="link"
        additionalClasses="key-updates__button"
        title={stringCheck(buttonText, i18next.t('SEE ALL'))}
      />
    )}
  </div>
);

KeyUpdates.propTypes = {
  updates: PropTypes.arrayOf(PropTypes.string).isRequired,
  breaking: PropTypes.bool,
  sectionTitle: PropTypes.string,
  url: PropTypes.string,
  buttonText: PropTypes.string,
};


export default KeyUpdates;
