import { produce } from 'immer';

/**
 * Set default values for Storyline package.  Updates do not mutate the original content object.
 *
 * @param {object} content
 * @param {object} content.metadata
 * @param {string} [content.metadata.layoutType]
 * @param {string} [content.metadata.mediaSizeImportant]
 * @param {string} [content.metadata.mediaSize]
 * @param {'autoplay' | 'clickToPlay'} [content.metadata.playmakerPlayBehavior]
 * @returns the updated content object
 */
export function applyPackageDefaults(content) {
  return produce(content, (draftContent) => {
    const { metadata = {} } = draftContent;

    if (metadata.layoutType === 'important') {
      if (metadata.mediaSizeImportant) {
        metadata.mediaSize = metadata.mediaSizeImportant;
      } else {
        metadata.mediaSize = 'large';
      }
    }

    if (!metadata.playmakerPlayBehavior) {
      metadata.playmakerPlayBehavior = 'autoplay';
    }
  });
}
