import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  content as ContentPropType,
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';

import { MetaContent } from '../MetaContent';
import { SmallTeaseVisual } from '../SmallTeaseVisual';
import { TeaseVisual } from '../TeaseVisual/TeaseVisual';
import {
  SMORGASBORD_TEASE_VALID_SIZES,
  TEASE_SIZES,
  SMORGASBORD_TEASE_VISUAL_VARIANTS,
  SMORGASBORD_COMPONENT_TYPES,
} from '../constants';
import { RightRailVideoLogo } from '../RightRailVideoLogo/index';

import './styles.themed.scss';

const block = 'smorgasbord-tease';
const relatedBlock = 'smorgasbord-related-item';

const {
  PLAYMAKER, VIDEO, DOT, PLAY, ARTICLE, LIVEBLOG,
} = SMORGASBORD_TEASE_VISUAL_VARIANTS;

/**
 * The M->XL sized Smorgasbord tease
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The item to render in the tease
 * @param {boolean} props.showByline Will turn on/off the byline in the tease
 * @param {boolean} props.showEyebrow Will turn on/off the eyebrow in the tease
 * @param {boolean} props.showDek Will turn on/off the dek in the tease
 * @param {string} props.size the size of the tease to render (see VALID_SIZES for list)
 * @param {JSON} props.packageMetadata the metadata from the package the tease will be rendered inside. Used to render the playmaker.
 * @returns {JSX.Element} Component
 */
export const SmorgasbordTease = ({
  item,
  size,
  componentType,
  layout,
  showByline = true,
  showEyebrow = true,
  showDek = false,
  maxRelatedItems = 0,
  packageMetadata = null,
}) => {
  if (!item) {
    return null;
  }
  const { related = [], id, metadata: { useEyebrowAsTag } = {} } = item;

  const getVariant = (type, slotType, playmakerMetadata) => {
    if (slotType === SMORGASBORD_COMPONENT_TYPES.PLAYMAKER && playmakerMetadata) {
      return PLAYMAKER;
    }
    if (type === 'video') {
      return VIDEO;
    }
    if (type === 'liveBlog') {
      return LIVEBLOG;
    }
    return ARTICLE;
  };

  const variant = getVariant(item.type, componentType, packageMetadata);
  const isRightRailVideo = layout === 'threeKeyVisuals' && item.slotIndex === 7;
  const isLiveBlog = variant === LIVEBLOG;
  const renderVisual = isLiveBlog ? size !== TEASE_SIZES.M2 : true;

  const showRelated = maxRelatedItems > 0;

  const relatedItems = related?.slice(0, maxRelatedItems);
  const relatedComponent = !isLiveBlog && showRelated && relatedItems.length ? (
    <div
      className={classNames(`${block}__related-content-wrapper`, {
        [`${block}__related-content-wrapper--${size}`]: !!size,
      })}
    >
      {relatedItems.map((relatedItem) => {
        const relatedVariant = relatedItem?.type === 'video' ? PLAY : DOT;
        const {
          metadata:
          { showByline: relatedShowByline, showEyebrow: relatedShowEyebrow }
          = {},
        } = relatedItem || {};
        const {
          showByline: packageShowByline = true,
          showEyebrow: packageShowEyebrow = true,
        } = packageMetadata;
        const shouldShowByline = relatedShowByline ?? packageShowByline;
        const shouldShowEyebrow = relatedShowEyebrow ?? packageShowEyebrow;
        return (
          <div
            key={relatedItem?.id}
            className={classNames(`${relatedBlock}__wrapper`, {
              [`${relatedBlock}__wrapper--${size}`]: !!size,
            })}
          >
            <SmallTeaseVisual variant={relatedVariant} item={relatedItem} />
            <MetaContent
              item={relatedItem}
              showByline={shouldShowByline}
              showEyebrow={shouldShowEyebrow}
              isRelatedContent
            />
          </div>
        );
      })}
    </div>
  ) : null;

  return (
    <div
      className={classNames(block, {
        [`${block}--${size}`]: !!size,
        [`${block}--tag`]: !!useEyebrowAsTag,
      })}
      data-contentid={id}
    >
      {renderVisual && (
        <div
          className={classNames(`${block}__main-content-wrapper`, {
            [`${block}__main-content-wrapper--${size}`]: !!size,
          })}
        >
          {isRightRailVideo && (
            <RightRailVideoLogo
              item={item}
              packageMetadata={packageMetadata}
            />
          )}
          <TeaseVisual
            size={size}
            item={item}
            variant={variant}
            packageMetadata={packageMetadata}
            layout={layout}
          />
        </div>
      )}
      <div className={`${block}__meta-content-wrapper`}>
        {variant !== PLAYMAKER
        && (
          <MetaContent
            item={item}
            packageMetadata={packageMetadata}
            showHeadline
            showByline={showByline}
            showDek={showDek}
            showEyebrow={showEyebrow}
            size={size}
          />
        )}
        {size !== TEASE_SIZES.M2 ? relatedComponent : null}
      </div>
      {size === TEASE_SIZES.M2 ? relatedComponent : null}
    </div>
  );
};

SmorgasbordTease.propTypes = {
  item: ContentPropType.isRequired,
  showByline: PropTypes.bool,
  showEyebrow: PropTypes.bool,
  showDek: PropTypes.bool,
  maxRelatedItems: PropTypes.number,
  size: PropTypes.oneOf(SMORGASBORD_TEASE_VALID_SIZES).isRequired,
  packageMetadata: PlaymakerMetadataPropType,
  componentType: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

