import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { package as PackagePropType } from 'lib/CustomPropTypes';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import { Unibrow } from 'components/Unibrow';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import TitleLogo from 'components/TitleLogo';
import TypeIcon from 'components/TypeIcon';
import Wrapper from 'components/packages/HeaderPackage/Wrapper';

import styles from './styles.module.scss';

const responsiveFlavors = {
  s: AIMS_FLAVORS.FOCAL_60x60,
  xl: AIMS_FLAVORS.FOCAL_80x80,
};

function MagazineLead(props) {
  const teaseWrapper = React.useRef();
  const { t } = useTranslation();

  const {
    content: {
      id,
      items,
      metadata,
    },
    pkgClassName = 'pkg magazineLead',
  } = props;

  const {
    imageUrl,
    logoUrl,
    title = t('On the show'),
  } = metadata;

  let down = false;
  let lastX = 0;
  const buffer = 10;

  const image = (item) => {
    const { type, metadata: { showTeaseImage } = {} } = item;

    if (showTeaseImage !== false) {
      return (
        <TeasePicture
          computedValues={item.computedValues}
          placeholderColor={null}
          previewRedirect={false}
          responsiveFlavors={responsiveFlavors}
          retina
          type={type}
        />
      );
    }
    return null;
  };

  const getTease = (item) => {
    const { computedValues: { headline, url, unibrow }, id: contentId } = item;

    return (
      <div
        className={styles.tease}
        key={`magazineLeadItem-${contentId}`}
        data-contentid={contentId}
      >
        <Link to={url} className={styles.thumbnail}>
          {image(item)}
          <TypeIcon
            additionalStyles={styles.thumbnailIcon}
            radius
            type={item.type}
          />
        </Link>
        <div>
          <Unibrow
            className={styles.unibrow}
            size="h3"
            unibrow={unibrow}
          />
          <Link to={url}>
            <h2 className={styles.headline}>
              {headline}
            </h2>
          </Link>
        </div>
      </div>
    );
  };

  const handleMouseMove = (e) => {
    if (down && typeof teaseWrapper.getBoundingClientRect === 'function') {
      const { left, right } = teaseWrapper.getBoundingClientRect();
      teaseWrapper.scrollLeft -= -lastX + (lastX = e.nativeEvent.clientX);
      if (lastX <= (left + buffer) || lastX >= (right - buffer)) {
        down = false;
      }
    }
    e.preventDefault();
  };

  const handleMouseDown = (e) => {
    down = true;
    lastX = e.nativeEvent.clientX;
  };

  const handleMouseUp = () => {
    down = false;
  };

  return (
    <Wrapper id={id} className={classNames(pkgClassName, styles.magazineLead)}>
      {imageUrl && (
        <div
          className={styles.background}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
      <div className={`layout-grid-container ${styles.inner}`}>
        <div className={styles.content}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>
              <TitleLogo logoUrl={logoUrl} title={title} />
            </h2>
          </div>
          <div className={styles.outerTeaseWrapper}>
            {/* eslint-disable jsx-a11y/no-static-element-interactions */}
            <div
              ref={teaseWrapper}
              className={styles.teases}
              onMouseMove={handleMouseMove}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {items
                .filter((item) => item)
                .map((item) => getTease(item))}
            </div>
            {/* eslint-enable jsx-a11y/no-static-element-interactions */}
          </div>
          <div className={styles.seeAllWrapper}>
            {metadata.seeAllUrl && (
              <ButtonHoverAnimation
                additionalClasses={styles.seeAllBtn}
                size="small"
                title={
                  metadata.seeAllText || t('See All From The Show')
                }
                type="link"
                url={metadata.seeAllUrl}
              />
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

MagazineLead.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};


export { MagazineLead };
