import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.themed.scss';

/**
 * The dek of a main story in smorgasbord component
 * @param {object} props The React props passed to the component
 * @param {string} props.dek The dek to render
 * @param {string} props.className Classname to append to the dek
 * @returns {JSX.Element} Component
 */
export const MainStoryDek = ({
  dek = null,
  className = '',
}) => {
  if (!dek) {
    return null;
  }
  return (
    <p className={classNames('smorgasbord-main-story__dek', className)}>
      {dek}
    </p>
  );
};

MainStoryDek.propTypes = {
  dek: PropTypes.string,
  className: PropTypes.string,
};

