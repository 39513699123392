/**
   * Used to access metadata so components can avoid looking at metadata itself.
   *
   * @param {Package} packageData - The package data object.
 */
export function packageMetadata(packageData) {
  const packageMetadataSource = packageData.metadata ?? {};
  const {
    pkgHeaderStyle,
    playmakerToggle,
    playmakerColumn,
    showVerticalBorderA = true,
    showVerticalBorderB = true,
    stackLeadColumnFirstOnMobile,
    column1Title,
    column1Logo,
    column2Title,
    column2Logo,
    column3Title,
    column3Logo,
    contentAsPackageHeadline,
    leadHeadlineSize = 'medium',
    showBottomBorder = true,
  } = packageMetadataSource;
  return {
    // pass on any new props not yet in list below
    ...packageMetadataSource,
    // List of explicitly known metadata flags below. Ideally the below list should be
    // complete, with all supported metadata properties listed.
    // Strictly, however, only those that need default value calculations HAVE to be
    // specified here.
    pkgHeaderStyle,
    playmakerToggle,
    playmakerColumn,
    stackLeadColumnFirstOnMobile,
    column1Title,
    column1Logo,
    column2Title,
    column2Logo,
    column3Title,
    column3Logo,
    contentAsPackageHeadline,
    // need default settings
    showVerticalBorderA: showVerticalBorderA ?? true,
    showVerticalBorderB: showVerticalBorderB ?? true,
    leadHeadlineSize: leadHeadlineSize ?? 'medium',
    showBottomBorder: showBottomBorder ?? true,
  };
}
