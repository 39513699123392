import React from 'react';
import { layoutContext as LayoutContextPropType } from 'lib/CustomPropTypes';

/**
 * Component to render the package header.
 *
 * @param {object} props - The properties for the PackageHeader component.
 * @param {string} [props.className=''] - Additional CSS class for the component.
 * @param {number|null} [props.packageIndex=null] - Index of the package.
 * @param {React.ReactNode} [props.children] - Child elements to render inside the component.
 * @param {object} context - The context object.
 */
const PackageHeader = ({
  className = '',
  packageIndex = null,
  children,
}, context = {}) => {
  const { isFirstLayoutWithPackages } = context;
  const isFirstPackage = isFirstLayoutWithPackages && packageIndex === 0;
  const Tag = !isFirstPackage ? 'h2' : 'h1';

  return (
    <Tag className={className} data-testid="package-header">
      { children }
    </Tag>
  );
};

PackageHeader.contextTypes = {
  ...LayoutContextPropType,
};

export default PackageHeader;
