import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

import { Button } from 'components/Button';

import {
  package as packagePropType,
  packageContext as packageContextTypes,
} from 'lib/CustomPropTypes';
import { isBlogLive } from 'lib/liveBlog';
import { fetchLatestCardsForPkg } from 'redux/modules/liveBlog';
import { getLiveBlogArticleId, querySettings } from 'lib/liveBlogPkgHelpers';
import { arraysAreEqual } from 'lib/arrayUtils';
import { postWebViewMessage } from 'lib/postWebViewMessage';
import { getOrderedLiveBlogCards } from 'lib/getOrderedLiveBlogCards';

import CardList from './CardList';
import './styles.themed.scss';

const {
  LIVE_BLOG_POLLING_INTERVAL = 30000,
} = process.env;

/**
 *
 * @param {object} root0
 * @param {object} root0.liveBlog
 */
const mapStateToProps = ({
  liveBlog,
}) => ({
  liveBlogPkgInstances: liveBlog.pkgInstances,
});

@connect(mapStateToProps)
class LiveBlog extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isEmbedded: PropTypes.bool,
    isWebEmbed: PropTypes.bool,
    hasPolling: PropTypes.bool,
    showLiveBadge: PropTypes.bool,
    content: packagePropType,
    itemIndex: PropTypes.number, // if the liveBlog content item is not index 0
    liveBlogPkgInstances: PropTypes.objectOf(PropTypes.shape({})),
    isMultiStoryline: PropTypes.bool,
    liveBlogStyle: PropTypes.string, // 'reportedStyle', 'chatStyle', 'storylineChatStyle'
    renderCardListByline: PropTypes.bool,
  };

  static contextTypes = {
    store: PropTypes.objectOf(PropTypes.shape({})),
    ...packageContextTypes,
  };

  static defaultProps = {
    isEmbedded: false,
    isWebEmbed: false,
    showLiveBadge: false,
    content: {},
    itemIndex: 0,
    hasPolling: true,
    liveBlogPkgInstances: {},
    isMultiStoryline: false,
    liveBlogStyle: 'reportedStyle',
    renderCardListByline: false,
  };

  /**
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    const {
      content: packageData,
      itemIndex,
    } = props;
    this.state = {
      articleId: getLiveBlogArticleId(packageData, itemIndex),
      renderedCardIds: [],
    };
  }

  /**
   *
   */
  componentDidMount() {
    // conditional statment
    const { hasPolling } = this.props;
    if (hasPolling) {
      this.startPolling();
    }
  }

  /**
   *
   * @param {object} props
   * @param {object} state
   */
  static getDerivedStateFromProps(props, state) {
    const { content: packageData, itemIndex } = props;
    const { articleId: prevArticleId } = state;
    const articleId = getLiveBlogArticleId(packageData, itemIndex);

    // Reset state if live blog article id is changed
    if (prevArticleId !== articleId) {
      return {
        articleId,
      };
    }
    return null;
  }

  /**
   * Lifecycle method that is called after the component updates.
   * It checks if the component is embedded and if the rendered card IDs have changed.
   * If the card IDs have changed, it attempts to post a message to the the WebView.
   */
  componentDidUpdate() {
    const { isEmbedded } = this.props;
    const { renderedCardIds } = this.state;
    if (isEmbedded) {
      const cardsToRender = this.getCardsToRender();
      const newCardIds = cardsToRender.map(({ id }) => id);
      const cardsHaveChanged = !arraysAreEqual(newCardIds, renderedCardIds);
      if (cardsHaveChanged) {
        this.setState({ renderedCardIds: newCardIds });
        postWebViewMessage('LIVE_BLOG_UPDATE');
      }
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    if (this.cardPollInterval) {
      clearInterval(this.cardPollInterval);
    }
  }

  /**
   *
   */
  startPolling = () => {
    // Interval cleared in componentWillUnmount
    this.queryCards();
    this.cardPollInterval = setInterval(this.queryCards, LIVE_BLOG_POLLING_INTERVAL);
  };

  /**
   *
   */
  queryCards = () => {
    const { page, queryLimit, shownOnFronts } = querySettings;
    const { articleId } = this.state;
    const { dispatch } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const fetchingLatest = this.props?.liveBlogPkgInstances?.[articleId]?.fetchingLatest ?? false;

    if (!fetchingLatest) {
      dispatch(fetchLatestCardsForPkg({
        articleId,
        queryLimit,
        page,
        shownOnFronts,
      }));
    }
  };

  /**
   *
   * @param {string} itemUrl
   */
  renderButton = (itemUrl) => {
    // eslint-disable-next-line react/destructuring-assignment
    const packageMetadata = (this.props?.content?.metadata) || {};
    const {
      seeAllText,
      seeAllUrl,
    } = packageMetadata;

    return (
      <div className="live-blog__button-wrapper">
        <Button
          size="small"
          type="link"
          url={seeAllUrl || itemUrl}
          additionalClasses="live-blog__button"
          title={seeAllText || i18next.t('See All')}
        />
      </div>
    );
  };

  /**
   * Retrieves the cards to render based on the content, item index, and live blog package instances.
   *
   * On initial render, we get cards from the Curation API.
   * We then order the cards as follows:
   * pinned cards first
   * all other cards after that
   *
   * We consider cards with autofill=false to be "pinned"
   *
   * @returns {Array} An array of cards to render.
   */
  getCardsToRender = () => {
    const { content, itemIndex, liveBlogPkgInstances } = this.props;
    const { articleId } = this.state;
    return getOrderedLiveBlogCards({
      liveBlogPkgInstances,
      content,
      itemIndex,
      articleId,
    });
  };

  /**
   * Renders the LiveBlog component.
   *
   * @returns {JSX.Element|null} The rendered LiveBlog component or null if the item type is invalid.
   */
  render() {
    const { railContext, isRailAdjacent } = this.context;
    const {
      content,
      itemIndex,
      isEmbedded,
      isWebEmbed,
      showLiveBadge,
      isMultiStoryline,
      liveBlogStyle,
      renderCardListByline,
    } = this.props;

    const item = (content?.items?.[itemIndex]) || {};
    const { id: itemId, type: itemType, metadata } = item;
    const validTypes = ['liveBlog', 'article'];
    if (!validTypes.includes(itemType)) return null;

    const cardsToRender = this.getCardsToRender();

    const packageId = content?.id;
    const packageType = content?.type;

    // Is this a root package of type "Live Blog"?
    // (ex. this is false when embedded within "Cover Spread")
    const isLiveBlogPackage = packageType === 'postList';

    /*
      Reducing the blog/cards publishedAt to find
      the most recent publishedAt date/time to determine if
      Live Updates will be displayed
    */
    const { item: { cards } } = item;
    const mostRecentPublishedAt = Array.isArray(cards) && cards.length > 0
      ? cards.reduce((latest, current) => {
        const currentPublishedAt = current?.date?.publishedAt;
        if (!latest || currentPublishedAt > latest) {
          return currentPublishedAt;
        }
        return latest;
      }, null) : null;
    // Package metadata
    const {
      title,
      description,
      hideLiveBlogBadge = false,
      hideLiveBlogHeadlineText = false,
    } = metadata || {};

    const hasHeader = title || description;
    const itemUrl = item?.computedValues?.url;
    const isLiveCurrently = isBlogLive(mostRecentPublishedAt) || (packageType === 'Storyline' && !hideLiveBlogBadge);
    const isInRail = railContext !== null;

    let liveBlogItemsToDisplay = 4;
    if (packageType === 'Storyline'
      || isMultiStoryline
      || (content.type === 'MultiStoryline3' && isEmbedded)) {
      liveBlogItemsToDisplay = Number(metadata.maxBlogItems ?? 0);
    }

    const isChatStyle = liveBlogStyle === 'chatStyle';
    const isStorylineChatStyle = liveBlogStyle === 'storylineChatStyle';

    return (
      <div
        // use package class only if root package
        className={classNames('live-blog', `${liveBlogStyle}`, {
          'pkg live-blog--standalone': isLiveBlogPackage,
          [`live-blog--${packageType}`]: !isLiveBlogPackage,
          'is-live': isLiveCurrently,
          'has-headline': !hideLiveBlogHeadlineText && !isEmbedded,
          // Modify styling based on placement in rail
          'live-blog--in-rail': isInRail,
          [`cards-to-display-${liveBlogItemsToDisplay}`]: true,
        })}
        data-test="live-blog"
        data-testid="live-blog-package"
        data-packageid={packageId}
      >
        {hasHeader && <div className="live-blog__top-decoration" />}
        <div className="live-blog__content-wrapper">
          <div className="live-blog__content">
            <CardList
              id={itemId}
              content={item}
              cards={cardsToRender}
              hideHeadlineText={hideLiveBlogHeadlineText}
              isEmbedded={isEmbedded}
              isWebEmbed={isWebEmbed}
              defaultBreakingStyle={!isLiveBlogPackage} // default is not breaking if root package
              defaultLabel={(isLiveCurrently || isChatStyle || isStorylineChatStyle) ? 'Live Updates' : ''} // default is empty if root package
              isRailAdjacent={isRailAdjacent}
              showLiveBadge={showLiveBadge}
              isStorylinePackage={packageType === 'Storyline'}
              isMultiStoryline={isMultiStoryline}
              liveBlogStyle={liveBlogStyle}
              maxCardsOverride={liveBlogItemsToDisplay}
              renderByline={renderCardListByline}
            />
          </div>
          {
            // "Cover Spread" does not support button yet
            isLiveBlogPackage && this.renderButton(itemUrl)
          }
        </div>
      </div>
    );
  }
}

export default LiveBlog;
