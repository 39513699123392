import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { RelatedContentTease } from 'components/RelatedContentTease';
import { getItemData } from '../../../utils/packageDataManager';

export function StandardItemRelatedContent({
  content,
  itemData,
  columnIndex = null,
  showLive = false,
  centerContent,
}) {
  const {
    hasRelatedContent,
    related,
    relatedsDisplay,
  } = getItemData(itemData,
    'multiStorylineItemData', { content, showLive, columnIndex });

  return (
    <div
      className={classNames(
        'storyline-related-box',
        {
          related: hasRelatedContent,
          'side-by-side': relatedsDisplay === 'sideBySide',
          'centered-content': centerContent,
        },
      )}
      data-testid="storyline-small-related"
    >
      {
        related?.map((relatedItem, i) => relatedItem && (
          <div
            className={classNames({
              'multi-related-container': hasRelatedContent,
              'multi-related-border': true,
            })}
            key={`related-${relatedItem.id}`}
          >
            <RelatedContentTease
              isMultiStoryline
              relatedItem={relatedItem}
              lastRelated={related.length - 1 === i}
            />
          </div>
        ))
      }
    </div>
  );
}

StandardItemRelatedContent.propTypes = {
  content: PackagePropType.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  itemData: PropTypes.object.isRequired,
  columnIndex: PropTypes.number,
  showLive: PropTypes.bool,
};

