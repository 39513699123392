import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import TypeIcon from 'components/TypeIcon';

import { hasTeaseImage } from 'lib/teaseUtils';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import styles from './styles.module.scss';

const BigHeadlineTease = ({ teaseItem, breaking }) => {
  const { computedValues = {} } = teaseItem;

  if (!hasTeaseImage(computedValues)) {
    return null;
  }

  const { url } = computedValues;

  const teaseResponsiveFlavors = {
    s: AIMS_FLAVORS.FOCAL_758x379,
    m: AIMS_FLAVORS.FOCAL_920x460,
    l: AIMS_FLAVORS.FOCAL_600x300,
    xl: AIMS_FLAVORS.FOCAL_920x460,
  };

  return (
    <div className={classNames(
      styles.tease,
      { [styles.breaking]: breaking },
    )}
    >
      <Link to={url} className={styles.link}>
        <TeasePicture
          responsiveFlavors={teaseResponsiveFlavors}
          type={teaseItem.type}
          computedValues={computedValues}
        />
        <TypeIcon type={teaseItem.type} className={styles.play} />
      </Link>
    </div>
  );
};

BigHeadlineTease.propTypes = {
  breaking: PropTypes.bool.isRequired,
  teaseItem: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export { BigHeadlineTease };
