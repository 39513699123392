import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { setTheme } from 'redux/modules/navbar';

import { NAVBAR_THEME } from 'lib/navbar';
import { VerticalContext } from 'lib/ContextTypes';
import BTE from 'lib/BTE';

import { Rail, RailContainer } from 'components/Rail';
import Ad from 'components/Ad';
import LeadBorderTop from 'components/LeadBorderTop';
import OneUp from 'components/packages/OneUp';
import PackageHeader from 'components/PackageHeader';
import TitleLogo from 'components/TitleLogo';
import TwoUp from 'components/packages/TwoUp';

import { Background } from './Background';
import { Navigation } from './Navigation';
import { PaidContentHeader } from './PaidContentHeader';

import styles from './styles.module.scss';

/**
 * LeadSectionFront component
 * @param {object} props - Component properties
 * @param {boolean} [props.isRailAdjacent=false] - Whether the component is rail adjacent
 * @param {import('lib/CustomPropTypes/package').PackageType} props.content - The content to display
 * @param {string} [props.pkgClassName='pkg leadSectionFront'] - Additional class name for the package
 */
function LeadSectionFront(props) {
  const vertical = useContext(VerticalContext);

  const {
    isRailAdjacent = false,
    content: {
      id,
      items,
      metadata = {},
      packageIndex,
    },
    pkgClassName = 'pkg leadSectionFront',
  } = props;

  const {
    description,
    logoUrl,
    navigation,
  } = metadata;

  // metadata to be passed to tease components
  const {
    title,
    ...filteredMetadata
  } = metadata;

  const dispatch = useDispatch();
  const triggerTop = useSelector((state) => state.layout?.triggerTop || 0);
  const navTheme = useSelector((state) => state.navbar?.theme || NAVBAR_THEME.VERTICAL);

  // Handle navigation bar theme transitions
  const handleScroll = (depth) => {
    if (depth > triggerTop && navTheme !== NAVBAR_THEME.LIGHT) {
      dispatch(setTheme(NAVBAR_THEME.LIGHT));
      return;
    }

    if (depth <= triggerTop) {
      if (vertical !== 'today' && navTheme !== NAVBAR_THEME.TRANSPARENT) {
        dispatch(setTheme(NAVBAR_THEME.TRANSPARENT));
      }
    }
  };

  // Attach scroll monitoring
  useEffect(() => {
    BTE.on('scroll', handleScroll, 'throttle');

    return () => {
      BTE.remove('scroll', handleScroll);
    };
  });

  return (
    <div
      className={classNames(
        pkgClassName,
        `pkg-index-${packageIndex + 1}`,
        styles.leadSectionFront,
      )}
      data-packageid={id}
    >
      <LeadBorderTop types={['collectionLead']} />
      <Background title={title} />
      <RailContainer className="layout-grid-container">
        <Rail className={classNames(styles.rail, 'layout-grid-item grid-col-5-l grid-col-4-xl')}>
          <div className={styles.headerContent}>
            <PaidContentHeader />
            {title && (
              <PackageHeader
                className={styles.headerTitle}
                packageIndex={packageIndex}
              >
                <TitleLogo logoUrl={logoUrl} title={title} />
              </PackageHeader>
            )}
            {description && (
              <p className={styles.headerDek}>
                {description}
              </p>
            )}
            <Navigation
              navigation={navigation}
              placement="header"
            />
          </div>
          <Navigation
            navigation={navigation}
            placement="rail"
          />
          <Ad
            adClass={classNames({ boxflexAd: vertical === 'sponsoredcontent' })}
            slot="boxrail"
            sticky
          />
        </Rail>
        <div className={classNames(styles.content, 'layout-grid-item grid-col-7-l grid-col-8-xl')}>
          <OneUp
            content={{ items: [items[0]], metadata: filteredMetadata }}
            vertical={vertical}
          />
          <TwoUp
            content={{ items: items.slice(1, 3), metadata: filteredMetadata }}
            className={styles.twoUp}
            vertical={vertical}
          />
          <OneUp
            content={{ items: [items[3]], metadata: filteredMetadata }}
            isRailAdjacent={isRailAdjacent}
            pkgClassName={pkgClassName}
            vertical={vertical}
          />
        </div>
      </RailContainer>
    </div>
  );
}

export { LeadSectionFront };
