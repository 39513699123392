import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useInView, InView } from 'react-intersection-observer';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { useBroadcastScheduleData } from 'lib/Hooks/useBroadcastScheduleData';
import { useRerender } from 'lib/Hooks/useRerender';
import { STREAM_KEYS } from 'lib/liveVideo';

import './styles.themed.scss';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const verticalStreamMap = {
  news: STREAM_KEYS.NEWS_NOW,
  today: STREAM_KEYS.TODAY_ALL_DAY,
  noticias: STREAM_KEYS.NOTICIAS_AHORA,
  telemundo: STREAM_KEYS.TELEMUNDO_AL_DIA,
};

/**
 *
 */
function ScheduleLive() {
  const {
    query: {
      vertical,
    },
  } = useRouter();
  const episodesInView = useRef({});
  const scrollContainerRef = useRef();

  const rerender = useRerender();

  const useInViewOptions = { threshold: [0, 0.01, 0.99, 1] };
  const {
    ref: firstEpisodeRef,
    entry: {
      intersectionRatio: firstEpisodeIntersectionRatio,
    } = {},
  } = useInView(useInViewOptions);
  const shouldPrevButtonShow = firstEpisodeIntersectionRatio < 1;

  const {
    ref: lastEpisodeRef,
    entry: {
      intersectionRatio: lastEpisodeIntersectionRatio,
    } = {},
  } = useInView(useInViewOptions);
  const shouldNextButtonShow = lastEpisodeIntersectionRatio < 1;

  const { scheduleData, error } = useBroadcastScheduleData(verticalStreamMap[vertical]);

  const now = dayjs();

  useEffect(() => {
    const minute = 60000;
    const intervalId = setInterval(() => {
      rerender();
    }, minute);

    return () => clearInterval(intervalId);
  }, []);

  if (!scheduleData || error) {
    return <div className="schedule-live" />;
  }

  /**
 * Returns a reference to the first or last episode based on the current index.
 *
 * @param {number} currentIndex - The current index of the episode.
 * @param {number} scheduleLength - The total number of episodes in the schedule.
 * @returns {object | null} A reference to the first or last episode, or null if neither.
 */
  function getEpisodeRef(currentIndex, scheduleLength) {
    const firstEpisode = currentIndex === 0;
    if (firstEpisode) {
      return firstEpisodeRef;
    }

    const maxIndex = (scheduleLength - 1);
    const lastEpisode = currentIndex === maxIndex;
    if (lastEpisode) {
      return lastEpisodeRef;
    }

    return null;
  }

  /**
 * Scrolls the schedule view to the next or previous episode marker.
 *
 * @param {boolean} forward - If true, scrolls to the next episode marker. If false, scrolls to the previous episode marker.
 */
  function onClickScrollIntoView(forward) {
    const currentInViewEpisodes = episodesInView.current;
    const episodeIndexes = Object.keys(currentInViewEpisodes).map((i) => parseInt(i, 10));
    const currentInViewEpisodeIndexes = episodeIndexes
      .filter((key) => currentInViewEpisodes[key] === true);
    const scrollIntoViewOptions = {
      block: 'nearest',
      behavior: 'smooth',
    };

    if (!forward) {
      const newLeftRootIndex = Math.min(currentInViewEpisodeIndexes[0] + 1, episodeIndexes.pop());
      const prevTarget = document.getElementById(`schedule-live__marker-prev--${newLeftRootIndex}`);

      scrollIntoViewOptions.inline = 'end';

      return prevTarget.scrollIntoView(scrollIntoViewOptions);
    }

    const newRightRootIndex = currentInViewEpisodeIndexes.pop() - 1;
    const nextTarget = document.getElementById(
      `schedule-live__marker-next--${newRightRootIndex}`,
    );

    scrollIntoViewOptions.inline = 'start';

    return nextTarget.scrollIntoView(scrollIntoViewOptions);
  }

  const availableEpisodes = scheduleData.filter((episode) => {
    const {
      endDateTime,
    } = episode;

    const shouldExcludeEpisode = now.isSameOrAfter(endDateTime);
    if (shouldExcludeEpisode) {
      return false;
    }

    return true;
  });

  return (
    <div className="schedule-live">
      {shouldPrevButtonShow && (
        <button
          className="schedule-live__btn schedule-live__btn--prev"
          onClick={() => onClickScrollIntoView(false)}
          type="button"
        >
          <svg
            width="14"
            height="23"
            viewBox="0 0 14 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13 22.0002L2.3934 11.6026L13 1.20505" stroke="white" strokeWidth="2" />
          </svg>
          <div className="schedule-live__btn-line schedule-live__btn-line--prev" />
        </button>
      )}

      <div
        className="schedule-live__scroll-container"
        ref={scrollContainerRef}
      >
        {availableEpisodes.map((episode, i, arr) => {
          const {
            title,
            startDateTime,
            endDateTime,
          } = episode;

          const parsedStartDateTime = dayjs(startDateTime);
          const parsedEndDateTime = dayjs(endDateTime);

          const displayTime = parsedStartDateTime.format('h:mm A');

          const shouldShowBlinkingRedDot = now.isBetween(
            parsedStartDateTime,
            parsedEndDateTime,
            null,
            '[)', // specifies inclusive of the start time, exclusive of the end time
          );
          const totalDuration = parsedEndDateTime.diff(parsedStartDateTime);
          const completedDuration = now.diff(parsedStartDateTime);
          const completionPercent = (completedDuration / totalDuration) * 100;

          return (
            <InView
              id={`schedule-live__episode--${i}`}
              className="schedule-live__episode"
              onChange={(inView) => { episodesInView.current[i] = inView; }}
              key={`${title}-${startDateTime}-${endDateTime}`}
            >
              <div ref={getEpisodeRef(i, arr.length)}>
                {shouldShowBlinkingRedDot && (
                  <div
                    className="schedule-live__red-line"
                    style={{
                      left: `${completionPercent}%`,
                    }}
                  />
                )}
                <div className="schedule-live__time">{displayTime}</div>
                <div className="schedule-live__title">
                  {shouldShowBlinkingRedDot && (
                    <span className="schedule-live__red-dot" />
                  )}
                  {title}
                </div>

                <div
                  id={`schedule-live__marker-prev--${i}`}
                  className="schedule-live__marker schedule-live__marker-prev"
                />
                <div
                  id={`schedule-live__marker-next--${i}`}
                  className="schedule-live__marker schedule-live__marker-next"
                />
              </div>
            </InView>
          );
        })}
      </div>

      {shouldNextButtonShow && (
        <button
          className="schedule-live__btn schedule-live__btn--next"
          onClick={() => onClickScrollIntoView(true)}
          type="button"
        >
          <div className="schedule-live__btn-line schedule-live__btn-line--next" />
          <svg
            width="14"
            height="23"
            viewBox="0 0 14 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 0.999817L11.6066 11.3974L1 21.795" stroke="white" strokeWidth="2" />
          </svg>
        </button>
      )}
    </div>
  );
}

ScheduleLive.propTypes = {};

export { ScheduleLive };
