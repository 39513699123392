import React from 'react';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link';

import styles from './styles.module.scss';

const PackageTitle = ({
  text = null,
  url = null,
  icid = null,
}) => {
  if (!text) return null;

  return (
    <h2
      className={styles.title}
      data-testid="live-video-promo__package-title"
    >
      <LinkIfHref href={url} icid={icid}>
        {text}
      </LinkIfHref>
    </h2>
  );
};

PackageTitle.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  icid: PropTypes.string,
};


export { PackageTitle };
