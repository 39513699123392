import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { package as packagePropType } from 'lib/CustomPropTypes';
import Breakpoints from 'lib/Breakpoints';
import { getItemMetadataValue } from 'lib/packageHelper';
import { getPlaymakerMetadata } from 'lib/liveVideo';

import PackageTitle from 'components/PackageTitle';
import { CvsdkTease } from 'components/CvsdkTease';
import SlideshowTeasePicture from 'components/SlideshowTeasePicture';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';

import { getUpImageSize } from './imageConfig';

import styles from './styles.module.scss';

const COUNT_MAP = {
  1: 'one',
  2: 'two',
  3: 'three',
};

/**
 * DynamicUp component renders a section with a dynamic number of tease cards based on the provided package.
 * It conditionally includes a playmaker tease card if the playmaker configuration allows it.
 *
 * @param {object} props - The component props
 * @param {Package} props.package - The package data containing items and metadata
 * @param {string} [props.pkgClassName='pkg'] - Additional class name for the package
 * @param {boolean} [props.railAdjacent=false] - Flag indicating if the component is adjacent to a rail
 * @param {VerticalType} [props.vertical=null] - The vertical type for the package
 */
function DynamicUp(props) {
  const {
    package: pkg,
    pkgClassName = 'pkg',
    railAdjacent = false,
    vertical = null,
  } = props;
  if (!pkg || !(pkg.items?.[0])) {
    return null;
  }

  const {
    id,
    items,
    metadata: packageMetadata = {},
  } = pkg;

  // playmakerToggle on by default
  if (typeof packageMetadata.playmakerToggle === 'undefined') {
    packageMetadata.playmakerToggle = true;
  }

  // get playmaker configuration
  const playmakerConfig = getPlaymakerMetadata(packageMetadata, vertical);

  const [isMobile, setIsMobile] = React.useState(true);

  // useEffect to toggle display of playmaker tease on non-mobile
  // 'id' prop should not change, safe to rely on this running once
  React.useEffect(() => {
    if (!Breakpoints.isS()) {
      setIsMobile(false);
    }
  }, [id]);

  const filteredItems = items.filter((item) => !!item && item.id);
  let itemCount = filteredItems.length;

  let hasPlaymaker = false;
  if (!isMobile && playmakerConfig.playmakerToggle) {
    // Toggle playmaker
    hasPlaymaker = true;
    // Increment count
    itemCount += 1;
  }

  // Get configured metadata value
  const getItemMetadata = getItemMetadataValue(packageMetadata);

  return (
    <section
      className={classNames(
        styles.dynamicUp,
        pkgClassName,
        {
          [styles[`${COUNT_MAP[itemCount]}UpFull`]]: !railAdjacent,
          [styles[`${COUNT_MAP[itemCount]}UpRailAdj`]]: railAdjacent,
        },
      )}
      data-packageid={id}
      data-test="dynamic-up__section"
      data-testid="dynamic-up__section"
    >
      <PackageTitle metadata={packageMetadata} />
      <div className={styles.teaseCards}>
        {hasPlaymaker && (
          <article className="tease-card">
            <CvsdkTease
              icid="tease-dynamicup"
              metadata={playmakerConfig}
            />
          </article>
        )}
        {filteredItems.map((item) => {
          const { type } = item;
          const teaseSizes = getUpImageSize({
            count: itemCount,
            railAdj: railAdjacent,
            type,
            videoAdj: hasPlaymaker,
          });
          return (
            <TeaseCard
              content={item}
              isRailAdjacent={railAdjacent}
              key={item.id}
              packageId={id}
              showAuthor={getItemMetadata('showAuthor', item.metadata)}
              showDek={getItemMetadata('showDek', item.metadata)}
              showFeaturedAuthor={getItemMetadata('showFeaturedAuthor', item.metadata)}
              showTeaseImage={getItemMetadata('showTeaseImage', item.metadata)}
              showTimestamp={getItemMetadata('showTimestamp', item.metadata)}
              vertical={vertical}
            >
              {type === 'slideshow' ? (
                <SlideshowTeasePicture
                  sizes={teaseSizes}
                  slides={item.item.slides}
                />
              ) : (
                <TeasePicture
                  computedValues={item.computedValues}
                  responsiveFlavors={teaseSizes}
                  type={item.type}
                />
              )}
            </TeaseCard>
          );
        })}
      </div>
    </section>
  );
}

DynamicUp.propTypes = {
  package: packagePropType.isRequired,
  pkgClassName: PropTypes.string,
  railAdjacent: PropTypes.bool,
  vertical: PropTypes.string,
};


export { DynamicUp };
