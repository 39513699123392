import React, { useEffect, useState, useId } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCopyToClipboard, useTracking } from 'lib/Hooks';
import { package as packagePropType } from 'lib/CustomPropTypes';
import { useCookies } from 'react-cookie';
import { AUTHENTICATED } from 'lib/myNewsConstants';
import { useBedrockRegistration } from 'store';
import { getAuthenticationState, isBedrockApiEnabled } from 'lib/authenticationUtils';
import { TeaseRow } from 'components/packages/TeaseRow';
import { SignInPrompt } from './SignInPrompt';
import ShareButton from './ShareButton';
import { Timer } from './Timer';
import {
  getSelectedRange, generateGrid, getWords, calculateTextSize,
} from './gridUtils';
import { GridLoadingAnimation, WordsLoadingAnimation } from './LoadingAnimation';
import styles from './styles.module.scss';

/**
  * @summary Render word
  * @description Renders a word in the wordsearch grid
  * @param {object} props
  * @param {string} props.word - word to render
  * @param {boolean} props.unchosen - word is not in the wordsearch grid
  * @param {boolean} props.matched - word has been found in the wordsearch grid
  * @returns {React.Element} The rendered word element
*/
export const Word = ({ word, unchosen, matched }) => (
  <>
    {unchosen
      ? (
        <span
          className={styles.fillerWords}
        >
          {word}
        </span>
      )
      : (
        <span
          className={styles.word}
          style={{ width: calculateTextSize(word) }}
        >
          {matched && word}
        </span>
      )}
    {' '}
  </>
);

Word.propTypes = {
  word: PropTypes.string,
  unchosen: PropTypes.bool,
  matched: PropTypes.bool,
};

/**
 * @summary Package that displays a wordsearch game based on curator article content
 * @description Package works by taking in data entered in from curator
 * and displaying 4-6 random words from the article title within the wordsearch grid.
 * The user can find a word by clicking the first and last letter of a word. When
 * the user finds all words in the grid, they see a win message and can view the article.
 * @param {object} props
 * @param {object} props.content  Package data from curator to display items
 * @returns {React.Element} Component
 */
const WordSearchGame = ({ content }) => {
  const track = useTracking('word_search');
  const authenticationState = getAuthenticationState();
  const {
    items,
    context: { pageRoute },
    id, metadata: { title, description },
  } = content;

  const uniqueId = useId();

  const articleTitle = items[0]?.computedValues?.headline;

  /**
   * @summary Gets the words from the article title
   * @description This function gets the words from the article title and returns an error message
   * if the words are not found.
   * @returns {Array} The words from the article title
   */
  const getWordsError = () => {
    const words = getWords(articleTitle);
    if (words.length === 0) {
      return (
        <div>
          <p className={styles.description}>Sorry something went wrong, please try again later.</p>
        </div>
      );
    }
    return null;
  };

  const [words] = useState(() => getWords(articleTitle).sort((a, b) => a.length - b.length));
  const [isLoading, setLoading] = useState(true);
  const [grid, setGrid] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [matchedWords, setMatchedWords] = useState([]);
  const [matchedCells, setMatchedCells] = useState([]);
  const [incorrectCells, setIncorrectCells] = useState([]);
  const [horizontalWords, setHorizonalWords] = useState([]);
  const [verticalWords, setVerticalWords] = useState([]);
  const [firstSelectedHorizontalLetter, setFirstSelectedHorizontalLetter] = useState([]);
  const [lastSelectedHorizontalLetter, setLastSelectedHorizontalLetter] = useState([]);
  const [firstSelectedVerticalLetter, setFirstSelectedVerticalLetter] = useState([]);
  const [lastSelectedVerticalLetter, setLastSelectedVerticalLetter] = useState([]);
  const [overlappingLetters, setOverlappingLetters] = useState([]);
  const [trackStartCalled, setTrackStartCalled] = useState(false);
  const [trackEndCalled, setTrackEndCalled] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [cookies] = useCookies(['wordsearchtime']);
  const allWordsFound = words.every((word) => matchedWords.includes(word));
  const filteredTitle = articleTitle?.replace(/([.,!?'()-])/g, ' $1 ');
  const unchosenArticleWords = filteredTitle?.split(' ').filter((word) => !words.includes(word.toUpperCase()));
  const blanksAndWords = [];
  filteredTitle?.split(' ').map((word) => blanksAndWords.push(
    {
      word,
      matched: matchedWords.includes(word.toUpperCase()),
      unchosen: unchosenArticleWords.includes(word),
    },
  ));

  if (allWordsFound && trackStartCalled && !trackEndCalled) {
    setTrackEndCalled(true);
    track({ action: 'end' });
  }

  const [url, setUrl] = useState('');
  const [isCopied, handleCopyToClipboard] = useCopyToClipboard(1500);

  useEffect(() => {
    setGrid(generateGrid(10, 8, words));
    setMatchedWords([]);
    setMatchedCells([]);
    setSelectedLetters([]);
    setFirstSelectedHorizontalLetter([]);
    setLastSelectedHorizontalLetter([]);
    setFirstSelectedVerticalLetter([]);
    setLastSelectedVerticalLetter([]);
    setHorizonalWords([]);
    setVerticalWords([]);
    setOverlappingLetters([]);
    setLoading(false);
    setUrl(document.location.host + pageRoute);
  }, []);

  /**
   * @summary Checks if the selected range is horizontal
   * @param {number} startRow - The starting row index
   * @param {number} endRow - The ending row index
   * @returns {boolean} True if the range is horizontal, otherwise false
   */
  const isHorizontal = (startRow, endRow) => startRow === endRow;

  /**
   * @summary Checks if the selected range is vertical
   * @param {number} startCol - The starting column index
   * @param {number} endCol - The ending column index
   * @returns {boolean} True if the range is vertical, otherwise false
   */
  const isVertical = (startCol, endCol) => startCol === endCol;

  /**
   * @summary Checks if a cell is in the selection
   * @param {number} row - The row index of the cell
   * @param {number} col - The column index of the cell
   * @param {Array} cells - The array of selected cells
   * @returns {boolean} True if the cell is in the selection, otherwise false
   */
  const isInSelection = (row, col, cells) => cells?.some(
    (selection) => selection && selection.row === row && selection.col === col,
  );

  /**
   * @summary Checks if a letter is overlapping with matched cells
   * @description This function filters the selected range to find cells that overlap with matched cells.
   * @param {Array} selectedRange - The range of selected cells
   * @returns {Array} The overlapping cells
   */
  const isOverlappingLetter = (selectedRange) => selectedRange?.filter((cell) => matchedCells
    && matchedCells.some((matchedCell) => matchedCell.row === cell.row
      && matchedCell.col === cell.col));

  /**
   * @summary Checks if a word is valid and updates the state accordingly
   * @description This function checks if the selected word is in the list of words to find,
   * updates the matched words and cells, and handles overlapping letters.
   * @param {string} word - The word to check
   * @param {Array} range - The range of cells that form the word
   */
  const checkWord = (word, range) => {
    const firstElement = range[0];
    const lastElement = range[range.length - 1];
    const overlappedLetters = isOverlappingLetter(range);

    if (word && words.includes(word) && !matchedWords.includes(word)) {
      setMatchedWords((prevWords) => [...prevWords, word]);
      setMatchedCells((prevCells) => [...prevCells, ...range]);

      if (overlappedLetters.length) {
        setOverlappingLetters((prevCells) => [...prevCells, ...overlappedLetters]);
      }

      if (isHorizontal(firstElement.row, lastElement.row)) {
        setHorizonalWords((prevCells) => [...prevCells, firstElement, lastElement]);
        setFirstSelectedHorizontalLetter((prevCells) => [...prevCells, firstElement]);
        setLastSelectedHorizontalLetter((prevCells) => [...prevCells, lastElement]);
      } else if (isVertical(firstElement.col, lastElement.col)) {
        setVerticalWords((prevCells) => [...prevCells, firstElement, lastElement]);
        setFirstSelectedVerticalLetter((prevCells) => [...prevCells, firstElement]);
        setLastSelectedVerticalLetter((prevCells) => [...prevCells, lastElement]);
      }
    } else {
      setIncorrectCells((prevCells) => [...prevCells, firstElement, lastElement]);
      // We only want to animate incorrect cells because they could be correct for future selections
      setTimeout(() => {
        setIncorrectCells([]);
      }, 1000);
    }
  };

  /**
   * @summary Handles the click event on a grid cell
   * @description Tracks the start of the game, starts the timer, and processes the selected letters
   * @param {number} row - The row index of the clicked cell
   * @param {number} col - The column index of the clicked cell
   */
  const handleClick = (row, col) => {
    if (!trackStartCalled) {
      track({ action: 'start' });
      setTrackStartCalled(true);
    }
    setIsTimerRunning(true);
    const letter = grid[row][col];
    const selectedLetter = letter.props ? letter.props.children : letter;
    if (selectedLetters.length === 0) {
      setSelectedLetters([{ row, col, letter: selectedLetter }]);
    } else if (selectedLetters.length === 1) {
      const firstLetter = selectedLetters[0];
      const selectedRange = getSelectedRange(firstLetter, { row, col });
      const word = selectedRange.map((cell) => {
        const letterInRange = grid[cell.row][cell.col];
        return letterInRange.props ? letterInRange.props.children : letterInRange;
      }).join('');
      checkWord(word, selectedRange);
      setSelectedLetters([]);
    }
  };

  /**
   * @summary Handles the share functionality
   * @description Shares the game URL and fastest time if available, or copies the URL to clipboard
   */
  const handleShare = () => {
    const savedTime = cookies.wordsearchtime;
    let shareText;
    if (savedTime) {
      const isRecordLessthanMin = savedTime.minutes === 0;
      const stringMin = savedTime.minutes < 2 ? 'min' : 'mins';
      const minutes = !isRecordLessthanMin ? `${savedTime.minutes.toString()} ${stringMin} ` : '';
      const seconds = `${savedTime.seconds.toString()} sec?`;
      shareText = `⏱️ Can you beat my fastest time of ${minutes}${seconds} Play TODAY's Find!`;
    } else shareText = 'Can you solve TODAY’s Find?';
    if (navigator.share) {
      navigator.share({
        url: pageRoute,
        text: shareText,
        // eslint-disable-next-line no-console
      }).catch(console.error);
    } else {
      handleCopyToClipboard(url);
    }
  };

  const isAuthenticated = authenticationState === AUTHENTICATED;
  const showBedrockGate = useBedrockRegistration((state) => state.showGate);
  const isNewLoginFlow = isBedrockApiEnabled();
  const showSignInPrompt = isNewLoginFlow ? (!isAuthenticated || showBedrockGate) : !isAuthenticated;

  const wordsError = getWordsError();

  return (
    <div
      className={classNames('pkg wordSearchGame', styles.container)}
      role="grid"
      data-packageid={id}
      data-activity-map="wordSearchGame-package"
    >
      <div className={styles.game}>
        <div className={classNames(styles.info, 'layout-grid-item', 'grid-col-4-m')}>
          <h1 className={styles.title}>{title}</h1>
          {allWordsFound && !wordsError ? (
            <div data-testid="winMessage">
              <div className={styles.winMessage}>
                <div className={styles.winTitle}>You did it!</div>
                <span className={styles.winSubtitle}>
                  Come back tomorrow to discover the next headline.
                </span>
              </div>
              <div className={styles.teaseCard}>
                <h3 className={styles.dek}>Read the article here:</h3>
                <TeaseRow content={content} isWordSearchGame />
              </div>
            </div>
          ) : (
            <>
              {wordsError || (
                <>
                  <p className={styles.description}>
                    {description}
                  </p>
                  {isLoading ? <WordsLoadingAnimation /> : (
                    <div className={styles.words}>
                      <span>
                        {
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          blanksAndWords.map((word) => (<Word key={`${uniqueId}-${word}`} {...word} />))
                        }
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {!wordsError && (
            <>
              <Timer isRunning={isTimerRunning} shouldStop={allWordsFound} />
              <ShareButton handleShare={handleShare} isCopied={isCopied} />
            </>
          )}
        </div>
        {!wordsError && (
          <div
            className={classNames(
              styles.grid,
              'layout-grid-item layout-grid-item grid-col-4-m',
              {
                [styles.allWordsFound]: allWordsFound,
              },
            )}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {showSignInPrompt
              ? <SignInPrompt /> : (
                isLoading ? <GridLoadingAnimation />
                  : (
                    <>
                      {grid.map((row, rowIndex) => {
                        const uniqRowId = `${uniqueId}-row-${rowIndex}`;
                        return (
                          <div className={styles.row} role="row" key={uniqRowId}>
                            {row.map((cell, colIndex) => {
                              const uniqCellId = `${uniqueId}-cel-${colIndex}`;
                              return (
                                <button
                                  key={uniqCellId}
                                  type="button"
                                  role="gridcell"
                                  onClick={() => handleClick(rowIndex, colIndex)}
                                  className={classNames(
                                    styles.cell,
                                    {
                                      [styles.selectedCell]:
                                        isInSelection(rowIndex, colIndex, selectedLetters),
                                      [styles.incorrectCell]:
                                        isInSelection(rowIndex, colIndex, incorrectCells),
                                      [styles.matchedCell]:
                                        isInSelection(rowIndex, colIndex, matchedCells),
                                      [styles.overlappingCell]:
                                        isInSelection(rowIndex, colIndex, overlappingLetters),
                                      [styles.horizontalFirst]:
                                        isInSelection(rowIndex, colIndex, firstSelectedHorizontalLetter)
                                        && isInSelection(rowIndex, colIndex, horizontalWords),
                                      [styles.horizontalLast]:
                                        isInSelection(rowIndex, colIndex, lastSelectedHorizontalLetter)
                                        && isInSelection(rowIndex, colIndex, horizontalWords),
                                      [styles.verticalFirst]:
                                        isInSelection(rowIndex, colIndex, firstSelectedVerticalLetter)
                                        && isInSelection(rowIndex, colIndex, verticalWords),
                                      [styles.verticalLast]:
                                        isInSelection(rowIndex, colIndex, lastSelectedVerticalLetter)
                                        && isInSelection(rowIndex, colIndex, verticalWords),
                                    },
                                  )}
                                  tabIndex={0}
                                >
                                  {cell}
                                </button>
                              );
                            })}
                          </div>
                        );
                      })}
                    </>
                  )
              )}
            <Timer isRunning={isTimerRunning} shouldStop={allWordsFound} />
            <ShareButton handleShare={handleShare} isCopied={isCopied} />
          </div>
        )}
      </div>
    </div>
  );
};


WordSearchGame.propTypes = {
  content: packagePropType.isRequired,
};

export { WordSearchGame };
