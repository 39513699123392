import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';

const Title = ({
  isRailAdjacent = false,
  title = null,
  titleUrl = null,
  titleFontSize = null,
}) => {
  if (!title) {
    return null;
  }
  return (
    <h2
      className={classNames(
        'cover-spread__headline',
        {
          'cover-spread__headline--sm': !titleFontSize || titleFontSize === 'small',
          'cover-spread__headline--md': titleFontSize === 'medium',
          'cover-spread__headline--lg': titleFontSize === 'large',
          'cover-spread__rail': isRailAdjacent,
        },
      )}
      data-testid="cover-spread__headline"
    >
      {titleUrl ? (
        <Link href={titleUrl}>
          {title}
        </Link>
      ) : title}
    </h2>
  );
};

Title.propTypes = {
  isRailAdjacent: PropTypes.bool,
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  titleFontSize: PropTypes.string,
};


export { Title };
