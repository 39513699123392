import React from 'react';
import SignIn from 'components/TVE/SignIn';
import { block } from './helper';

function CTAAnonymousSecondary() {
  return (
    <div className={`${block}__anonymous-secondary`} data-testid="call-to-action-anonymous-secondary">
      <SignIn />
    </div>
  );
}

CTAAnonymousSecondary.propTypes = {};

export default CTAAnonymousSecondary;
