/**
   * Retrieves the first item of a specified type from the package data.
   *
   * @param {Package} packageData - The package data object.
   * @param {AccessorOptions} [opts={}] - Options for the accessor.
   */
export function firstItemOfType(packageData, opts = {}) {
  // need opts for `itemType`
  const { fields: { itemType } = {} } = opts;
  if (!itemType) return null;

  const { items } = packageData;
  if (!items) return null;

  const foundItem = items.find((item) => item.type === itemType);
  return foundItem;
}
