import PropTypes from 'prop-types';
import React from 'react';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { CvsdkTease } from 'components/CvsdkTease';

/**
 * FeaturedCvsdk component renders a featured Cvsdk tease.
 *
 * @param {object} props - The component props.
 * @param {string} props.block - The block class name.
 * @param {ContentModule} props.content - The content object.
 * @param {string} props.content.id - The content ID.
 * @param {JSON} props.content.metadata - The metadata for the content.
 */
function FeaturedCvsdk(props) {
  const {
    block,
    content: {
      id,
      metadata,
    },
  } = props;

  return (
    <div
      className={`${block}__cvsdk`}
      data-contentid={id}
    >
      <div className={`${block}__player`}>
        <CvsdkTease
          icid="tease-coverspread"
          metadata={metadata}
          showDek={false}
          showPlayerControls
          stickyEnabled
          styleOverride={{
            headline: 'cover-spread-featured__headline',
          }}
        />
      </div>
    </div>
  );
}

FeaturedCvsdk.propTypes = {
  block: PropTypes.string,
  content: ContentPropType.isRequired,
};


export { FeaturedCvsdk };
