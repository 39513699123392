import React from 'react';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link';
import RawImage from 'components/RawImage';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getCloudinaryImageSrc, isCloudinaryImage } from 'lib/imageUtils';

import pkgConfig from 'components/packages/CollectionLead/definition.json';

import './styles.themed.scss';


// Find first matching field by name from array of fields
function findField(fieldArray, fieldName) {
  return Array.isArray(fieldArray) && fieldArray.find((field) => field.field === fieldName);
}

// Get sponsor field from package configuration
const sponsorField = findField(pkgConfig.packageFields, 'sponsor') || { items: { metadata: [] } };

// Set default sponsor label value from field configuration
const {
  defaultValue: sponsorLabelDefault = null,
} = findField(sponsorField.items.metadata, 'label') || {};


const PackageSponsor = ({
  metadata = {
    sponsor: null,
  },
}) => {
  const {
    sponsor,
  } = metadata;

  if (Array.isArray(sponsor) && sponsor.length === 1 && sponsor[0].logoImageUrl) {
    const [{ logoImageUrl, linkUrl, label = sponsorLabelDefault }] = sponsor;

    // Resize image if possible
    let imageSrc = logoImageUrl;
    if (isCloudinaryImage(imageSrc)) {
      imageSrc = getCloudinaryImageSrc(imageSrc, AIMS_FLAVORS.FIT_360w);
    }

    return (
      <div className="pkg-sponsor">
        {(typeof label === 'string' && label.length > 0) && (
          <p className="pkg-sponsor__label">
            {label}
          </p>
        )}
        <LinkIfHref className="pkg-sponsor__link" href={linkUrl}>
          <RawImage
            alt={null}
            className="pkg-sponsor__logo"
            src={imageSrc}
          />
        </LinkIfHref>
      </div>
    );
  }
  return null;
};

PackageSponsor.propTypes = {
  metadata: PropTypes.shape({
    sponsor: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          linkUrl: PropTypes.string,
          logoImageUrl: PropTypes.string,
        }),
      ),
    ]),
  }),
};


export { PackageSponsor };
