import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import { PaidContent } from 'components/PaidContent';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import TypeIcon from 'components/TypeIcon';
import { Unibrow } from 'components/Unibrow';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { useShouldShowSource } from 'lib/Hooks/useShouldShowSource';
import { shouldTargetBeBlankHelper } from 'lib/shouldTargetBeBlankHelper';
import {
  packageContext as packageContextTypes,
} from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { getTeaseUrl } from 'lib/teaseUtils';
import { getVerticalName } from 'lib/verticalType';
import { imageAltForItem } from 'lib/imageAlt';
import { TEASE_LIST_ROUND_IMAGES } from 'lib/brandFeatures';
import { FeatureFlagContext, VerticalContext } from 'lib/ContextTypes';
import { isPaidContentType } from 'lib/paidContentUtils';

import styles from './styles.module.scss';

/**
 * Item component renders an individual item.
 *
 * @param {object} props - The properties object.
 * @param {import('lib/CustomPropTypes/content').Content} props.item - The item data.
 * @param {boolean} [props.inRail=false] - Flag to indicate if the item is in a rail.
 * @param {boolean} [props.isFeatured=false] - Flag to indicate if the item is featured.
 * @param {object} context - The context object.
 */
function Item({
  item,
  inRail = false,
  isFeatured = false,
}, context) {
  const {
    'show-nbc-sports-front': sportsFrontFF,
  } = useContext(FeatureFlagContext);
  const vertical = useContext(VerticalContext);
  const { railContext = '' } = context;

  const [adLoaded, setAdLoaded] = useState(false);

  const {
    computedValues,
    id,
    metadata,
    type,
    item: innerItem,
  } = item;

  const sourceName = innerItem?.source?.name || innerItem?.source?.organization?.name;
  const shouldOpenLinkInNewTab = sportsFrontFF ? shouldTargetBeBlankHelper(sourceName, innerItem?.subType) : false;

  const {
    headline,
    url,
    unibrow,
  } = computedValues || {};

  /**
   * sourceToDisplay determines whether to display NBC Sports, or if not, default to NBC News.
   * @param {string} sourceName
   * @returns {string} based off of sourceName value
   */
  const sourceToDisplay = (itemSourceName) => {
    const isSourceSports = itemSourceName === 'NBC Sports';
    const isSourceNews = itemSourceName === 'NBC News';
    const sourceDisplay = isSourceSports || isSourceNews;

    return sourceDisplay;
  };

  const publisher = computedValues?.teaseImage?.publisher?.name === 'nbcnews' ? 'NBC News' : computedValues?.teaseImage?.publisher?.name;
  const displaySource = sourceToDisplay(sourceName);
  const shouldShowSourceName = useShouldShowSource(sourceName);

  // this is to check whether or not the native ad
  // should be a nativetopvideolist
  const isItemInMSNBCRightRail = vertical === 'msnbc' && railContext === 'right';

  if (isPaidContentType(type)) {
    return (
      <PaidContent
        adLoaded={() => { setAdLoaded(true); }}
        className={classNames({
          [styles.placeholder]: !adLoaded,
          [styles.item]: adLoaded,
          [styles.inRail]: adLoaded,
        })}
        key={type}
        type="alacarte"
        vertical={vertical}
        isMSNBCRightRail={isItemInMSNBCRightRail}
      />
    );
  }

  const isInRail = railContext !== null;
  const isOpinion = getVerticalName(item) === 'think';
  const isSquareImage = !isFeatured;
  const isRoundedImage = isInRail
    && !isFeatured
    && getFeatureConfigForBrand(
      TEASE_LIST_ROUND_IMAGES,
      vertical,
    );

  const imageUrl = getTeaseUrl(item, isSquareImage);
  const shouldDisplayImage = !!imageUrl && metadata.showTeaseImage !== false;
  const imageAlt = imageAltForItem(item);

  let imageSizes = { s: AIMS_FLAVORS.FOCAL_80x80 };

  if (isInRail && isFeatured) {
    imageSizes = {
      s: AIMS_FLAVORS.FOCAL_720x360,
      m: AIMS_FLAVORS.FOCAL_200x100,
      l: AIMS_FLAVORS.FOCAL_280x140,
      xl: AIMS_FLAVORS.FOCAL_260x130,
    };
  }

  const isSportsVideoWorkflow = item?.item?.taxonomy?.additionalTerms?.filter(({ termName }) => (
    termName?.display === 'Workflow: NBC Sports Video'
  ))?.length > 0;

  const isSportsVideo = sportsFrontFF && isSportsVideoWorkflow ? 'video' : type;

  return (
    <li
      className={classNames(
        styles.item,
        {
          [styles.inRail]: inRail,
          [styles.isFeatured]: isFeatured,
          [styles.isOpinion]: isOpinion,
        },
      )}
      data-contentid={id}
      key={id}
    >
      {shouldDisplayImage && (
        <div className={styles.imageWrapper}>
          <Link target={shouldOpenLinkInNewTab ? '_blank' : ''} to={url}>
            <TypeIcon className={styles.typeIcon} type={type} />
            <TeasePicture
              alt={imageAlt}
              className={classNames(styles.image, { [styles.radius]: isRoundedImage })}
              preview={type === 'video' && { url, redirect: false, radius: isRoundedImage }}
              responsiveFlavors={imageSizes}
              retina
              url={imageUrl}
              computedValues={computedValues}
              isSportsVideo={isSportsVideo}
            />
          </Link>
        </div>
      )}
      <div
        className={
          shouldDisplayImage && !isFeatured
            ? styles.headlineContainerWithImage
            : styles.headlineContainer
        }
      >
        {shouldDisplayImage && (
          <Unibrow
            className={styles.unibrow}
            size="h3"
            unibrow={unibrow}
          />
        )}
        <h2 className={styles.headline}>
          <Link target={shouldOpenLinkInNewTab ? '_blank' : ''} to={url}>
            {headline}
          </Link>
        </h2>
        { shouldShowSourceName && <p className={styles.aLaCarteItemSourceName} id="aLaCarte-source-name">{displaySource ? sourceName : publisher}</p> }
      </div>
    </li>
  );
}

Item.contextTypes = {
  ...packageContextTypes,
};

export { Item };
