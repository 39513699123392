import React from 'react';
import PropTypes from 'prop-types';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { content as ContentPropType } from 'lib/CustomPropTypes';

import { FeaturedEmbed } from 'components/packages/CoverSpread/Featured/Embed';
import { FeaturedCvsdk } from 'components/packages/CoverSpread/Featured/Cvsdk';
import { FeaturedVideo } from 'components/packages/CoverSpread/Featured/Video';
import { Tease } from 'components/packages/CoverSpread/Tease';
import { isTeaseCvsdkOverride } from 'components/packages/CoverSpread/utility';

import './styles.themed.scss';

const block = 'cover-spread-featured';

const FEATURED_ITEM_INDEX = 0;

/**
 * Featured component renders a featured item based on its type.
 *
 * @param {object} props
 * @param {ContentModule} props.content
 * @param {boolean} props.isRailAdjacent
 * @param {JSON} props.packageMetadata
 * @param {number} props.packageSize
 */
function Featured(props) {
  const {
    content,
    content: {
      type,
      metadata: itemMetadata,
    },
    isRailAdjacent = false,
    packageMetadata = {},
    packageSize,
  } = props;

  let FeatureComponent = Tease;

  // Embed, e.g., dataviz
  if (type === 'custom' && (itemMetadata?.useEmbed ?? false)) {
    FeatureComponent = FeaturedEmbed;
  // Playmaker video
  } else if (isTeaseCvsdkOverride(FEATURED_ITEM_INDEX, 'feature', itemMetadata, packageSize)) {
    FeatureComponent = FeaturedCvsdk;
  // Standard video
  } else if (type === 'video') {
    FeatureComponent = FeaturedVideo;
  }

  return (
    <div
      className={block}
      data-test={block}
      data-testid={block}
    >
      <FeatureComponent
        block={block}
        content={content}
        headlineSizes={`${block}__headline`}
        isRailAdjacent={isRailAdjacent}
        packageMetadata={packageMetadata}
        responsiveFlavors={{
          s: AIMS_FLAVORS.FOCAL_760x380,
          m: AIMS_FLAVORS.FOCAL_640x320,
          l: AIMS_FLAVORS.FOCAL_600x300,
          xl: AIMS_FLAVORS.FOCAL_560x280,
        }}
        layoutType="feature"
        packageSize={packageSize}
      />
    </div>
  );
}

Featured.propTypes = {
  content: ContentPropType.isRequired,
  isRailAdjacent: PropTypes.bool,
  packageMetadata: PropTypes.shape({}),
  packageSize: PropTypes.number.isRequired,
};


export { Featured };
