import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BadgeLabel from 'components/BadgeLabel';
import { BylineList } from 'components/Byline';
import { getLimitedAuthors } from 'lib/getLimitedAuthors';
import ContentTimestamp from 'components/ContentTimestamp';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { shouldTargetBeBlankHelper } from 'lib/shouldTargetBeBlankHelper';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { getItemData } from '../../../utils/packageDataManager';
import { ContentMedia } from '../../../ContentMedia';
import { Headline } from '../../../Headline';


/**
 *
 * @param {object} props
 */
export function StandardItemContent({
  content,
  itemData,
  columnIndex = 0,
  showLive = false,
  itemIndexInColumn = null,
  hideHeadlineAndDek = false,
  itemIndex = 0,
  colLead = false,
}) {
  const isLiveVideo = showLive;
  const {
    multiMediaSize,
    aspectRatio,
    packageMetadata,
    delimiter,
    hasEyebrow,
    badgeText,
    multiHeadlineSize,
    headline,
    headlineType,
    hasHeadline,
    storyType,
    unibrow,
    showHeadlineOnly,
    oneToOneAspect,
    hasRelatedContent,
    isVideoContent,
    isDataVizContent,
    showDek,
    dek,
    showAuthor,
    numberOfAuthors,
    showTimestamp,
    item,
    getBadgeType,
    headlineUrl,
    centerContent,
  } = getItemData(itemData,
    'multiStorylineItemData',
    {
      content, showLive, columnIndex, itemIndex, itemIndexInColumn,
    });

  const isSportsVideoEmbed = itemData.computedValues.url.includes('nbcsports.com/video/embed');

  const noMedia = multiMediaSize === 'none' || (!itemData.computedValues.teaseImage && !isSportsVideoEmbed);

  const { 'show-nbc-sports-front': sportsFeatureFlag } = useFeatureFlagContext();

  const sourceName = item?.source?.name || item?.source?.organization?.name;
  const subType = item?.subType;
  const shouldOpenLinkInNewTab = sportsFeatureFlag ? shouldTargetBeBlankHelper(sourceName, subType) : false;

  const smallMedia = multiMediaSize === 'multiSmall';

  const headlineSizeLargerThanL = multiHeadlineSize === 'large' || multiHeadlineSize === 'extraLarge' || multiHeadlineSize === 'xxl';

  const dekJsx = (
    <div className="multi-story__dek publico-txt f3 lh-copy fw4">
      {dek}
    </div>
  );
  const authorTimeJsx = (
    <div className={classNames(
      'multi-story-main-content',
      {
        'centered-content': centerContent,
      },
    )}
    >
      {showAuthor && (
        <BylineList
          authors={getLimitedAuthors(item?.authors, numberOfAuthors)}
        />
      )}
      {showAuthor && showTimestamp && (
        <span className="ma1">
          {delimiter}
        </span>
      )}
      {showTimestamp && (
        <ContentTimestamp
          dateCreated={item?.dateCreated}
          datePublished={item?.datePublished}
          delimiter=", "
        />
      )}
    </div>
  );

  /**
   *
   * @param {string} customClass
   */
  const mediaSection = (customClass) => {
    if (noMedia && !isDataVizContent) return null;
    if (isLiveVideo || isDataVizContent || itemData?.computedValues?.teaseImage !== null) {
      return (
        <div className={classNames(
          'media',
          customClass,
          `media-${multiMediaSize}`, {
            'one-to-one-aspect': oneToOneAspect,
            'live-video-container': isLiveVideo,
            isVideoContent,
          },
        )}
        >
          <div className="media-top-spacer mobile-multistory-media" />
          <ContentMedia
            colLead={colLead}
            columnIndex={columnIndex}
            aspectRatio={aspectRatio}
            storylineDataViz
            contentItem={itemData}
            packageMetadata={packageMetadata}
            mediaSize={multiMediaSize}
            isLiveVideo={isLiveVideo}
            uniqueId={customClass}
            shouldOpenLinkInNewTab={shouldOpenLinkInNewTab}
          />
        </div>
      );
    }
    return null;
  };

  const standardItemContent = (
    <>
      {mediaSection('non-mobile-multistory-media')}
      {
        !hideHeadlineAndDek
        && (
          <div className={classNames(
            'headline-item-container',
            {
              'headline-container-small': multiMediaSize === 'multiSmall',
              'one-to-one-media': oneToOneAspect && !noMedia,
              'three-to-two-media': !oneToOneAspect && !noMedia,
              'media-none': noMedia,
              'centered-content': centerContent,
            },
          )}
          >
            <div className="standard-layout__badge">
              {hasEyebrow && (
                <>
                  <div className="badge-top-spacer" />
                  <BadgeLabel
                    badgeType={getBadgeType()}
                    badgeText={badgeText}
                    eyebrowText={unibrow?.text}
                    url={unibrow?.url?.primary}
                  />
                </>
              )}
            </div>
            <div className={classNames({
              'headline-small': multiHeadlineSize === 'small',
              'headline-standard': multiHeadlineSize === 'standard',
              'no-media': !showLive && noMedia,
              'headline-large': multiHeadlineSize === 'large',
              'headline-xl': multiHeadlineSize === 'extraLarge',
              'headline-xxl': multiHeadlineSize === 'xxl',
              'headline-mobile-no-media': !showLive && noMedia && headlineSizeLargerThanL,
            })}
            >
              { /* if not multiSmall and there is eyebrow, use small spacer */
                hasHeadline && multiMediaSize !== 'multiSmall' && hasEyebrow && (
                  <div className="headline-top-spacer" />
                )
              }
              { /* if multiStandard media size and no eyebrow, we are below media, use larger spacer
                that goes to zero in mobile since image will go to bottom.  */
                hasHeadline && multiMediaSize === 'multiStandard' && !hasEyebrow && (
                  <div className="headline-under-media-spacer" />
                )
              }
              <Headline
                text={headline}
                headlineSize={headlineType}
                storyType={storyType}
                url={headlineUrl}
                showHeadlineOnly={showHeadlineOnly}
                noBottomSpace={!hasRelatedContent}
                shouldOpenLinkInNewTab={shouldOpenLinkInNewTab}
              />
              {/* If media is small with no Dek toggled on show author/timestamp below headline  */}
              {(smallMedia && !showDek && (showAuthor || showTimestamp)) && (
                <>
                  <div className="author-top-spacer" />
                  {authorTimeJsx}
                </>
              )}
            </div>
          </div>
        )
      }
      {
        !hideHeadlineAndDek && (showDek || showAuthor || showTimestamp) && (
          <div className={classNames(
            'dek-author-timestamp',
            {
              'centered-content': centerContent,
            },
          )}
          >
            {showDek && (
              <>
                <div className="dek-top-spacer" />
                {dekJsx}
              </>
            )}
            {(!smallMedia || showDek) && (showAuthor || showTimestamp) && (
              <>
                <div className="author-top-spacer" />
                {authorTimeJsx}
              </>
            )}
          </div>
        )
      }
    </>
  );

  return standardItemContent;
}

StandardItemContent.propTypes = {
  content: PackagePropType.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  itemData: PropTypes.object.isRequired,
  columnIndex: PropTypes.number,
  showLive: PropTypes.bool,
  hideHeadlineAndDek: PropTypes.bool,
  itemIndexInColumn: PropTypes.number,
  itemIndex: PropTypes.number,
  colLead: PropTypes.bool,
};
