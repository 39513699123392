import React from 'react';
import './styles.themed.scss';

import { VerticalContext } from 'lib/ContextTypes/vertical';
import {
  getPlaymakerMetadata,
  MSNBC_TVE_PID,
} from 'lib/liveVideo';

import { CvsdkTeaseDefault } from './Default';
import { CvsdkTeaseMSNBC } from './MSNBC';

/**
 * CvsdkTease is a React component that displays a cvsdk tease based on the provided props.
 * It uses the CvsdkTeaseDefault or CvsdkTeaseMSNBC component based on the cvsdkId and useDefault props.
 *
 * @param {object} props - The properties passed to the component.
 * @param {Unibrow} props.unibrow - The unibrow prop type.
 * @param {Function} props.handleStatus - Function to handle status.
 * @param {boolean} props.hasClickedToPlay - Indicates if the play button has been clicked.
 * @param {string} props.icid - The ICID.
 * @param {JSON} props.metadata - The metadata for CVSDK.
 * @param {boolean} props.showDek - Indicates if the dek should be shown.
 * @param {boolean} props.showPlayerControls - Indicates if the player controls should be shown.
 * @param {boolean} props.showPlayerActionButton - Indicates if the player action button should be shown.
 * @param {boolean} props.showTitle - Indicates if the title should be shown.
 * @param {SlateOverride} props.slateOverride - If this prop is populated, it will override the default slate.
 * @param {boolean} props.stickyEnabled - Indicates if sticky is enabled.
 * @param {CvsdkStyleOverride} props.styleOverride - The style override.
 * @param {boolean} props.useDefault - Indicates if the default CVSDK tease should be used.
 * @param {boolean} props.isStoryline - Indicates if it is a storyline.
 * @param {Function} props.onStuckChange - Function to handle stuck change.
 * @param {boolean} props.noStuckChangeInFullscreen - Indicates StickyPIP should not be active during fullscreen.
 */
function CvsdkTease({
  unibrow = undefined,
  handleStatus = Function.prototype,
  hasClickedToPlay = false,
  icid = null,
  metadata = {},
  showDek = true,
  showPlayerActionButton = true,
  showPlayerControls = false,
  showTitle = true,
  slateOverride = {},
  stickyEnabled = false,
  styleOverride = {},
  useDefault = false,
  isStoryline = false,
  onStuckChange = Function.prototype,
  noStuckChangeInFullscreen = false,
}) {
  const vertical = React.useContext(VerticalContext);

  const cvsdkMetadata = getPlaymakerMetadata(metadata, vertical);
  const { playmakerId: cvsdkId, streamId } = cvsdkMetadata;
  let CvsdkComponent;

  if (!useDefault && [MSNBC_TVE_PID].includes(cvsdkId)) {
    CvsdkComponent = CvsdkTeaseMSNBC;
  } else if (typeof cvsdkId === 'string' && cvsdkId.length > 0) {
    CvsdkComponent = CvsdkTeaseDefault;
  }

  if (CvsdkComponent) {
    return (
      <CvsdkComponent
        block="cvsdk-tease"
        handleStatus={handleStatus}
        hasClickedToPlay={hasClickedToPlay}
        icid={icid}
        playmakerMetadata={cvsdkMetadata}
        streamId={streamId}
        showDek={showDek}
        showPlayerActionButton={showPlayerActionButton}
        showPlayerControls={showPlayerControls}
        showTitle={showTitle}
        slateOverride={slateOverride}
        stickyEnabled={stickyEnabled}
        styleOverride={styleOverride}
        vertical={vertical}
        unibrow={unibrow}
        isStoryline={isStoryline}
        onStuckChange={onStuckChange}
        noStuckChangeInFullscreen={noStuckChangeInFullscreen}
      />
    );
  }

  return null;
}

export { CvsdkTease };
