import i18next from 'i18next';
import classNames from 'classnames';
import React, { useContext } from 'react';

import Link from 'components/Link';
import TitleLogo from 'components/TitleLogo';

import './styles.themed.scss';
import { VerticalContext } from 'lib/ContextTypes';

/**
 * Component to render the top border of the package title.
 *
 * @param {object} props - The properties for the PackageTitleTopBorder component.
 * @param {string} [props.additionalClasses=''] - Additional CSS classes for the component.
 * @param {string} [props.logoUrl=''] - URL of the logo image.
 * @param {string} [props.seeAllText=''] - Text for the "See All" link.
 * @param {string} [props.seeAllUrl=''] - URL for the "See All" link.
 * @param {string} [props.title=''] - Title text.
 * @param {string|null} [props.icid=null] - ICID for tracking.
 * @param {string} [props.HTag='h2'] - HTML tag to use for the title.
 */
export function PackageTitleTopBorder({
  additionalClasses = '',
  logoUrl = '',
  seeAllText = '',
  seeAllUrl = '',
  title = '',
  icid = null,
  HTag = 'h2',
}) {
  const vertical = useContext(VerticalContext);

  if (!title && !logoUrl) {
    return null;
  }

  const headingClasses = classNames(
    'package-title-top-border__title',
  );

  const containerClasses = classNames(
    'package-title-top-border',
    'relative',
    'df',
    'flex-column',
    additionalClasses,
  );

  const isTodayVertical = false;
  const isNotAlternateDisplay = false;
  const newsVertical = vertical === 'news';

  const link = seeAllUrl
    ? (
      <Link
        className={classNames(
          'package-title-top-border__link',
          {
            'package-title-top-border__link--alt-color': isTodayVertical && isNotAlternateDisplay,
          },
        )}
        to={seeAllUrl}
        icid={icid}
      >
        {seeAllText || i18next.t('See All')}
      </Link>
    )
    : null;

  return (
    <div className={containerClasses} data-testid="package-title-top-border">
      <div className="package-title-top-border__top-decoration" data-test="topDecoration" />
      {newsVertical && <hr className="package-title-top__logo-line" />}
      <HTag className={headingClasses}>
        <TitleLogo logoUrl={logoUrl} title={title} />
        {link}
      </HTag>
    </div>
  );
}
