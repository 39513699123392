import React, {
  createRef, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';

import { useIsGeoRestricted } from 'components/OmegaVideoPlayer/useIsGeoRestricted';
import { ERROR_TYPES } from 'components/TVE/Slate';
import { logError } from 'lib/datadog';
import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import TempPass from 'components/TVE/TempPass';
import ProviderSelect from 'components/TVE/ProviderSelect';
import CallToAction from 'components/TVE/CallToAction';
import Player from 'components/TVE/Player';
import Info from 'components/TVE/Info';
import Schedule from 'components/TVE/Schedule';

import './styles.themed.scss';

/**
 *
 * @param {object} args
 * @param {boolean} args.authenticated
 * @param {object} args.epgCurrent
 * @param {boolean} args.hasTempPass
 * @param {boolean} args.providerSelectVisible
 * @param {ERROR_TYPES} args.videoError
 */
const TVE = ({
  authenticated = null,
  epgCurrent = {},
  hasTempPass = null,
  providerSelectVisible = false,
  videoError = false,
}) => {
  const iframeRef = createRef(null);
  const isGeoRestricted = useIsGeoRestricted();
  const playerContainerRef = useRef(null);
  const previousSizeRef = useRef({ width: 0, height: 0 });
  const previousPositionRef = useRef({ top: 0, left: 0 });


  const title = epgCurrent?.program?.title ?? null;
  const description = epgCurrent?.program?.description ?? null;

  const {
    publicRuntimeConfig: {
      DISABLE_AUTH_TVE_MSNBC,
    },
  } = getConfig();

  const authEnabled = !DISABLE_AUTH_TVE_MSNBC && !isGeoRestricted;

  // Error logging below to detect cause of MSNBC Live Player collapsing
  /**
    * Gets position document.
    * @param {*} container The div wrapping the live video
    * @returns {object} The top and left positions.
    */
  const getDocumentPosition = (container) => {
    const rect = container.getBoundingClientRect();
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
  };

  useEffect(() => {
    const container = playerContainerRef.current;
    if (!container) return undefined;
    /* eslint-disable no-restricted-syntax */
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        if (width === 0 || height === 0) {
          console.error('Video player has collapsed or shrunk:', {
            width,
            height,
            currentDate: new Date().toISOString(),
          });
          logError('Video player has collapsed or shrunk:', `details: ${width}, ${height}`);
        }
        previousSizeRef.current = { width, height };
      }
    });
    resizeObserver.observe(container);
    /**
    * Cleans up on unmount
    * @returns {*} disconnects resize observer
    */
    return () => {
      resizeObserver.unobserve(container);
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const container = playerContainerRef.current;
    if (!container) return undefined;

    previousPositionRef.current = getDocumentPosition(container);

    /**
     *
     */
    const handleMutations = () => {
      const currentPosition = getDocumentPosition(container);
      const { top: prevTop, left: prevLeft } = previousPositionRef.current;
      const { top, left } = currentPosition;
      const POSITION_THRESHOLD = 10;
      const hasMoved = Math.abs(top - prevTop) > POSITION_THRESHOLD
        || Math.abs(left - prevLeft) > POSITION_THRESHOLD;
      if (hasMoved) {
        const errorDetails = {
          previousPosition: previousPositionRef.current,
          currentPosition,
          currentDate: new Date().toISOString(),
        };
        console.error('Video player has moved to a different part of the page:', errorDetails);
        logError('Video player has moved to a different part of the page:', errorDetails);
        previousPositionRef.current = currentPosition;
      }
    };

    const mutationObserver = new MutationObserver(handleMutations);
    mutationObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    const positionCheckInterval = setInterval(handleMutations, 1000);
    /**
    * Cleans up on unmount
    * @returns {*} disconnects mutation observer and clears the position check interval
    */
    return () => {
      mutationObserver.disconnect();
      clearInterval(positionCheckInterval);
    };
  }, []);

  return (
    <>
      {authEnabled && (
        <>
          <WithTVEAuthProvider iframeRef={iframeRef} />
          {videoError !== ERROR_TYPES.BROWSER && (
            <TempPass
              checkTempPass={authenticated === false && hasTempPass === false}
            />
          )}
        </>
      )}

      <section className="tve-view-curator">
        <div className="tve-view-curator__inner">
          {authEnabled && (
            <>
              {providerSelectVisible && (
                <ProviderSelect iframeRef={iframeRef} />
              )}
              <CallToAction />
            </>
          )}


          <div data-testid="player" className="tve-view-curator__player-outer" ref={playerContainerRef}>
            <Player bypassAuth={DISABLE_AUTH_TVE_MSNBC} autoplayMuted />
          </div>

          <div className="tve-view-curator__info-outer">
            <div className="tve-view-curator__info-inner">
              <Info
                description={description}
                title={title}
              />
            </div>
          </div>

          <div className="tve-view-curator__schedule-outer">
            <Schedule />
          </div>
        </div>
      </section>
    </>
  );
};

TVE.propTypes = {
  authenticated: PropTypes.bool,
  epgCurrent: PropTypes.shape(schedulePropType),
  hasTempPass: PropTypes.bool,
  providerSelectVisible: PropTypes.bool,
  videoError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};


/**
 *
 * @param {object} args
 * @param {object} args.tve
 * @param {object} args.video
 */
const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  epgCurrent: tve.epgCurrent,
  hasTempPass: tve.hasTempPass,
  providerSelectVisible: tve.providerSelectVisible,
  videoError: video.error,
});

export { TVE };

export default connect(mapStateToProps)(TVE);
