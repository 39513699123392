/**
 *
 */
export const itemBaseDefaults = () => ({
  multiHeadlineSize: 'standard',
  multiMediaSize: 'multiStandard',
  hasEyebrow: true,
  aspectRatio: '3 / 2',
});

export const presetDefaultsConfig = {
  '3UP': {
    packageDefaults: {
      columns: 2,
      itemsInColumns: [1, 2],
      leadColumn: 'Column1Lead',
    },
    fallbackItemDefaults: {
      multiHeadlineSize: 'small',
      multiMediaSize: 'none',
      hasEyebrow: false,
      aspectRatio: '3 / 2',
    },
    itemDefaults: {
      0: {
        0: {
          multiHeadlineSize: 'large',
          multiMediaSize: 'multiStandard',
          hasEyebrow: false,
        },
      },
      1: {
        0: {
          multiHeadlineSize: 'standard',
          multiMediaSize: 'multiStandard',
          hasEyebrow: false,
        },
        1: {
          multiHeadlineSize: 'small',
          multiMediaSize: 'none',
          hasEyebrow: false,
        },
      },
    },
  },
  '4UP': {
    packageDefaults: {
      columns: 2,
      itemsInColumns: [1, 3],
      leadColumn: 'Column1Lead',
    },
    fallbackItemDefaults: {
      multiHeadlineSize: 'small',
      multiMediaSize: 'none',
      hasEyebrow: false,
      aspectRatio: '3 / 2',
    },
    itemDefaults: {
      0: {
        0: {
          multiHeadlineSize: 'large',
          multiMediaSize: 'multiStandard',
          hasEyebrow: false,
        },
      },
      1: {
        0: {
          multiHeadlineSize: 'small',
          multiMediaSize: 'multiStandard',
          hasEyebrow: false,
        },
        1: {
          multiHeadlineSize: 'small',
          multiMediaSize: 'none',
          hasEyebrow: false,
        },
        2: {
          multiHeadlineSize: 'small',
          multiMediaSize: 'none',
          hasEyebrow: false,
        },
      },
    },
  },
};
