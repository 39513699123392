import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import get from 'lodash.get';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { isResizableImage } from 'lib/imageUtils';

import { video as videoPropType } from 'lib/CustomPropTypes';
import { Picture } from 'components/Picture';

import './styles.themed.scss';

class VideoSlate extends Component {
  static propTypes = {
    centerPlayButtonAtMobile: PropTypes.bool,
    isContentPlaying: PropTypes.bool,
    isLive: PropTypes.bool,
    isLoading: PropTypes.bool,
    isRailLayout: PropTypes.bool,
    onPlayClick: PropTypes.func,
    pageView: PropTypes.string.isRequired,
    playButtonStyle: PropTypes.string,
    positionStyleValue: PropTypes.string,
    showPlayIcon: PropTypes.bool,
    showReplayIcon: PropTypes.bool,
    showTeaseImage: PropTypes.bool,
    video: videoPropType.isRequired,
    isAutoPlay: PropTypes.bool,
    loadingLazy: PropTypes.bool,
  };

  static defaultProps = {
    centerPlayButtonAtMobile: false,
    isContentPlaying: false,
    isLive: false,
    isLoading: false,
    isRailLayout: false,
    /**
     * On Play event handler
     */
    onPlayClick: () => {},
    playButtonStyle: null,
    positionStyleValue: 'relative',
    showPlayIcon: true,
    showReplayIcon: false,
    showTeaseImage: true,
    isAutoPlay: false,
    loadingLazy: null,
  };

  /**
   *
   * @param {string} src
   */
  getImgWidthFromSrc = (src = '') => {
    const [, width = null] = src.match(/([1-3]?[0-9]{3})x[1-3]?[0-9]{3}[-.]/) || [];

    return width;
  }

  /**
   *
   * @param {string} src
   */
  getImgHeightFromSrc = (src = '') => {
    const [, height = null] = src.match(/[1-3]?[0-9]{3}x([1-3]?[0-9]{3})[-.]/) || [];

    return height;
  }

  /**
   * Overlay for expired videos
   */
  showExpiredOverlay = () => (
    <div
      className="expiredOverlay items-center df absolute w-100 h-100 z-2 f7 f8-m"
      data-testid="video-player__expired-overlay"
    >
      <div className="expiredText founders-cond tc w-100 gray-60">
        <Trans>We apologize, this video has expired.</Trans>
      </div>
    </div>
  );

  /**
   * Replay Icon
   */
  replayIcon() {
    const {
      isContentPlaying,
      showReplayIcon,
      isLoading,
      centerPlayButtonAtMobile,
      isAutoPlay,
    } = this.props;

    if (showReplayIcon && !isContentPlaying && !isLoading && !isAutoPlay) {
      return (
        <div
          className={classNames(
            'absolute absolute-fill w100 dn-print df replayIconContainer',
            {
              'items-center items-center-m justify-center justify-center-m':
                !centerPlayButtonAtMobile,
              'items-center justify-center': centerPlayButtonAtMobile,
            },
          )}
          data-testid="video-player__replay-button__outer"
        >
          <span className="replayIcon z-2 white icon icon-replay" />
        </div>
      );
    }
    return null;
  }

  /**
   * Play Icon
   */
  playIcon() {
    const {
      centerPlayButtonAtMobile,
      isContentPlaying,
      isLive,
      isLoading,
      isRailLayout,
      playButtonStyle,
      showPlayIcon,
    } = this.props;

    if (showPlayIcon && !isContentPlaying && !isLoading) {
      return (
        <div
          className="playButtonOuter absolute absolute-fill w100 dn-print"
          data-testid="video-player__play-button__outer"
        >
          <div
            className={classNames('absolute absolute-fill w100 df', {
              'items-center items-center-m justify-center justify-center-m':
                !centerPlayButtonAtMobile,
              'items-center justify-center': centerPlayButtonAtMobile,
            })}
          >
            <div
              className={classNames('play', playButtonStyle, {
                'play--live': isLive,
                'play--rail-layout': isRailLayout,
              })}
            >
              <svg
                className="play--icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15 15"
                preserveAspectRatio="xMidYMid"
                style={{ maxWidth: '30px' }}
              >
                <path d="M0 0v15l15-7.41z" fillRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  /**
   * Teaser Image played before video loads
   */
  teaseImage() {
    const { showTeaseImage } = this.props;
    if (!showTeaseImage) {
      return null;
    }

    const {
      video,
      pageView,
      onPlayClick,
      loadingLazy,
    } = this.props;

    const img = get(video, 'teaseImage') || get(video, 'primaryImage');
    const imgSrc = get(img, 'url.primary') || get(img, 'url') || undefined;
    const width = get(img, 'width') || this.getImgWidthFromSrc(imgSrc);
    const height = get(img, 'height') || this.getImgHeightFromSrc(imgSrc);
    const href = get(video, 'url.canonical') || get(video, 'url.primary') || null;
    const altText = get(img, 'altText') || null;

    let picture = (
      <img
        src={imgSrc}
        alt={altText}
        width={width}
        height={height}
      />
    );

    // If resizeable (AIMS or Telemundo) image, use Picture component.
    if (isResizableImage(imgSrc)) {
      const pictureProps = {
        responsiveFlavors: {
          s: AIMS_FLAVORS.FOCAL_760x428, // 16x9 ratio (56.25%)
          m: AIMS_FLAVORS.FOCAL_1000x563,
          xl: AIMS_FLAVORS.FOCAL_860x484,
        },
        url: imgSrc,
        alt: altText,
        useSuffix: true,
        aimsOptions: { mode: 5 },
        originalWidth: width,
        originalHeight: height,
      };

      // Slate on a video page is largest contentful paint so
      // we do not want to lazy load
      if (loadingLazy !== false && pageView !== 'video') {
        pictureProps.lazyloaded = true;
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      picture = <Picture {...pictureProps} />;
    }

    // This <a> requires href from a structural perspective, so bypass click blocking in preview.
    return (
      <a
        href={href}
        onClick={onPlayClick}
        className="allowClicksOnPreview"
      >
        {picture}
      </a>
    );
  }

  /**
   * React render function
   */
  render() {
    const {
      showTeaseImage,
      showPlayIcon,
      showReplayIcon,
    } = this.props;

    // Requires tease image
    if (!showTeaseImage) return null;

    const {
      video: {
        playable,
        isContinuousPlay,
      },
      positionStyleValue,
    } = this.props;

    const teaseImage = this.teaseImage();

    // check to see if video is playable or expired. If expired, render overlay
    let videoPlayable = true;
    if (typeof playable !== 'undefined') {
      videoPlayable = playable;
    }
    if (typeof isContinuousPlay !== 'undefined') {
      videoPlayable = videoPlayable || isContinuousPlay;
    }

    const videoSlateClass = classNames('videoSlate', positionStyleValue, {
      rePlayOverlay: showReplayIcon && showTeaseImage,
    });

    if (teaseImage !== null) {
      return (
        <div className={videoSlateClass}>
          {!videoPlayable && this.showExpiredOverlay()}
          {teaseImage}
          {videoPlayable && showPlayIcon && this.playIcon()}
          {videoPlayable && showReplayIcon && this.replayIcon()}
        </div>
      );
    }

    if (showReplayIcon) {
      return this.replayIcon();
    }

    return this.playIcon();
  }
}

// eslint-disable-next-line import/no-default-export
export default VideoSlate;
