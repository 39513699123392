import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BadgeLabel from 'components/BadgeLabel';
import { BADGES } from 'components/BadgeLabel/constants';
import { BylineList } from 'components/Byline';

import Link from 'components/Link';
import { getLimitedAuthors } from 'lib/getLimitedAuthors';
import { RelatedContentTeaseMedia } from './Media/index';

import './styles.themed.scss';

export const RelatedContentTease = ({
  relatedItem,
  isVerticallyCentered = false,
  displayTeaseImage = false,
  lastRelated = false,
  isMultiStoryline = false,
  shouldOpenLinkInNewTab = false,
}) => {
  const divBoxRef = useRef(null);
  const [width, setWidth] = useState();
  const [isNarrow, setIsNarrow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (divBoxRef.current !== null) {
        setWidth(divBoxRef.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [divBoxRef]);

  useEffect(() => {
    if (width <= 185) {
      setIsNarrow(true);
    } else {
      setIsNarrow(false);
    }
  }, [width]);

  const {
    item, type, computedValues, metadata,
  } = relatedItem;

  const {
    hasBadge = false, badge, badgeText, hasMedia, showAuthor, numberOfAuthors = 1,
  } = metadata;

  if (computedValues === null) {
    return null;
  }

  const {
    unibrow = {},
  } = computedValues;

  const displayMedia = displayTeaseImage || (hasMedia && (computedValues.teaseImage !== null));
  const displayIcon = relatedItem.type === 'video';
  const displayLabel = hasBadge;
  const getBadgeType = () => {
    if (hasBadge && !badge) {
      return 'BREAKING';
    }
    return (hasBadge && badge) || BADGES.EYEBROW;
  };

  return (
    <div
      ref={divBoxRef}
      className={
        classNames('related-content-tease',
          { 'last-related': lastRelated })
      }
    >
      <div to={computedValues.url} className="related-content-tease__headline-link">
        <div className={classNames(
          'related-content-tease__box',
          {
            'related-content-tease__box--narrow': isNarrow,
            multistoryline: isMultiStoryline,
          },
        )}
        >
          { displayMedia && (
            <Link target={shouldOpenLinkInNewTab ? '_blank' : ''} to={computedValues.url}>
              <RelatedContentTeaseMedia
                displayPlayButton={displayIcon}
                type={type}
                computedValues={computedValues}
                isNarrow
              />
            </Link>
          ) }
          <div
            className={classNames(
              'related-content-tease__headline',
              {
                'related-content-tease__headline--with-media': displayMedia,
                'related-content-tease__headline--with-media--narrow': isNarrow,
                'related-content-tease__headline--with-media--vertical-center': displayMedia || isVerticallyCentered,
                'related-content-tease__headline--tall': isVerticallyCentered,
                'related-content-tease--label': displayLabel,
                'related-content-tease--byline': showAuthor,
                'related-content-tease--no-media': !displayMedia,
              },
            )}
          >
            { displayLabel
                && (
                  <span className={classNames(
                    'related-content-tease__badge-label',
                    { 'related-content-tease__badge-label--narrow': isNarrow },
                  )}
                  >
                    <BadgeLabel
                      badgeType={getBadgeType()}
                      badgeText={badgeText}
                      eyebrowText={unibrow?.text}
                      url={unibrow?.url?.primary}
                    />
                  </span>
                )}
            <Link target={shouldOpenLinkInNewTab ? '_blank' : ''} to={computedValues.url}>
              {computedValues.headline}
            </Link>
            {showAuthor && item?.authors ? (
              <>
                <div className="author-top-spacer" />
                <div className="related-content-byline">
                  <BylineList
                    authors={getLimitedAuthors(item.authors, numberOfAuthors)}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

RelatedContentTease.propTypes = {
  relatedItem: PropTypes.instanceOf(Object).isRequired,
  isVerticallyCentered: PropTypes.bool,
  displayTeaseImage: PropTypes.bool,
  lastRelated: PropTypes.bool,
  isMultiStoryline: PropTypes.bool,
  shouldOpenLinkInNewTab: PropTypes.bool,
};

RelatedContentTease.defaultProps = {
  isVerticallyCentered: false,
  displayTeaseImage: false,
  lastRelated: false,
  isMultiStoryline: false,
};
