/* eslint-disable jsdoc/require-jsdoc */
/* About containerFunction.js
    These are for MSP Container and are ways of accessing
    the lead item for using it as package headline when selected
*/

import { BADGES } from 'components/BadgeLabel/constants';


/**
 *
 * @param {string} leadHeadlineSize
 * @param {boolean} contentAsPackageHeadline
 * @param {string} leadColumn
 * @param {Array} items
 * @param {object} metadata
 * @returns {object}
 */

/* Find lead item info for when contentAsPackageHeadline is selected
*/
export const findLeadItemInfo = (args) => {
  const {
    leadHeadlineSize,
    contentAsPackageHeadline,
    leadColumn,
    items,
    itemsPerColumn,
    hasColumn1Lead,
    hasColumn2Lead,
  } = args;
  if (!items) return null;
  const noLead = leadColumn === undefined;
  const [numOfItemsCol1, numOfItemsCol2] = itemsPerColumn;
  const prevItemsNum = numOfItemsCol1 + numOfItemsCol2;
  let leadItemValues = {};
  const columnLeads = {
    Column1Lead: 0,
    Column2Lead: numOfItemsCol1,
    Column3Lead: prevItemsNum,
  };
  const leadIndex = columnLeads[leadColumn];
  const leadItem = items[leadIndex];

  if (contentAsPackageHeadline && !noLead) {
    leadItemValues = {
      headlineSize: leadHeadlineSize,
      // eslint-disable-next-line no-nested-ternary
      columnLeadIndex: hasColumn1Lead ? 0 : hasColumn2Lead ? 1 : 2,
      removed: false,
      valueRemoved: leadIndex,
      item: leadItem ?? null,
    };
    // Headline+dek+byline will move above the columns, standard media stays:
    // * If there is media to show, leave the item in the column to render the media.
    // * Overwise mark it to be removed from the columns to avoid an extra content
    // divider.
    // * We set the flag to remove here, but it's used by the caller.
    if ((!leadItem?.computedValues.teaseImage
      || leadItem?.metadata?.mediaSize === 'multiSmall')
      && leadItem?.type !== 'liveBlog'
      && leadItem?.type !== 'custom') {
      leadItemValues.remove = true;
    }
  }

  return leadItemValues;
};

/**
 *
 * @param {string} badge
 * @param {boolean} hasBadge
 * @returns {string}
 */

// find badge type for MSPContainer
export const getBadgeType = (badge, hasBadge) => {
  if (hasBadge && !badge) {
    return 'BREAKING';
  }
  return (hasBadge && badge) || BADGES.EYEBROW;
};
