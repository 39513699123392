/**
 * Retrieves and orders live blog cards from the Curation API and Bento API.
 *
 * The function first parses cards from the Curation API, separating them into:
 * - **Pinned cards** (autofill=false)
 * - **Autofilled cards** (autofill=true)
 *
 * If live updates (Bento API) are available, it merges them with non-pinned cards
 * while preserving pinned cards at the top.
 *
 * @param {object} root0 - The root object containing parameters.
 * @param {object} root0.liveBlogPkgInstances - Contains the latest live blog items from Bento API.
 * @param {object} root0.content - The content object containing items and their related cards.
 * @param {number} root0.itemIndex - The index of the item to retrieve cards for.
 * @param {string} root0.articleId - The ID of the article.
 * @returns {Array} An array of ordered live blog cards.
 */
export const getOrderedLiveBlogCards = ({
  liveBlogPkgInstances,
  content,
  itemIndex,
  articleId,
}) => {
  const item = (content?.items?.[itemIndex]) || {};
  const cardsFromCurator = (item?.related ?? []).filter((c) => c !== null);
  const checkedPinnedCards = new Set();
  const pinnedCardsFromCurator = cardsFromCurator.filter((val) => {
    if (!val || !val.id || val.autofilled || checkedPinnedCards.has(val.id)) return false;
    checkedPinnedCards.add(val.id);
    return true;
  });
  const autofilledCardsFromCurator = cardsFromCurator.filter((c) => c.autofilled);
  const nonPinnedCardsFromCurator = autofilledCardsFromCurator.length > 0
    ? autofilledCardsFromCurator
    // In the case that curator/Bento API has broken and `item.related` is empty or has no
    // non-autofilled cards, but `item.item.cards` has cards in it, we should at least server-side
    // render those cards. This avoids a breakage with the Live Blog Embed package on mobile,
    // which will render shorter, the client-side call loads the cards, and then the card
    // headlines are cut off. See BENTO-23058
    : (item?.item?.cards ?? []).filter((c) => c);
  const orderedCardsFromCurator = [
    ...pinnedCardsFromCurator,
    ...nonPinnedCardsFromCurator,
  ];
  let cardsToRender;
  /**
   * Via polling we get an updated list of live blog cards direct
   * from the Bento API. Here we need merge the new cards from
   * Bento API with the existing cards from the Curation
   */
  const cardsFromBento = (
    liveBlogPkgInstances
    && typeof liveBlogPkgInstances === 'object'
    && liveBlogPkgInstances[articleId]?.latestItems)
    || [];

  if (cardsFromBento.length > 0) {
    const nonPinnedCardsFromCuratorById = new Map(
      nonPinnedCardsFromCurator.map((card) => [card.id, card]),
    );
    const mergedCards = cardsFromBento.reduce((acc, card) => {
      const { id } = card;
      if (!checkedPinnedCards.has(id)) {
        acc.push(nonPinnedCardsFromCuratorById.get(id) || card);
      }
      return acc;
    }, []);
    cardsToRender = [...pinnedCardsFromCurator, ...mergedCards];
  } else {
    cardsToRender = orderedCardsFromCurator;
  }
  return cardsToRender;
};
