import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { setErrorAction } from 'redux/modules/video/error';

import BrowserDetection from 'lib/BrowserDetection';
import { configTVE } from 'lib/tve';
import { ConcurrencyMonitor } from 'components/TVE/ConcurrencyMonitor';
import Loader from 'components/IconLoader';
import Slate, { ERROR_TYPES } from 'components/TVE/Slate';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';

import placeholderVideo from 'assets/videos/msnbc-placeholder.mp4';

import './styles.themed.scss';
import { GeoRestriction } from 'components/TVE/GeoRestriction';

const block = 'tve__player';

/**
 * Maps state from the Redux store to the component's props.
 *
 * @param {object} state - The Redux state.
 * @param {object} state.router - The router state.
 * @param {object} state.shared - The shared state.
 * @param {object} state.tve - The TVE state.
 * @param {object} state.video - The video state.
 * @returns {object} The mapped props.
 */
const mapStateToProps = ({
  router, shared, tve, video,
}) => ({
  authenticated: tve.authenticated,
  domain: router.domain,
  path: router?.path,
  hasTempPass: tve.hasTempPass,
  mvpdAccountMetadata: tve.metadata,
  selectedProvider: tve.selected,
  tempPassProviderID: tve.tempPassProviderID,
  isLoadingMvpd: !!tve?.loadingMVPD,
  videoError: video.error,
  vertical: shared.vertical,
});

/**
 * Maps dispatch actions to the component's props.
 *
 * @param {Function} dispatch
 * @returns {object} The mapped actions.
 */
const mapActionToProps = (dispatch) => ({
  /**
   * Sets an error in the Redux store.
   *
   * @param {object} payload - The error payload.
   */
  setError: (payload) => dispatch(setErrorAction(payload)),
});

export class PlayerWrapper extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    autoplayMuted: PropTypes.bool,
    bypassAuth: PropTypes.bool,
    clickToPlay: PropTypes.bool,
    domain: PropTypes.string,
    hasTempPass: PropTypes.bool,
    mvpdAccountMetadata: PropTypes.shape({
      // Adobe code returns a string of "true" or "false" rather than a boolean
      hba_status: PropTypes.oneOf(['true', 'false']),
      userID: PropTypes.string,
    }),
    selectedProvider: PropTypes.shape({
      freewheelHash: PropTypes.string,
      id: PropTypes.string,
      concurrencyFlow: PropTypes.number,
    }),
    setError: PropTypes.func.isRequired,
    stickyEnabled: PropTypes.bool,
    onStuckChange: PropTypes.func,
    tempPassProviderID: PropTypes.string,
    isLoadingMvpd: PropTypes.bool,
    videoError: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    noStuckChangeInFullscreen: PropTypes.bool,
  };

  static defaultProps = {
    authenticated: false,
    autoplayMuted: false,
    bypassAuth: false,
    clickToPlay: false,
    domain: null,
    hasTempPass: null,
    mvpdAccountMetadata: null,
    selectedProvider: {
      freewheelHash: null,
      id: null,
    },
    isLoadingMvpd: false,
    stickyEnabled: false,
    onStuckChange: Function.prototype,
    tempPassProviderID: null,
    videoError: null,
    noStuckChangeInFullscreen: false,
  };

  /**
   * Creates an instance of PlayerWrapper.
   *
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);

    this.state = {
      placeholder: false,
      isVideoPlaying: false,
    };
  }

  /**
   * Lifecycle method that is called after the component is mounted.
   */
  componentDidMount() {
    // Does not work in IE, throw browser error
    if (BrowserDetection.isIE() && BrowserDetection.version() <= 11) {
      const { setError } = this.props;
      setError(ERROR_TYPES.BROWSER);
    }

    // Prevent giant placeholder video from displaying unstyled until mounted
    this.setState({ placeholder: true });
  }

  /**
   * Handles the play event.
   */
  onPlayListener = () => {
    this.setState({ isVideoPlaying: true });
  }

  /**
   * Handles the pause event.
   */
  onPauseListener = () => {
    this.setState({ isVideoPlaying: false });
  }

  /**
   * Updates the isVideoPlaying state.
   *
   * @param {boolean} val - The new value for isVideoPlaying.
   */
  updateIsVideoPlaying = (val) => {
    this.setState({ isVideoPlaying: val });
    if (val === true) {
      this.setState({ placeholder: false });
    }
  }

  /**
   * Renders the placeholder video.
   *
   * @returns {React.ReactElement} The placeholder video element.
   */
  renderPlaceholder = () => (
    <div className={`${block}__slate absolute absolute-fill`}>
      <div className={`${block}__slate__background absolute absolute-fill`} />
      <video
        width="0px"
        height="0px"
        autoPlay
        loop
        muted
        className={`${block}__slate__video`}
      >
        <source src={placeholderVideo} type="video/mp4" />
      </video>
    </div>
  );

  /**
   * Renders the error slate.
   *
   * @returns {React.ReactElement} The error slate element.
   */
  renderError = () => (
    <div className={`${block}__error absolute absolute-fill`}>
      <Slate />
    </div>
  );

  /**
   * Renders the loader.
   *
   * @returns {React.ReactElement} The loader element.
   */
  renderLoader = () => (
    <div className={`${block}__loader absolute absolute-fill`}>
      <Loader
        className="relative"
        brand="news"
        animateIn
        animateOut
      />
    </div>
  );

  /**
   * Renders the video player.
   *
   * @returns {React.ReactElement} The video player element.
   */
  renderPlayer = () => {
    const {
      autoplayMuted,
      clickToPlay,
      domain,
      hasTempPass,
      selectedProvider,
      tempPassProviderID,
      stickyEnabled,
      onStuckChange,
      noStuckChangeInFullscreen,
    } = this.props;

    let providerId;
    if (hasTempPass) {
      providerId = tempPassProviderID;
    } else {
      providerId = get(selectedProvider, 'freewheelHash', null);
    }

    const {
      [domain]: {
        stream,
      } = {},
    } = configTVE;

    return (
      <div className={`${block}__player absolute absolute-fill`}>
        <OmegaVideoPlayer
          stream={stream}
          autoplay={!clickToPlay}
          mutedAutoplay={autoplayMuted}
          mvpdHash={providerId}
          stickyEnabled={stickyEnabled}
          updateIsVideoPlaying={this.updateIsVideoPlaying}
          onStuckChange={onStuckChange}
          noStuckChangeInFullscreen={noStuckChangeInFullscreen}
        />
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {React.ReactElement} The rendered component.
   */
  render() {
    const {
      authenticated,
      bypassAuth,
      mvpdAccountMetadata,
      videoError,
      selectedProvider,
      isLoadingMvpd,
    } = this.props;
    const {
      placeholder,
      isVideoPlaying,
    } = this.state;

    return (
      <div className={`${block} absolute absolute-fill`}>
        <GeoRestriction>
          <ConcurrencyMonitor
            mvpdProviderId={selectedProvider?.id}
            shouldMonitor={isVideoPlaying}
            mvpdProviderConcurrencyFlow={selectedProvider?.concurrencyFlow}
            mvpdAccountMetadata={mvpdAccountMetadata}
          />

          {(() => {
            if (videoError !== false) {
              return this.renderError();
            }
            return null;
          })()}

          {bypassAuth ? (
            this.renderPlayer()
          ) : (
            <>
              {authenticated && isLoadingMvpd && (
                this.renderLoader()
              )}
              {authenticated && !isLoadingMvpd && (
                this.renderPlayer()
              )}
            </>
          )}
          {placeholder && !isVideoPlaying && (
            this.renderPlaceholder())}
        </GeoRestriction>
      </div>
    );
  }
}

const Player = React.forwardRef((props, ref) => <PlayerWrapper {...props} forwardedRef={ref} />);

export default connect(mapStateToProps, mapActionToProps, null, { forwardRef: true })(
  Player,
);
