import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import ProviderSelect from 'components/TVE/ProviderSelect';

import styles from './styles.module.scss';

const SignIn = ({
  providerSelectVisible = false,
  showProviderSelect = Function.prototype,
}) => {
  const iframeRef = React.useRef(null);

  return (
    <div>
      { providerSelectVisible && <ProviderSelect iframeRef={iframeRef} /> }
      <Trans>TO CONTINUE WATCHING</Trans>
      <button
        type="button"
        className={`icon icon-lock ${styles.lock}`}
        onClick={showProviderSelect}
      >
        <Trans>Sign in</Trans>
      </button>
    </div>
  );
};

SignIn.propTypes = {
  providerSelectVisible: PropTypes.bool,
  showProviderSelect: PropTypes.func,
};


export { SignIn };
