import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import styles from './styles.module.scss';

const PAGES_TO_SHOW = 4;
const PAGES_TO_SHOW_MOBILE = 3;

/**
 * @function PageLink
 * @param {String} url
 * @param {Function} onClick
 * @param {Object|String} children
 * @return {React.ReactElement}
 */
const PageLink = ({
  url = '',
  onClick,
  children,
  ...rest
}) => (
  <Link to={url} {...rest}>
    {children}
  </Link>
);

PageLink.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string,
};

/**
 * @function PageButton
 * @param {String} url
 * @param {Object|String} children
 * @return {React.ReactElement}
 */
const PageButton = ({ url, children, ...rest }) => (
  <button type="button" {...rest}>
    {children}
  </button>
);

PageButton.propTypes = {
  url: PropTypes.string,
  children: PropTypes.string,
};

/**
 * Renders pagination links based on the current page and total page count.
 * It shows two links to the left and two to the right of the current page by default.
 * If near the start or end, it adjusts to show fewer on one side and more on the other.
 * Always includes the first and last page, with gaps represented by "gap1" and "gap2" as needed.
 *
 * @param {object} pagination - The content object containing pagination details.
 * @param {Function} navigatePageCallback - Callback function for navigating to a specific page.
 * @param {Function} generatePageUrl - Callback function to generate a page url; used if usePageLink is true
 * @param {Boolean} usePageLink - Flag to determine if a page link should be used instead of a button; used for SEO purposes
 */
function Pagination({
  pagination,
  navigatePageCallback = () => {},
  generatePageUrl = () => {},
  usePageLink = false,
}) {
  const currentPage = pagination.page;
  const pageCount = pagination.totalPages;

  // normal case we show two links to the left and two to the right of the current page
  // but if near the edge, can show less on that side and more on the other
  // first and last page always shown
  // example outputs
  // 1, 2, (3)
  // 1, ..., 3, 4, 5, (6)
  // 1, 2, (3), 4, ..., 10
  // 1, ..., 4, (5), 6, ..., 10
  const pagesToShowLeft = Math.min(
    currentPage - 1,
    PAGES_TO_SHOW - Math.min(pageCount - currentPage, 2),
  );
  const pagesToShowRight = Math.min(
    pageCount - currentPage,
    PAGES_TO_SHOW - Math.min(currentPage - 1, 2),
  );

  const pagesToShow = [currentPage];
  for (let i = 1; i <= pagesToShowLeft; i += 1) {
    if (i < pagesToShowLeft) {
      pagesToShow.unshift(currentPage - i);
    } else {
      if (pagesToShow[0] > 2) {
        pagesToShow.unshift('gap1');
      }
      pagesToShow.unshift(1);
    }
  }
  for (let i = 1; i <= pagesToShowRight; i += 1) {
    if (i < pagesToShowRight) {
      pagesToShow.push(currentPage + i);
    } else {
      if (pagesToShow[pagesToShow.length - 1] < pageCount - 1) {
        pagesToShow.push('gap2');
      }
      pagesToShow.push(pageCount);
    }
  }

  const backIcon = (
    <svg className={styles.paginationButton} width="49" height="47" viewBox="0 0 49 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="49" height="47" fill="white" />
      <path d="M23.8965 33.7947L13.3939 23.2921L24.1034 13.0005" stroke="#2A2A2A" strokeWidth="2" />
    </svg>
  );
  const nextIcon = (
    <svg className={styles.paginationButton} width="49" height="47" viewBox="0 0 49 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="49" height="47" fill="white" />
      <path d="M24 13L34.6066 23.3976L24 33.7952" stroke="#2A2A2A" strokeWidth="2" />
    </svg>
  );

  // minimize pagination for mobile view to fit in smaller screens
  const hideInMobile = (page) => {
    if (
      // show first, last, and current pages by default
      page === 1
      || page === pageCount
      || page === currentPage
      // show up to the maximium page to show in mobile, but hide when elipsis is shown
      || (page <= PAGES_TO_SHOW_MOBILE && currentPage <= PAGES_TO_SHOW_MOBILE)
      // show up the maximum mobile page count from last page, but hide when elipsis is shown
      || (page > pageCount - PAGES_TO_SHOW_MOBILE && currentPage > pageCount - PAGES_TO_SHOW_MOBILE)
    ) {
      return false;
    }

    // hide all other pages
    return true;
  };

  const Page = usePageLink ? PageLink : PageButton;

  return (
    <div className={styles.paginationContainer} data-testid="pagination">
      <button
        disabled={currentPage === 1}
        className={classNames(styles.paginationButton, styles.prevBtn, {
          [styles.disabled]: currentPage === 1,
        })}
        type="button"
        data-testid="prev-btn"
        onClick={() => navigatePageCallback(currentPage - 1)}
      >
        {backIcon}
      </button>
      <div className={classNames(styles.innerContainer)}>
        {pagesToShow.map((page) => (
          typeof page === 'number' ? (
            <Page
              disabled={page === currentPage}
              className={classNames(styles.paginationButton, styles.paginationNumber, {
                [styles.disabled]: page === currentPage,
                [styles.active]: page === currentPage,
                [styles.mobileHide]: hideInMobile(page),
              })}
              type="button"
              onClick={() => navigatePageCallback(page)}
              url={generatePageUrl(page)}
              data-testid={`pagelink-${page}`}
              key={`pagelink-${page}`}
            >
              {page}
            </Page>
          ) : (
            <div className={styles.showMore} key={page} type="button">...</div>
          )
        ))}
      </div>
      <button
        disabled={currentPage === pageCount}
        className={classNames(styles.paginationButton, styles.nextBtn, {
          [styles.disabled]: currentPage === pageCount,
        })}
        type="button"
        data-testid="next-btn"
        onClick={() => navigatePageCallback(currentPage + 1)}
      >
        {nextIcon}
      </button>
    </div>
  );
}

Pagination.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired,
  navigatePageCallback: PropTypes.func,
  generatePageUrl: PropTypes.func,
  usePageLink: PropTypes.bool,
};

export { Pagination };
