import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  package as PackagePropType,
} from 'lib/CustomPropTypes';
import './styles.themed.scss';

function DevPackage(props) {
  const {
    content,
    className = '',
    pkgClassName = 'pkg devPackage',
  } = props;

  if (!content) {
    return null;
  }

  const {
    id,
  } = content;

  const classes = classNames(
    pkgClassName,
    className,
  );

  return (
    <section
      className={classNames(classes)}
      data-packageid={id}
      data-test="dev-package__section"
      data-testid="dev-package__section"
    >
      <pre>{ JSON.stringify(content, null, 2) }</pre>
    </section>
  );
}

DevPackage.propTypes = {
  content: PackagePropType.isRequired,
  className: PropTypes.string,
  pkgClassName: PropTypes.string,
};


export default DevPackage;
