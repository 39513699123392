import React from 'react';
import PropTypes from 'prop-types';
import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';

import styles from './styles.module.scss';

const Schedule = ({
  items = [],
}) => {
  if (!items) return null;

  return (
    <div
      className={styles.schedule}
      data-testid="live-video-promo__schedule"
    >
      {
        items && items.map((i) => (
          <div key={i.guid} className={styles.item}>
            <span className={styles.time}>{i.startTimeFormatted}</span>
            <span className={styles.title}>{i.program.title}</span>
          </div>
        ))
      }
    </div>
  );
};

Schedule.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape(schedulePropType),
  ),
};


export { Schedule };
