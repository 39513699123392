import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useCopyToClipboard } from 'lib/Hooks';

import './styles.themed.scss';

const CopyToClipboardButton = ({
  buttonText,
  copiedText,
  buttonClasses = '',
  tooltipText = '',
  tooltipClasses = '',
}) => {
  const { t } = useTranslation();

  const [isCopied, handleCopyToClipboard] = useCopyToClipboard();

  const buttonClassString = classNames(
    { copied: isCopied },
    'copy-to-clipboard__button',
    buttonClasses,
  );

  const tooltipClassString = classNames(
    'copy-to-clipboard__tooltip',
    'arrow-top',
    tooltipClasses,
  );

  return (
    <div className="copy-to-clipboard">
      <button
        type="button"
        className={buttonClassString}
        onClick={() => handleCopyToClipboard(copiedText)}
      >
        {buttonText}
      </button>
      <span className={tooltipClassString}>
        {tooltipText || t('Copied')}
      </span>
    </div>
  );
};

CopyToClipboardButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
  copiedText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  tooltipClasses: PropTypes.string,
};


export { CopyToClipboardButton };
