import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import classNames from 'classnames';

import { Unibrow } from 'components/Unibrow';

import { formatDuration } from 'lib/DateTime';
import { getIconForArticleType } from 'lib/iconUtils';

import './styles.themed.scss';

function MediaLabel({
  content,
  iconBlockClasses = '',
  iconInlineClasses = '',
  showInlineIcon = false,
  eyebrowClasses = '',
}) {
  const { computedValues = {}, item = {}, type = '' } = content;
  const supportedType = getIconForArticleType(type);

  if (!supportedType) {
    return null;
  }

  const brow = computedValues?.unibrow
    ? { ...computedValues.unibrow }
    : {};

  // link to canonical
  brow.url = { ...brow.url, primary: computedValues.url };

  if (type === 'slideshow') {
    // Show slide count
    const slideCount = item?.slides?.length;
    brow.text = `${slideCount} ${i18next.t('Images')}`;
  }

  if (type === 'video' && item && item.duration) {
    // Show video duration
    brow.text = formatDuration(item.duration);
  }

  const iconClasses = `icon ${getIconForArticleType(type)}`;
  const block = 'media-label';

  // TODO: Should investigate again whether or not TypeIcon can be used
  // instead - at the very least, this should be moved out to its own folder
  // during refactor of this general component into an animatable component like Bacon TwoByOne
  if (showInlineIcon) {
    return (
      <span
        className={classNames(
          iconClasses,
          iconInlineClasses,
          `${block}__type-icon ${block}__type-icon--inline`,
        )}
        data-test={`${block}--inline`}
        data-testid={`${block}--inline`}
      />
    );
  }

  return (
    <div
      className={`${block}`}
      data-test={`${block}--block`}
      data-testid={`${block}--block`}
    >
      <Unibrow
        className={classNames(
          eyebrowClasses,
          `${block}__eyebrow`,
          {
            [`${block}__eyebrow--slideshow`]: type === 'slideshow',
            [`${block}__eyebrow--video`]: type === 'video',
          },
        )}
        data-test={`${block}__eyebrow`}
        unibrow={brow}
      />
      <div
        className={classNames(
          `${block}__icon-container`,
          iconBlockClasses,
        )}
      >
        <span
          className={classNames(
            `${block}__type-icon ${block}__type-icon--block`,
            iconClasses,
          )}
        />
        <span className={`${block}__icon-background`} />
      </div>
    </div>
  );
}

MediaLabel.propTypes = {
  content: ContentPropType.isRequired,
  iconBlockClasses: PropTypes.string,
  iconInlineClasses: PropTypes.string,
  showInlineIcon: PropTypes.bool,
  eyebrowClasses: PropTypes.string,
};


export default MediaLabel;
