import React from 'react';
import NewsLogo from 'assets/images/sports/Logo_NBC_NEWS_Sports_Header.svg';
import SportsLogo from 'assets/images/sports/Logo_NBCSports_Front.svg';
import PlusIcon from 'assets/images/sports/icon_plus_Header.svg';
import styles from './styles.module.scss';

/**
 * SportsHeader NBC News x NBC Sports logo duo for Collection Leads
 */
const SportsHeader = () => (
  <>
    <br />
    <div className={styles.sportsHeader} data-testid="sports-header">
      <img
        className={styles.newsLogo}
        src={NewsLogo}
        alt="NBC News Logo"
      />
      <img
        className={styles.plusIcon}
        src={PlusIcon}
        alt="Plus sign icon"
      />
      <img
        className={styles.sportsLogo}
        src={SportsLogo}
        alt="NBC Sports Logo"
      />
    </div>
  </>
);

export { SportsHeader };
