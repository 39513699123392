import * as accessorInterfaces from './accessorInterfaces';
import { itemDataAccessors } from './itemDataAccessors';
import { packageDataAccessors } from './packageDataAccessors';

/*
  The packageDataManager is a singleton with module level private properties.
  It exports functions from component submodules.
*/

accessorInterfaces.setAccessorsLibrary({
  item: itemDataAccessors,
  package: packageDataAccessors,
});

// functions to call accessors by name
export * from './accessorInterfaces';
// package defaults functions for "preset" support
export * from './packageDefaults';
// helpers for getting complex preset defaults
export * from './presetDefaultsHelpers';
