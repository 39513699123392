import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { DAYS, ET_TIMEZONE, entryIsNextDayET } from 'lib/scheduleUtils';
import { VerticalContext } from 'lib/ContextTypes';

import { loadSchedules as loadSchedulesAction } from 'redux/modules/schedule';

import { Entry } from './Entry';
import { DayPicker } from './DayPicker';

import styles from './styles.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Data-fetching layer of the custom Schedule package. Fetches and passes schedule feed data to
 * functional Schedule renderer
 */
function Schedule({
  content: { metadata = {} } = {},
  minify = false,
}) {
  const today = dayjs().tz('America/New_York').format('dddd');

  const {
    title = 'Schedule Page',
  } = metadata;

  const vertical = useContext(VerticalContext);

  const [selectedDay, setselectedDay] = useState(today);

  const dispatch = useDispatch();

  // Load data
  useEffect(() => {
    dispatch(loadSchedulesAction(vertical));
  }, []);

  const schedule = useSelector((state) => state.schedule?.formattedItems || {});

  const {
    [selectedDay]: scheduleEntries = [],
  } = schedule;

  /**
   * Reduce function with two primary features. The first is to format every entry in a provided
   * feed array into a HTML schedule element. The second is to look for the index where the provided
   * entry switches to the next day. The provided entries are pre-formatted in the redux store and
   * staggered so each day starts entries at 5:00 am and contains the next day's schedule up until
   * 5:00 am.  This reduce inserts an additional label element to indicate the switch.
   *
   * @param {Element[]} renderedEntries - Accumulated array of rendered schedule entries
   * @param {object} entry - Current entry object we are iterating over
   * @param {number} idx - Index of the current entry object in the original array
   * @param {object[]} originalEntries - Original array of entry objects
   */
  const renderScheduleEntries = (renderedEntries, entry, idx, originalEntries) => {
    const {
      description,
      seriesId,
      startTime,
      title: entryTitle,
    } = entry;
    // Next day label
    if (entryIsNextDayET(entry, originalEntries[idx - 1])) {
      const startTimeInET = dayjs(startTime).tz(ET_TIMEZONE);
      const nextDayLabel = startTimeInET.format('dddd [Morning]');
      renderedEntries.push(
        <p key="nextDay" className={styles.nextDay}>
          {nextDayLabel}
        </p>,
      );
    }
    // Add entry
    renderedEntries.push(
      <Entry
        description={description}
        key={startTime}
        minify={minify}
        seriesId={seriesId}
        startTime={startTime}
        title={entryTitle}
      />,
    );
    return renderedEntries;
  };

  const renderedEntries = scheduleEntries
    .sort((showA, showB) => showA.startTime - showB.startTime)
    .reduce(renderScheduleEntries, []);

  return (
    <section className={classNames(styles.schedule, { [styles.minified]: minify })}>
      <h4 className={styles.header}>
        {title}
      </h4>
      {!minify && (
        <DayPicker
          days={DAYS}
          selectedDay={selectedDay}
          setSelectedDay={setselectedDay}
        />
      )}
      <div className={styles.list}>
        {renderedEntries}
      </div>
    </section>
  );
}

Schedule.propTypes = {
  content: PropTypes.shape({
    metadata: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  minify: PropTypes.bool,
};


export { Schedule };
